import { useCallback, useRef } from 'react';
import { QuiBox, QuiButton, useQuiToasts } from '@tonicai/ui-quinine';
import { SUPPORTED_PARSE_JOB_FILE_EXTENSIONS } from '@/types';
import { useFileUploadPipelineContext } from '@/contexts/FileUploadPipeline/useFileUploadPipelineContext';

export function UploadFilesButton() {
    const api = useFileUploadPipelineContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const accept = SUPPORTED_PARSE_JOB_FILE_EXTENSIONS.join(',');
    const addToast = useQuiToasts();

    const handleClick = useCallback(() => {
        inputRef.current?.click();
    }, []);

    return (
        <>
            <QuiBox display="none">
                <input
                    ref={inputRef}
                    multiple
                    onChange={(e) => {
                        // If we use this inside of a form, we don't want the FormData
                        // value to include these files. Since this is a SPA, the
                        // form data is being managed by us, and not the browser, so
                        // we don't want to inject any of these files into the native
                        // form state of the browser.
                        e.preventDefault();
                        e.stopPropagation();
                        const files = e.target.files;

                        if (!files) return;

                        for (const file of files) {
                            api.uploadFile(file, (e) => {
                                if (e.response?.status === 409) addToast({ title: 'File already exists', variant: 'destructive' });
                            });
                        }

                        if (inputRef.current) {
                            // Workaround for clearing the input value for Chrome.
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            inputRef.current.value = null;
                        }
                    }}
                    type="file"
                    accept={accept}
                />
            </QuiBox>

            <div>
                <QuiButton data-test="upload-files-button" variant="brand-purple" onClick={handleClick} type="button" iconLeft="upload">
                    Upload Files
                </QuiButton>
            </div>
        </>
    );
}
