import { QuiBox, QuiGridLayout, QuiFlexH, QuiFlexV, QuiCell, QuiText, QuiSpinner, QuiButtonLink, QuiIconEnum } from '@tonicai/ui-quinine';
import BarChart from './BarChart';
import { useEffect } from 'react';
import { fetchBillingInfo, useBillingStatus, useFetchedBillingData, useLast30DaysUsage, useStripeInvoices } from '@/stores';
import { WordCountRightSidebar } from './WordCountRightSidebar';
import { WordCountLeftSidebar } from './WordCountLeftSidebar';
import { CloudSubscriptionStatus } from '@/types';
import { Navigate } from 'react-router-dom';
import styles from './Billing.module.scss';
import { formatWordCount, toTitleCase } from './utils';
import StripeInvoiceStatus from './StripeInvoiceStatus';

export function Billing() {
    const last30daysUsage = useLast30DaysUsage();
    const billingStatus = useBillingStatus();
    const fetchedBillingData = useFetchedBillingData();
    const stripeInvoices = useStripeInvoices();

    useEffect(() => {
        fetchBillingInfo();
    }, []);

    if (!fetchedBillingData) {
        return (
            <QuiBox display="flex" justifyContent="center" alignItems="center" padding="md">
                <QuiSpinner size="sm" />
            </QuiBox>
        );
    }

    // Redirect to home if the user doesn't have an active subscription
    if (billingStatus === CloudSubscriptionStatus.None) {
        return <Navigate to="/" />;
    }

    return (
        <QuiBox className={styles.wrapper} maxWidth="wide" padding="md">
            <QuiGridLayout gap="md">
                <QuiCell width={3}>
                    <WordCountLeftSidebar />
                </QuiCell>
                <QuiCell width={6}>
                    <QuiBox display="flex" flexDirection="column" gap="sm">
                        <QuiBox padding="lg" bg="background-primary" border="border-default" borderRadius="md">
                            <QuiFlexV gap="md">
                                <QuiFlexH align="bottom" justify="between">
                                    <QuiText>Usage last 30 days</QuiText>
                                    <QuiText color="text-secondary" size="text-xs">
                                        {`${formatWordCount(last30daysUsage)} words`}
                                    </QuiText>
                                </QuiFlexH>
                                <BarChart />
                            </QuiFlexV>
                        </QuiBox>
                        <QuiText size="text-xl" weight="bold" style={{ marginTop: '1em' }}>
                            Payment History
                        </QuiText>
                        <QuiBox text="text-sm">
                            <QuiGridLayout gap="sm">
                                <QuiCell style={{ paddingLeft: '1em' }} width={2}>
                                    Amount
                                </QuiCell>
                                <QuiCell style={{ paddingLeft: '1em' }} width={2}>
                                    Date
                                </QuiCell>
                                <QuiCell width={3}>Payment Method</QuiCell>
                                <QuiCell width={3}>Status</QuiCell>
                                <QuiCell>
                                    {stripeInvoices!.map((invoice) => {
                                        return (
                                            <QuiBox
                                                margin="sm none none none"
                                                key={invoice.id}
                                                padding="md"
                                                bg="background-primary"
                                                border="border-default"
                                                borderRadius="md"
                                                text="text-sm"
                                            >
                                                <QuiGridLayout gap="sm">
                                                    <QuiCell width={2}>${(invoice.amountDue / 100).toFixed(2)}</QuiCell>
                                                    <QuiCell width={2}>
                                                        <QuiBox color="text-secondary">{new Date(invoice.dueDate).toLocaleDateString()}</QuiBox>
                                                    </QuiCell>
                                                    <QuiCell width={3}>
                                                        {invoice.paymentIntent?.paymentMethod?.card.brand && (
                                                            <QuiBox color="text-secondary">
                                                                {toTitleCase(invoice.paymentIntent.paymentMethod.card.brand ?? '')} •••••
                                                                {invoice.paymentIntent.paymentMethod.card.last4}
                                                            </QuiBox>
                                                        )}
                                                    </QuiCell>
                                                    <QuiCell width={3}>
                                                        <StripeInvoiceStatus status={invoice.status} />
                                                    </QuiCell>
                                                    <QuiCell width={2}>
                                                        <QuiBox display="flex" justifyContent="end">
                                                            <QuiButtonLink
                                                                iconRight={QuiIconEnum.Download}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                to={invoice.hostedInvoiceUrl}
                                                                size="sm"
                                                            >
                                                                Invoice
                                                            </QuiButtonLink>
                                                        </QuiBox>
                                                    </QuiCell>
                                                </QuiGridLayout>
                                            </QuiBox>
                                        );
                                    })}
                                </QuiCell>
                            </QuiGridLayout>
                        </QuiBox>
                    </QuiBox>
                </QuiCell>
                <QuiCell width={3}>
                    <WordCountRightSidebar />
                </QuiCell>
            </QuiGridLayout>
        </QuiBox>
    );
}
