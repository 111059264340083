import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useFilePreviewContext } from './useFilePreviewContext';
import { useDatasetFile } from '../../stores/datasetFiles';
import { FileDownloadButton } from './FileDownloadButton';
import { FileDeleteButton } from './FileDeleteButton';

export function FilePreviewDialogTitle() {
    const { selectedFileId, openPreview, numPages, hasNextPage, hasPreviousPage, pageNumber, nextPage, previousPage } = useFilePreviewContext();
    const selectedFile = useDatasetFile(selectedFileId);

    if (!selectedFileId) {
        return (
            <QuiBox display="inline-flex" gap="md" alignItems="center">
                <div>Preview and Manage Files</div>
            </QuiBox>
        );
    }

    return (
        <QuiBox display="inline-flex" alignItems="center" gap="md">
            <QuiButton iconLeft="arrow-left" onClick={openPreview} size="sm">
                Back To All Files
            </QuiButton>

            <QuiBox text="mono-text-sm" weight="bold">
                {selectedFile?.fileName ?? 'File'}
            </QuiBox>

            {(hasNextPage || hasPreviousPage) && (
                <QuiBox alignItems="center" border="border-default" borderRadius="md" display="flex" justifyContent="center" gap={'xs'}>
                    <QuiButton variant="minimal" size="sm" disabled={!hasPreviousPage} onClick={previousPage} iconLeft={'arrow-left'} />
                    <QuiBox display="inline-flex" padding="xs" text="mono-text-xs">{`Page ${pageNumber + 1} / ${numPages}`}</QuiBox>
                    <QuiButton variant="minimal" size="sm" disabled={!hasNextPage} onClick={nextPage} iconRight={'arrow-right'} />
                </QuiBox>
            )}

            {selectedFile ? <FileDeleteButton file={selectedFile} /> : null}
            {selectedFile ? <FileDownloadButton file={selectedFile} jobStatus={selectedFile.processingStatus} /> : null}
        </QuiBox>
    );
}
