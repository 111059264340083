import { ReactNode } from 'react';
import { QuiBox, QuiCell, QuiGridLayout } from '@tonicai/ui-quinine';
import styles from './FormSection.module.scss';

type FormSectionProps = Readonly<{
    title: ReactNode;
    description?: ReactNode;
    children: ReactNode;
}>;

export function FormSection({ title, children, description }: FormSectionProps) {
    return (
        <QuiGridLayout gap="md">
            <QuiCell
                width={12}
                desktop={{
                    width: 3,
                }}
            >
                <div className={styles.formSectionHeader}>
                    <QuiBox display="flex" gap="md" alignItems="center">
                        <QuiBox display="flex" flexDirection="column">
                            <QuiBox text="text-lg" weight="medium">
                                {title}
                            </QuiBox>
                            {description ? (
                                <QuiBox text="text-xs" color="text-secondary">
                                    {description}
                                </QuiBox>
                            ) : null}
                        </QuiBox>
                    </QuiBox>
                </div>
            </QuiCell>

            <QuiCell
                width={12}
                desktop={{
                    width: 9,
                }}
            >
                <QuiBox display="flex" flexDirection="column" gap="md" style={{ maxWidth: '100%' }}>
                    {children}
                </QuiBox>
            </QuiCell>
        </QuiGridLayout>
    );
}
