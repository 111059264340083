import { QuiBadge, QuiBox, QuiButton } from '@tonicai/ui-quinine';
import Notice from '../Notice/Notice';
import styles from './OnPremCallout.module.scss';

const CHAT_MESSAGE = "Hi. I'd like to discuss going on-prem with Tonic Textual.";

export function OnPremCallout() {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if ('Intercom' in window && typeof window.Intercom === 'function') {
            window?.Intercom('showNewMessage', CHAT_MESSAGE);
        }
    };

    return (
        <Notice variant="purple">
            <QuiBadge className={styles.badge} size="xl" variant="brand-purple" iconLeft="map-pin" />
            <QuiBox display={'flex'} flexDirection={'column'}>
                <Notice.Title>Ready to go on-prem?</Notice.Title>
                <QuiBox text="text-sm">
                    Our hosted Tonic Textual offering is best, but if your data is too sensitive, we also offer the ability to host Tonic Textual
                    yourself.
                </QuiBox>
            </QuiBox>
            <QuiButton variant="brand-purple" iconLeft="send" onClick={handleClick}>
                Go On-Prem
            </QuiButton>
        </Notice>
    );
}
