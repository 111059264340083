import { matchesOtherField, QuiBox, QuiFlexV, QuiForm, QuiLink, QuiPasswordField, QuiSubmitButton, QuiTextField } from '@tonicai/ui-quinine';
import { SignUpFormValues } from '@/types';
import { signUp } from '@/stores';
import { SubmitError } from './SubmitError';
import { requiredEmail, requiredPassword } from '@/validation';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { environmentVariablesAtom } from '@/stores';
import { SolarEnvironmentVariable } from '@/utils';

export function SignUpForm() {
    const navigate = useNavigate();
    const values = useAtomValue(environmentVariablesAtom);
    const isHostedProduction = values?.[SolarEnvironmentVariable.IsHostedProd] === true;

    return (
        <QuiForm<SignUpFormValues>
            onSubmit={(values: SignUpFormValues) =>
                signUp(values).then((response) => {
                    if (response === undefined) {
                        navigate('/signin?accountCreated=true');
                    } else {
                        return response;
                    }
                })
            }
        >
            <QuiFlexV gap="md">
                <QuiTextField data-test="signup-email-field" label="Email Address *" validate={requiredEmail} name="email" />
                <QuiPasswordField
                    data-test="signup-password-field"
                    validate={requiredPassword}
                    hideShowPasswordToggle
                    label="Password *"
                    name="password"
                />
                <QuiPasswordField
                    data-test="signup-confirm-password-field"
                    name="confirmPassword"
                    label="Confirm Password *"
                    validate={matchesOtherField('password', 'Passwords must match.')}
                    hideShowPasswordToggle
                />
                <SubmitError />
                <QuiBox text="text-xs">
                    {`Signing up signifies that you are authorized to enter into an agreement with Tonic, and that you have read and agree to our `}
                    <QuiLink variant="currentcolor" external to={isHostedProduction ? 'https://tonic.ai/terms/free-trial' : 'https://tonic.ai/terms'}>
                        Terms of Use
                    </QuiLink>
                    {' and '}
                    <QuiLink variant="currentcolor" external to="https://www.tonic.ai/privacy-policy">
                        Privacy Policy
                    </QuiLink>
                    {`, which will govern all uses of any Tonic product, unless there is a signed agreement between you and Tonic that you agree governs
                    this use.`}
                </QuiBox>
                <QuiSubmitButton data-test="signup-submit-button" variant="brand-purple">
                    Sign Up
                </QuiSubmitButton>
            </QuiFlexV>
        </QuiForm>
    );
}
