import { CloudItemType } from './types';

type CloudStorageItemSelectedConfig = {
    itemPath: string;
    selectedFiles: string[];
    pathPrefixes: string[];
    type: CloudItemType;
    disableNestedPaths: boolean;
    allowFileSelection: boolean;
};

export function isCloudStorageItemSelected({
    itemPath,
    selectedFiles,
    pathPrefixes,
    type,
    disableNestedPaths,
    allowFileSelection,
}: CloudStorageItemSelectedConfig): boolean {
    if (type === 'File') {
        return allowFileSelection && selectedFiles.includes(itemPath);
    }

    // For Bucket, Catalog, Schema, Volume, and Folder
    if (disableNestedPaths) {
        return pathPrefixes.some((prefix) => prefix === itemPath || prefix.startsWith(`${itemPath}/`));
    } else {
        return pathPrefixes.includes(itemPath);
    }
}
