import { QuiBox, QuiIcon, QuiIconToken, QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItemDivider, QuiMenuItems } from '@tonicai/ui-quinine';
import { CreateDatasetDialog } from '../CreateDatasetDialog/CreateDatasetDialog';
import { useCallback, useState } from 'react';
import { ApiKeyModal } from '../ApiKeyModal/ApiKeyModal';
import { CreatePipelineDialog } from '../CreatePipelineDialog/CreatePipelineDialog';

function MenuItemText({ title, description, icon }: Readonly<{ title: string; description: string; icon: QuiIconToken }>) {
    return (
        <QuiBox display="flex" gap="sm" alignItems="start">
            <div>
                <QuiIcon icon={icon} />
            </div>
            <QuiBox flexShrink="1" display="flex" overflow="hidden" flexDirection="column">
                <QuiBox weight="medium" text="text-sm">
                    {title}
                </QuiBox>
                <QuiBox style={{ width: '252px' }} whiteSpace="break-spaces" display="block" text="text-xs" color="text-secondary">
                    {description}
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

export function CreateMenu() {
    const [openDialog, setOpenDialog] = useState<'dataset' | 'parse-job' | 'api-key' | null>(null);

    const closeDialog = useCallback(() => {
        setOpenDialog(null);
    }, []);

    return (
        <>
            <QuiMenu enableFlip placement="bottom-start">
                <QuiMenuButton iconRight="chevron-down" size="lg" variant="brand-purple">
                    Create
                </QuiMenuButton>
                <QuiMenuItems>
                    <QuiMenuItem onClick={() => setOpenDialog('parse-job')}>
                        <MenuItemText
                            icon="git-branch"
                            title="Pipeline"
                            description="Prepare standardize unstructured data for ingestion into your RAG or LLM system."
                        />
                    </QuiMenuItem>
                    <QuiMenuItemDivider />
                    <QuiMenuItem onClick={() => setOpenDialog('dataset')}>
                        <MenuItemText
                            icon="database"
                            title="Dataset"
                            description="Take files with sensitive information and create redacted or synthesized versions."
                        />
                    </QuiMenuItem>
                    <QuiMenuItemDivider />
                    <QuiMenuItem onClick={() => setOpenDialog('api-key')}>
                        <MenuItemText
                            icon="key"
                            title="API Key"
                            description="With the Textual API, you can use a simple Python script to fetch your redacted data."
                        />
                    </QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>

            <CreateDatasetDialog isOpen={openDialog === 'dataset'} onClose={closeDialog} />

            <CreatePipelineDialog isOpen={openDialog === 'parse-job'} onClose={closeDialog} />

            <ApiKeyModal isOpen={openDialog === 'api-key'} onClose={closeDialog} />
        </>
    );
}
