import { ReactNode, useMemo } from 'react';
import { QuiBox, QuiBoxProps, QuiIcon, QuiIconToken } from '@tonicai/ui-quinine';

type MessageType = 'info' | 'success' | 'error' | 'warning';

type MessageProps = Readonly<{
    variant: MessageType;
    children: ReactNode;
    ['data-test']?: string;
}>;

export function Message({ variant, children, ...otherProps }: MessageProps) {
    const { boxProps, icon } = useMemo(() => {
        const bp: Pick<QuiBoxProps, 'color' | 'bg' | 'border'> = {};

        let icon: QuiIconToken | null = null;

        if (variant === 'error') {
            bp.color = 'red-400';
            bp.border = 'red-400';
            icon = 'alert-octagon';
        }

        if (variant === 'info') {
            bp.color = 'blue-400';
            bp.border = 'blue-400';
            icon = 'info';
        }

        if (variant === 'success') {
            bp.color = 'green-400';
            bp.border = 'green-400';
            icon = 'alert-circle';
        }

        if (variant === 'warning') {
            bp.color = 'orange-400';
            bp.border = 'orange-400';
            icon = 'alert-triangle';
        }

        return { boxProps: bp, icon };
    }, [variant]);

    return (
        <QuiBox display="flex" justifyContent="start" gap="sm" padding="sm" borderRadius="md" {...boxProps} {...otherProps}>
            {icon ? (
                <QuiBox display="flex" style={{ height: '20px' }} alignItems="center">
                    <QuiIcon size="md" icon={icon} />
                </QuiBox>
            ) : null}
            <QuiBox display="flex" flexDirection="column" gap="sm">
                {children}
            </QuiBox>
        </QuiBox>
    );
}
