declare global {
    interface Window {
        gtag?: (command: 'get' | 'set' | 'config' | 'event' | 'consent', ...params: (string | Record<string, string>)[]) => void;
    }
}

// Track signup event in google tag manager
function trackSignup(userCreatedDate?: string) {
    if (!userCreatedDate) return;

    try {
        const timestampUserCreated = new Date(userCreatedDate).valueOf();
        const now = new Date().valueOf();
        const millisecondsSinceAccountCreation = now - timestampUserCreated;

        if (millisecondsSinceAccountCreation < 3000 && window?.gtag && typeof window.gtag === 'function') {
            window.gtag('event', 'sign_up');
        }
    } catch {
        // Do nothing
    }
}

export const gtag = {
    trackSignup,
};
