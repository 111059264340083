export enum CloudSubscriptionStatus {
    None = 'None',
    Expired = 'Expired',
    Active = 'Active',
}

export const FREE_TRIAL_WORD_COUNT = 100_000;

export const PRICE_PER_1000_WORDS = 0.29;

export type TotalUsageHistoryResponseModel = {
    wordCount: number;
    earliestJobDate: string | undefined | null;
};

export type StripeAccountMetadata = {
    lastPaymentDate?: string | null;
    nextPaymentDate?: string | null;
    currentBalance?: number | null;
    customerName?: string | null;
    lastFour?: string | null;
    wordsUsed?: number | null;
};

export type StripeInvoiceStatus = 'pending' | 'paid' | 'uncollectible' | 'void' | 'deleted' | 'draft';
export type StripeInvoice = {
    id: string;
    status: StripeInvoiceStatus;
    paymentIntent?: {
        paymentMethod?: { card: { brand: string; last4: string } };
    };
    amountDue: number;
    hostedInvoiceUrl: string;
    dueDate: string;
};
