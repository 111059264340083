import { FileSourceButtons } from './FileSourceButtons';
import {
    QuiBox,
    QuiButton,
    QuiConditionalField,
    QuiField,
    QuiFlexBoxColumn,
    QuiForm,
    QuiFormProps,
    QuiModalContent,
    QuiModalDialog,
    QuiSegmentedControlButton,
    QuiSegmentedControlField,
    QuiSubmitButton,
    QuiText,
    QuiTextField,
} from '@tonicai/ui-quinine';
import { AxiosResponse, isCancel } from 'axios';
import isEqual from 'fast-deep-equal';
import { FORM_ERROR } from 'final-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormSpy, useField, useForm, useFormState } from 'react-final-form';
import { Navigate } from 'react-router-dom';
import { client } from '../../services/HTTPClient';
import { createParseJob } from '../../stores/parse-jobs';
import { useAreAwsCredsAvailable, useInternalBucketSet, useIsHostedProd } from '../../stores/settings';
import {
    AwsCredential,
    AwsCredentialSourceEnum,
    AzureCredential,
    CreateAzureParseJobConfigRequest,
    CreateDatabricksParseJobConfigRequest,
    CreateParseJobConfigRequest,
    DatabricksCredential,
    FileSourceEnum,
    ObjectStorageType,
    ParseJobConfigResponse,
} from '../../types';
import { getAwsCredentialsError, isAwsCredentialsError } from '../../utils';
import { requiredString } from '../../validation';
import { Message } from '../Message/Message';
import { AWSCredentials } from '@/components/CreatePipelineDialog/AWSCredentials';
import { AzureCredentials } from '@/components/CreatePipelineDialog/AzureCredentials';
import styles from './CreatePipelineDialog.module.scss';
import { DatabricksCredentials } from '@/components/CreatePipelineDialog/DatabricksCredentials';
import { SourceType } from './SourceType';

const CLOUD_CONNECTION_ENDPOINT = '/api/cloudfile/test-cloud-connection';

const ERROR_MESSAGES = {
    invalidAwsCredentials: 'Invalid AWS credentials.',
    unableToCreatePipeline: 'Unable to create Pipeline.',
    invalidFileSource: 'Invalid file source selected.',
    databricksRequired: 'Databricks URL and access token are required.',
    azureRequired: 'Storage Account Name and Storage Account Key are required.',
};

type FormState = {
    name: string;
    fileSource: SourceType;
    useEnvironmentCredentials: boolean;
    awsAccessKey: string;
    awsAccessSecret: string;
    awsRegion: string;
    awsSessionToken: string;
    databricksUrl: string;
    databricksAccessToken: string;
    azureStorageAccountName: string;
    azureStorageAccountKey: string;
};

type FormContentProps = Readonly<{
    onClose: () => void;
    errorToShow: 'form' | 'test' | 'none';
    setErrorToShow: (errorToShow: 'form' | 'test' | 'none') => void;
}>;

function FormContent({ onClose, errorToShow, setErrorToShow }: FormContentProps) {
    const form = useForm<FormState>();
    const { values: formValues } = useFormState<FormState>();

    // Azure fields
    const azureStorageAccountNameField = useField('azureStorageAccountName');
    const azureStorageAccountKeyField = useField('azureStorageAccountKey');

    const [azureTestStatus, setAzureTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [azureTestErrorMessage, setAzureTestErrorMessage] = useState<string | null>(null);

    const azureTestConnectionEnabled = useMemo(() => {
        return azureStorageAccountNameField.meta.valid && azureStorageAccountKeyField.meta.valid;
    }, [azureStorageAccountNameField.meta.valid, azureStorageAccountKeyField.meta.valid]);

    // Databricks fields
    const databricksUrlField = useField('databricksUrl');
    const databricksAccessTokenField = useField('databricksAccessToken');

    const [databricksTestStatus, setDatabricksTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [databricksTestErrorMessage, setDatabricksTestErrorMessage] = useState<string | null>(null);

    const databricksTestConnectionEnabled = useMemo(() => {
        return databricksUrlField.meta.valid && databricksAccessTokenField.meta.valid;
    }, [databricksUrlField.meta.valid, databricksAccessTokenField.meta.valid]);

    useEffect(() => {
        setDatabricksTestStatus('untested');
    }, [databricksUrlField.input.value, databricksAccessTokenField.input.value]);

    const abortControllerRef = useRef<AbortController | null>(null);

    const testDatabricksConnection = useCallback(() => {
        setErrorToShow('none');

        const data: DatabricksCredential = {
            url: databricksUrlField.input.value,
            accessToken: databricksAccessTokenField.input.value,
        };

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        setDatabricksTestErrorMessage(null);
        setDatabricksTestStatus('testing');
        client
            .post<{ success: boolean; errorMessage: string | null }>(
                CLOUD_CONNECTION_ENDPOINT,
                {
                    fileSource: FileSourceEnum.Databricks,
                    credential: data,
                },
                { signal: abortControllerRef.current.signal }
            )
            .then((response) => {
                setErrorToShow('test');
                if (!response.data.success || typeof response.data.errorMessage === 'string') {
                    setDatabricksTestStatus('error');
                    setDatabricksTestErrorMessage(response.data.errorMessage);
                } else {
                    setDatabricksTestStatus('success');
                    setDatabricksTestErrorMessage(null);
                }
            })
            .catch((error) => {
                if (!isCancel(error)) {
                    console.error(error);
                    setDatabricksTestStatus('error');
                    setErrorToShow('test');
                }
            });
    }, [databricksUrlField.input.value, databricksAccessTokenField.input.value, setErrorToShow]);

    const testAzureConnection = useCallback(() => {
        setErrorToShow('none');

        const data: AzureCredential = {
            accountName: azureStorageAccountNameField.input.value,
            accountKey: azureStorageAccountKeyField.input.value,
        };

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        setAzureTestErrorMessage(null);
        setAzureTestStatus('testing');
        client
            .post<{ success: boolean; errorMessage: string | null }>(
                CLOUD_CONNECTION_ENDPOINT,
                {
                    fileSource: FileSourceEnum.Azure,
                    credential: data,
                },
                { signal: abortControllerRef.current.signal }
            )
            .then((response) => {
                setErrorToShow('test');
                if (!response.data.success || typeof response.data.errorMessage === 'string') {
                    setAzureTestStatus('error');
                    setAzureTestErrorMessage(response.data.errorMessage);
                } else {
                    setAzureTestStatus('success');
                    setAzureTestErrorMessage(null);
                }
            })
            .catch((error) => {
                if (!isCancel(error)) {
                    console.error(error);
                    setAzureTestStatus('error');
                    setErrorToShow('test');
                }
            });
    }, [azureStorageAccountNameField.input.value, azureStorageAccountKeyField.input.value, setErrorToShow]);

    const areAwsCredsAvailable = useAreAwsCredsAvailable();
    const isHostedProd = useIsHostedProd();
    const showCredsSourceToggle = !isHostedProd && areAwsCredsAvailable;

    const { submitting, submitError, values } = useFormState<FormState>({
        subscription: {
            submitting: true,
            submitError: true,
            values: true,
        },
    });

    // AWS fields
    const awsKeyField = useField('awsAccessKey');
    const awsAccessSecretField = useField('awsAccessSecret');
    const awsRegionField = useField('awsRegion');
    const awsSessionToken = useField('awsSessionToken');

    const lastCheckedValues = useRef<AwsCredential | null>(null);

    const [testStatus, setTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [testErrorMessage, setTestErrorMessage] = useState<string | null>(null);

    const testConnectionEnabled = useMemo(() => {
        return awsKeyField.meta.valid && awsAccessSecretField.meta.valid && awsRegionField.meta.valid;
    }, [awsKeyField.meta.valid, awsAccessSecretField.meta.valid, awsRegionField.meta.valid]);

    useEffect(() => {
        setTestStatus('untested');
    }, [awsKeyField.input.value, awsAccessSecretField.input.value, awsRegionField.input.value, awsSessionToken.input.value]);

    useEffect(() => {
        if (submitting) {
            setTestStatus('untested');
        }
    }, [submitting]);

    useEffect(() => {
        setErrorToShow('none');
    }, [values, setErrorToShow]);

    const testAWSConnection = useCallback(
        (automatic?: boolean) => {
            setErrorToShow('none');

            const data: AwsCredential = {
                accessKey: awsKeyField.input.value,
                secretKey: awsAccessSecretField.input.value,
                region: awsRegionField.input.value,
                sessionToken: awsSessionToken.input.value,
            };

            if (automatic && lastCheckedValues.current !== null && isEqual(data, lastCheckedValues.current)) {
                return;
            }

            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            lastCheckedValues.current = data;

            setTestErrorMessage(null);
            setTestStatus('testing');
            client
                .post<{ success: boolean; errorMessage: string | null }>(
                    CLOUD_CONNECTION_ENDPOINT,
                    {
                        fileSource: FileSourceEnum.Aws,
                        credential: data,
                    },
                    { signal: abortControllerRef.current.signal }
                )
                .then((response) => {
                    setErrorToShow('test');
                    if (!response.data.success || typeof response.data.errorMessage === 'string') {
                        setTestStatus('error');
                        setTestErrorMessage(response.data.errorMessage);
                    } else {
                        setTestStatus('success');
                        setTestErrorMessage(null);
                    }
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        console.error(error);
                        setTestStatus('error');
                        setErrorToShow('test');
                    }
                });
        },
        [awsKeyField.input.value, awsAccessSecretField.input.value, awsRegionField.input.value, awsSessionToken.input.value, setErrorToShow]
    );

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <QuiFlexBoxColumn gap="md">
            <QuiTextField data-test="pipeline-name-input" validate={requiredString} size="sm" label="Name" name="name" />

            <QuiField label="Files Source">
                <FileSourceButtons fileSource={formValues.fileSource} onChange={(source) => form.change('fileSource', source)} styles={styles} />
            </QuiField>

            {showCredsSourceToggle ? (
                <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Aws}>
                    <QuiField label="AWS Credentials Location">
                        <QuiSegmentedControlField<boolean> size="sm" name="useEnvironmentCredentials">
                            <QuiSegmentedControlButton<boolean> value={false}>User</QuiSegmentedControlButton>
                            <QuiSegmentedControlButton<boolean> value={true}>Environment</QuiSegmentedControlButton>
                        </QuiSegmentedControlField>
                    </QuiField>
                </QuiConditionalField>
            ) : null}

            <AWSCredentials
                testStatus={testStatus}
                testConnectionEnabled={testConnectionEnabled}
                submitting={submitting}
                onClick={() => {
                    testAWSConnection();
                }}
                errorToShow={errorToShow}
                testErrorMessage={testErrorMessage}
            />

            <AzureCredentials
                testStatus={azureTestStatus}
                testConnectionEnabled={azureTestConnectionEnabled}
                submitting={submitting}
                onClick={testAzureConnection}
                errorToShow={errorToShow}
                testErrorMessage={azureTestErrorMessage}
            />

            <DatabricksCredentials
                testStatus={databricksTestStatus}
                testConnectionEnabled={databricksTestConnectionEnabled}
                submitting={submitting}
                onClick={testDatabricksConnection}
                errorToShow={errorToShow}
                testErrorMessage={databricksTestErrorMessage}
            />

            {errorToShow === 'form' && submitError ? <Message variant="error">{submitError}</Message> : null}

            <QuiBox display="flex" gap="md" alignItems="center">
                <QuiSubmitButton data-test="create-dialog-save-button" variant="brand-purple">
                    Save
                </QuiSubmitButton>
                <QuiButton onClick={onClose} type="button">
                    Cancel
                </QuiButton>
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}

function formSubmitErrorHandler(error: unknown) {
    if (isAwsCredentialsError(error)) {
        return {
            [FORM_ERROR]: getAwsCredentialsError(error) ?? ERROR_MESSAGES.invalidAwsCredentials,
        };
    }
    return {
        [FORM_ERROR]: ERROR_MESSAGES.unableToCreatePipeline,
    };
}

type CreatePipelineDialogProps = Readonly<{
    onClose: () => void;
    isOpen: boolean;
}>;

export function CreatePipelineDialog({ isOpen, onClose }: CreatePipelineDialogProps) {
    const [newPipeline, setNewPipeline] = useState<ParseJobConfigResponse | null>(null);

    const [errorToShow, setErrorToShow] = useState<'form' | 'test' | 'none'>('none');
    const isInternalBucketSet = useInternalBucketSet();

    const INITIAL_VALUES: FormState = {
        name: '',
        fileSource: isInternalBucketSet ? SourceType.Files : SourceType.Aws,
        useEnvironmentCredentials: false,
        awsAccessKey: '',
        awsAccessSecret: '',
        awsRegion: '',
        awsSessionToken: '',
        databricksUrl: '',
        databricksAccessToken: '',
        azureStorageAccountName: '',
        azureStorageAccountKey: '',
    };

    function SubmitFilesPipeline(values: FormState) {
        return client
            .post<ParseJobConfigResponse, AxiosResponse<ParseJobConfigResponse>, { name: string }>('/api/parsejobconfig/local-files', {
                name: values.name,
            })
            .then(({ data }) => {
                setNewPipeline(data);
                return undefined;
            })
            .catch(formSubmitErrorHandler);
    }

    function SubmitAwsPipeline(values: FormState) {
        // Create Amazon S3 Pipelines That Uses Environment Credentials
        if (values.useEnvironmentCredentials) {
            const requestData: CreateParseJobConfigRequest = {
                name: values.name,
                outputPath: '',
                awsCredentialSource: AwsCredentialSourceEnum.FromEnvironment,
                objectStorageType: ObjectStorageType.S3,
                fileSource: FileSourceEnum.Aws,
                parseJobExternalCredential: {
                    fileSource: FileSourceEnum.Aws,
                },
            };
            return createParseJob(requestData)
                .then((pipeline) => {
                    setNewPipeline(pipeline);
                    return undefined;
                })
                .catch(formSubmitErrorHandler);
        } else {
            // Create Amazon S3 Pipelines That Uses User Provided Credentials
            const requestData: CreateParseJobConfigRequest = {
                name: values.name,
                awsCredentialSource: AwsCredentialSourceEnum.UserProvided,
                objectStorageType: ObjectStorageType.S3,
                fileSource: FileSourceEnum.Aws,
                outputPath: '',
                parseJobExternalCredential: {
                    fileSource: FileSourceEnum.Aws,
                    credential: {
                        accessKey: values.awsAccessKey,
                        secretKey: values.awsAccessSecret,
                        region: values.awsRegion,
                        sessionToken: values.awsSessionToken,
                    },
                },
            };
            return createParseJob(requestData)
                .then((pipeline) => {
                    setNewPipeline(pipeline);
                    return undefined;
                })
                .catch(formSubmitErrorHandler);
        }
    }

    async function SubmitDatabricksPipeline(values: FormState) {
        if (!values.databricksUrl || !values.databricksAccessToken) {
            return { [FORM_ERROR]: ERROR_MESSAGES.databricksRequired };
        }

        const requestData: CreateDatabricksParseJobConfigRequest = {
            name: values.name,
            objectStorageType: ObjectStorageType.Databricks,
            fileSource: FileSourceEnum.Databricks,
            parseJobExternalCredential: {
                fileSource: FileSourceEnum.Databricks,
                credential: {
                    url: values.databricksUrl,
                    accessToken: values.databricksAccessToken,
                },
            },
        };

        return createParseJob(requestData)
            .then((pipeline) => {
                setNewPipeline(pipeline);
                return undefined;
            })
            .catch(formSubmitErrorHandler);
    }

    async function SubmitAzurePipeline(values: FormState) {
        if (!values.azureStorageAccountName || !values.azureStorageAccountKey) {
            return { [FORM_ERROR]: ERROR_MESSAGES.azureRequired };
        }

        const requestData: CreateAzureParseJobConfigRequest = {
            name: values.name,
            objectStorageType: ObjectStorageType.Azure,
            fileSource: FileSourceEnum.Azure,
            parseJobExternalCredential: {
                fileSource: FileSourceEnum.Azure,
                credential: {
                    accountName: values.azureStorageAccountName,
                    accountKey: values.azureStorageAccountKey,
                },
            },
        };

        return createParseJob(requestData)
            .then((pipeline) => {
                setNewPipeline(pipeline);
                return undefined;
            })
            .catch(formSubmitErrorHandler);
    }

    const onSubmit = useCallback<QuiFormProps<FormState>['onSubmit']>(async (values) => {
        setErrorToShow('form');

        switch (values.fileSource) {
            case SourceType.Files:
                return SubmitFilesPipeline(values);
            case SourceType.Aws:
                return SubmitAwsPipeline(values);
            case SourceType.Databricks:
                return SubmitDatabricksPipeline(values);
            case SourceType.Azure:
                return SubmitAzurePipeline(values);
            default:
                return { [FORM_ERROR]: ERROR_MESSAGES.invalidFileSource };
        }
    }, []);

    if (newPipeline?.useInternalBucket === true) {
        return <Navigate to={`/pipelines/${newPipeline.id}`} />;
    }

    if (newPipeline) {
        return <Navigate to={`/pipelines/${newPipeline.id}/settings`} />;
    }

    return (
        <QuiModalDialog className={styles.modal} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent style={{ width: '450px' }}>
                <QuiFlexBoxColumn gap="md">
                    <QuiText size="text-lg" weight="medium">
                        Create A New Pipeline
                    </QuiText>
                    <QuiForm<FormState> initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
                        <FormSpy>{({ error }) => <div>{error}</div>}</FormSpy>
                        <FormContent errorToShow={errorToShow} setErrorToShow={setErrorToShow} onClose={onClose} />
                    </QuiForm>
                </QuiFlexBoxColumn>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
