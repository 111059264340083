import { QuiButton, QuiBox, useQuiModal } from '@tonicai/ui-quinine';
import { useDataset } from '../../stores/datasets';
import { FileSource } from '../../types';
import { UploadExternalFilesModal } from './UploadExternalFilesModal';

type UploadExternalFilesButtonProps = Readonly<{ datasetId: string; onFilesSelected: (files: File[]) => void; fileSource: FileSource }>;

export function UploadExternalFilesButton({ datasetId, onFilesSelected, fileSource }: UploadExternalFilesButtonProps) {
    const modal = useQuiModal();
    const dataset = useDataset(datasetId);

    if (!dataset) {
        return null;
    }

    return (
        <QuiBox>
            <QuiButton iconLeft={'upload'} variant="brand-purple" onClick={modal.open}>
                {'Scan Files'}
            </QuiButton>
            <UploadExternalFilesModal
                dataset={dataset}
                isOpen={modal.isOpen}
                onClose={modal.close}
                onFilesSelected={onFilesSelected}
                fileSource={fileSource}
            />
        </QuiBox>
    );
}
