import { QuiSelect } from '@tonicai/ui-quinine';
import { useDatasetTemplates } from './useDatasetTemplates';
import { useCallback, useMemo } from 'react';
import { SingleValue } from 'react-select';

type TemplateOption = {
    label: string;
    value: string;
};

type TemplateSelectProps = Readonly<{
    datasetId: string;
    value?: string;
    onChange: (value?: string) => void;
}>;

export function TemplateSelect({ datasetId, value, onChange }: TemplateSelectProps) {
    const { templates, fetchStatus } = useDatasetTemplates(datasetId);

    const options: TemplateOption[] = useMemo(() => {
        return (templates ?? []).map((template) => ({
            label: template.name,
            value: template.id,
        }));
    }, [templates]);

    const handleOnChange = useCallback(
        (v: SingleValue<TemplateOption>) => {
            onChange(v?.value ?? undefined);
        },
        [onChange]
    );

    const selectedValue = useMemo(() => {
        return options.find((option) => option.value === value);
    }, [value, options]);

    const placeholder = useMemo(() => {
        if (fetchStatus === 'loading' || fetchStatus === 'init') {
            return 'Loading Templates...';
        }
        if (fetchStatus === 'error') {
            return 'Error Loading Templates';
        }
        if (fetchStatus === 'success' && options.length === 0) {
            return 'No Templates Found';
        }
        return 'Select a template';
    }, [options, fetchStatus]);

    return (
        <QuiSelect<TemplateOption>
            isClearable
            disabled={options.length === 0 || fetchStatus !== 'success'}
            placeholder={placeholder}
            onChange={handleOnChange}
            value={selectedValue}
            options={options}
            menuPortalTarget={document.body}
        />
    );
}
