import { PRICE_PER_1000_WORDS } from '@/types';
import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiIcon, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';

type FreeTrialDialogProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    onLearnMore: () => void;
}>;

export function FreeTrialDialog({ isOpen, onClose, onLearnMore }: FreeTrialDialogProps) {
    return (
        <QuiModalDialog isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiFlexBoxColumn padding="md xs" gap="lg" style={{ width: '320px' }}>
                    <QuiBox>
                        <QuiBox display="inline-block" padding="md" borderRadius="md" bg="green-500" color="white">
                            <QuiIcon size="xl" icon="gift" />
                        </QuiBox>
                    </QuiBox>

                    <QuiBox text="text-md" weight="medium">
                        Free 100,000 words!
                    </QuiBox>

                    <QuiBox color="text-secondary">
                        To help you get started we give you 100K free words to use with your projects. Textual uses words and not tokens for tracking
                        usage.
                    </QuiBox>
                    <QuiBox color="text-secondary">{`After that it's just $${PRICE_PER_1000_WORDS.toFixed(2)} per 1,000 words.`}</QuiBox>

                    <QuiBox display="flex" gap="sm">
                        <QuiButton type="button" onClick={onClose} variant="green" iconRight="arrow-right">
                            Let's go
                        </QuiButton>
                        <QuiButton type="button" onClick={onLearnMore} iconRight="arrow-up-right">
                            Learn More
                        </QuiButton>
                    </QuiBox>
                </QuiFlexBoxColumn>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
