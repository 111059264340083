import { QuiBox, QuiButton, QuiButtonLink, QuiPopover, useQuiModal } from '@tonicai/ui-quinine';
import { WordCountBox } from './WordCountBox';
import { useCurrentBillingCycleUsage, useLicenseDetails, useLifetimeUsage, useMaximumNumberOfWords, useStripeMetadata } from '@/stores';
import { useMemo } from 'react';
import styles from './WordCountButton.module.scss';
import { formatWordCount } from './utils';

export function WordCountButton() {
    const popover = useQuiModal();
    const currentBillingCycleUsage = useCurrentBillingCycleUsage();
    const maximumNumberOfWords = useMaximumNumberOfWords();
    const licenseDetails = useLicenseDetails();
    const lifeTimeUsage = useLifetimeUsage();
    const stripeUsage = useStripeMetadata()?.wordsUsed ?? 0;
    const wordsUsed = useMemo(() => {
        if (licenseDetails?.category === 'FreeTrial' || licenseDetails?.category === 'Custom') {
            return lifeTimeUsage;
        }
        return stripeUsage;
    }, [licenseDetails?.category, lifeTimeUsage, stripeUsage]);

    const label = useMemo(() => {
        if (maximumNumberOfWords === 0) {
            return `Usage: ${formatWordCount(wordsUsed)} words`;
        }

        return `${formatWordCount(currentBillingCycleUsage)} of ${formatWordCount(maximumNumberOfWords)} words`;
    }, [currentBillingCycleUsage, wordsUsed, maximumNumberOfWords]);

    if (licenseDetails?.category === 'CloudSubscription') {
        return (
            <QuiButtonLink variant="green" size="sm" to="/billing">
                {label}
            </QuiButtonLink>
        );
    }

    const buttonVariant = wordsUsed > maximumNumberOfWords ? 'destructive' : 'green';

    return (
        <QuiPopover
            transitionDuration={0}
            isOpen={popover.isOpen}
            onClose={popover.close}
            placement="bottom-end"
            content={
                <QuiBox style={{ width: '320px' }}>
                    <WordCountBox variant="no-border" onClick={popover.close} />
                </QuiBox>
            }
        >
            <QuiButton
                iconRight="chevron-down"
                size="sm"
                onClick={popover.toggle}
                variant={buttonVariant}
                className={styles.navMenuButton}
                iconLeft="info"
            >
                {label}
            </QuiButton>
        </QuiPopover>
    );
}
