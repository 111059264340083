import { isAxiosError } from 'axios';

/**
 * Detect whether an error is due to invalid AWS credentials. This works
 * by parsing the error argument and figuring out if it has the same text
 * as the error message returned by the backend.
 */
export function isAwsCredentialsError(error: unknown) {
    if (!isAxiosError(error)) return false;

    const errorString = error.response?.data ?? null;

    return typeof errorString === 'string' && errorString.startsWith('Invalid AWS Credentials: ');
}

export function getAwsCredentialsError(error: unknown) {
    if (!isAxiosError(error)) return null;

    const errorString = error.response?.data ?? null;

    if (!isAwsCredentialsError(error)) {
        return null;
    }

    return errorString.replace('Invalid AWS Credentials: ', '');
}

export function isAzureCredentialsError(error: unknown) {
    if (!isAxiosError(error)) return false;

    const errorString = error.response?.data ?? null;

    return typeof errorString === 'string' && errorString.includes('blob.core.windows.net');
}
