import { ReactNode, useEffect, useMemo } from 'react';
import { PlaygroundContext } from './PlaygroundContext';
import { UseAtomOptions } from '../../stores/globalStore';
import { createStore } from 'jotai';
import { timeoutIdAtom } from './state';

type PlaygroundProviderProps = Readonly<{ children: ReactNode }>;

export function PlaygroundProvider({ children }: PlaygroundProviderProps) {
    // Create memoized Jotai store, this helps with StrictMode in development
    // but also allows us to have multiple playground instances that are entirely
    // independentent of one another
    const storeOptions = useMemo<UseAtomOptions>(() => ({ store: createStore() }), []);

    useEffect(() => {
        // Clean up anything that might still be happening in the background
        return () => {
            const timeoutId = storeOptions.store.get(timeoutIdAtom);

            if (typeof timeoutId === 'number') {
                clearTimeout(timeoutId);
            }
        };
    }, [storeOptions]);

    return <PlaygroundContext.Provider value={storeOptions}>{children}</PlaygroundContext.Provider>;
}
