import { Content, DetectTextEntitiesSingleModel, RedactedTextChunk } from '@/types';
import { nanoid } from 'nanoid';
import fileParseJobFileStyles from './FileParseJobFile.module.scss';

//
// To show highlighted entities like we do on the "Original" tab,
// set this to "true", or just remove the conditional check that
//
const ENABLE_ENTITIES = false;

const redactedTextClassName = fileParseJobFileStyles.redactedText;
const redactedTextLabelClassName = fileParseJobFileStyles.redactedTextLabel;

function getRedactionTextChunksForPlayground(content: Content, offset: number): RedactedTextChunk[] {
    const text = content?.text ?? '';
    const entities = content?.entities ?? [];

    const textChunks: RedactedTextChunk[] = [];

    let currentChunkString = '';
    let inRedaction: boolean | null = false;
    let previousRedaction: DetectTextEntitiesSingleModel | undefined = undefined;

    for (let i = 0; i < text.length; i++) {
        const actualI = i + offset;
        const detectedEntities = entities;
        const currentRedaction = detectedEntities.find((d) => {
            return actualI >= d.start && actualI < d.end;
        });

        const currentIndexInRedaction = currentRedaction !== undefined;

        if (currentIndexInRedaction !== inRedaction) {
            if (currentChunkString.length > 0) {
                textChunks.push({
                    id: nanoid(),
                    text: currentChunkString,
                    label: previousRedaction?.label,
                    score: previousRedaction?.score,
                });
                currentChunkString = '';
            }
            previousRedaction = currentRedaction;
            inRedaction = currentIndexInRedaction;
        }

        currentChunkString += text.charAt(i);

        if (i === text.length - 1) {
            textChunks.push({
                id: nanoid(),
                text: currentChunkString,
                label: previousRedaction?.label,
                score: previousRedaction?.score,
            });
        }
    }

    return textChunks;
}

type RenderContentProps = Readonly<{
    content?: Content;
}>;

export function RenderContent({ content }: RenderContentProps) {
    if (!content) {
        return <>&nbsp;</>;
    }

    if (content.text) {
        if (ENABLE_ENTITIES) {
            const chunks = getRedactionTextChunksForPlayground(content, 0);

            const markdownWithHighlightedRedactions = chunks
                .map((c) => {
                    if ('label' in c && typeof c.label === 'string') {
                        // If we ever add synthesize support, something like this will be needed:
                        // const redactedLabel = c.exampleRedaction ? c.exampleRedaction : c.label;
                        return `<span class="${redactedTextClassName}">${c.text} <span class="${redactedTextLabelClassName}">${c.label}</span></span>`;
                    } else {
                        return c.text;
                    }
                })
                .join('');

            return <span dangerouslySetInnerHTML={{ __html: markdownWithHighlightedRedactions }} />;
        } else {
            return content?.text ?? '';
        }
    }

    return null;
}
