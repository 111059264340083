import { Navigate, Outlet, useParams } from 'react-router-dom';
import { datasetsLoadedAtom, useDataset } from '../../stores/datasets';
import { useAtomValue } from 'jotai';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { DatasetProvider } from '../../contexts/DatasetContext';

// This component is just to ensure that the `datasetId` in the URL exists and
// the corresponding dataset also exists. This makes using hooks much easier,
// since we don't have to handle case where data is missing.
export function DatasetGuard() {
    const { datasetId = null } = useParams();
    const datasetsLoaded = useAtomValue(datasetsLoadedAtom);
    const dataset = useDataset(datasetId);

    if (!datasetsLoaded) {
        return (
            <QuiBox display="flex" padding="lg" justifyContent="center">
                <QuiSpinner />
            </QuiBox>
        );
    }

    if (!dataset || !datasetId) {
        return <Navigate to="/" />;
    }

    return (
        <DatasetProvider dataset={dataset}>
            <Outlet />
        </DatasetProvider>
    );
}
