import { QuiSegmentedControl, QuiSegmentedControlButton } from '@tonicai/ui-quinine';
import { ManualPdfRedactionType, PdfRedactionType } from '../../types';
import { PDF_REDACTION_TYPE_ICON_MAP, REDACTION_TYPE_LABELS } from '../PdfPage/PdfRedactionsControls';
import styles from './NewAreaTypeSegmentedControl.module.scss';

type AddAreaMenuItemProps = Readonly<{
    type: ManualPdfRedactionType;
}>;

function NewAreaButton({ type }: AddAreaMenuItemProps) {
    return (
        <QuiSegmentedControlButton value={type} iconLeft={PDF_REDACTION_TYPE_ICON_MAP[type]}>
            {REDACTION_TYPE_LABELS[type]}
        </QuiSegmentedControlButton>
    );
}

type AddAreaDropdownProps = Readonly<{
    onSelect: (type: ManualPdfRedactionType) => void;
    pdfRedactionType?: ManualPdfRedactionType;
    disabled?: boolean;
}>;

export function NewAreaTypeSegmentedControl({ onSelect, pdfRedactionType, disabled }: AddAreaDropdownProps) {
    return (
        <QuiSegmentedControl<ManualPdfRedactionType> className={styles.wrapper} value={pdfRedactionType} onChange={onSelect} disabled={disabled}>
            <NewAreaButton type={PdfRedactionType.RemoveRedaction} />
            <NewAreaButton type={PdfRedactionType.AddRedaction} />
        </QuiSegmentedControl>
    );
}
