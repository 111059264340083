import { QuiBadge, QuiSpinner, QuiTooltip } from '@tonicai/ui-quinine';
import { FileUploadPipelineFileRowStatus } from '../../contexts/FileUploadPipeline/fileUploadPipelineAPI';

type FileUploadPipelineFileStatusBadgeProps = Readonly<{
    status: FileUploadPipelineFileRowStatus;
    errorMessage?: string;
    ['data-test']?: string;
}>;

export function FileUploadPipelineFileStatusBadge({ status, errorMessage, ['data-test']: dataTest }: FileUploadPipelineFileStatusBadgeProps) {
    if (status === 'Canceled') {
        return (
            <QuiBadge data-test={dataTest} size="sm" variant="gold">
                Cancelled
            </QuiBadge>
        );
    }

    if (status === 'uploading') {
        return (
            <QuiBadge data-test={dataTest} size="sm">
                <QuiSpinner size="xs" /> Uploading
            </QuiBadge>
        );
    }

    if (status === 'Running') {
        return (
            <QuiBadge data-test={dataTest} size="sm">
                <QuiSpinner size="xs" /> Running
            </QuiBadge>
        );
    }

    if (status === 'error') {
        return (
            <QuiTooltip disabled={!errorMessage} content={errorMessage} placement="top">
                <QuiBadge data-test={dataTest} size="sm" variant="red">
                    Error
                </QuiBadge>
            </QuiTooltip>
        );
    }

    if (status === 'Failed') {
        return (
            <QuiTooltip disabled={!errorMessage} content={errorMessage} placement="top">
                <QuiBadge data-test={dataTest} size="sm" variant="red">
                    Failed
                </QuiBadge>
            </QuiTooltip>
        );
    }

    if (status === 'success') {
        return (
            <QuiBadge data-test={dataTest} size="sm" variant="green">
                Success
            </QuiBadge>
        );
    }

    if (status === 'Skipped') {
        return (
            <QuiBadge data-test={dataTest} size="sm" variant="default">
                Skipped
            </QuiBadge>
        );
    }

    if (status === 'Completed') {
        return (
            <QuiBadge data-test={dataTest} size="sm" variant="blue">
                Completed
            </QuiBadge>
        );
    }

    if (status === 'Queued') {
        return (
            <QuiBadge data-test={dataTest} size="sm" variant="default">
                Queued
            </QuiBadge>
        );
    }

    return null;
}
