import { QuiButton, QuiTooltip, useQuiToasts } from '@tonicai/ui-quinine';
import { startFileParseJob } from '../../stores/parse-jobs';
import { usePipelineContext } from './usePipelineContext';

export function RunPipelineButton() {
    const addToast = useQuiToasts();
    const { parseJobConfig, canRunPipelineJob } = usePipelineContext();

    return (
        <QuiTooltip
            disabled={canRunPipelineJob}
            content="To process files, you first must select both an output path & input files/directories on the Settings page"
        >
            <QuiButton
                variant="brand-purple"
                type="button"
                iconLeft="play-circle"
                disabled={!canRunPipelineJob}
                onClick={() => {
                    startFileParseJob(parseJobConfig.id)
                        .then(() => {
                            addToast({
                                title: 'Pipeline run added to queue',
                                variant: 'positive',
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            addToast({
                                title: 'Pipeline run failed to be created',
                                variant: 'destructive',
                            });
                        });
                }}
            >
                Run Pipeline
            </QuiButton>
        </QuiTooltip>
    );
}
