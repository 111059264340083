export const PIPELINE_FILE_UPLOAD_STATUSES = ['error', 'success', 'uploading'] as const;

export type PipelineFileUploadStatus = (typeof PIPELINE_FILE_UPLOAD_STATUSES)[number];

export type PipelineFileUpload = {
    id: string;
    fileName: string;
    fileSize: number;
    percentCompleted: number;
    uploadStatus: PipelineFileUploadStatus;
    errorMessage?: string;
    abortController: AbortController | null;
};
