import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isSSOProvider, urlSearchParamsToObject } from '../../utils';
import { setSSOCallbackData } from '../../stores/auth';
import { useEffect } from 'react';
import { FullScreenLoading } from '../../components/FullScreenLoading/FullScreenLoading';

export function SSOCallback() {
    const { ssoProvider } = useParams();
    const [searchParams] = useSearchParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (isSSOProvider(ssoProvider)) {
            if (hash) {
                for (const [key, value] of new URLSearchParams(hash.substring(1))) {
                    searchParams.set(key, value);
                }
            }
            const error = searchParams.get('error');
            const errorDescription = searchParams.get('error_description');
            const token = searchParams.get('id_token') ?? searchParams.get('code');

            if (error || errorDescription || !token) {
                setSSOCallbackData({
                    type: 'error',
                    error,
                    errorDescription,
                });
            } else {
                setSSOCallbackData({
                    token,
                    searchParams: urlSearchParamsToObject(searchParams),
                    // originalRedirectUrl: appURL(`/sso/callback/${ssoProvider}`),
                    provider: ssoProvider,
                    type: 'code',
                });
            }
        } else {
            setSSOCallbackData(null);
        }

        navigate('/?ssoCallback=true');
    }, [navigate, ssoProvider, searchParams, hash]);

    return <FullScreenLoading />;
}
