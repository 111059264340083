import { useQuiToasts } from '@tonicai/ui-quinine';
import { deleteDataset } from '../../stores/datasets';
import { Dataset } from '../../types';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';

type DeleteDatasetModalProps = Readonly<{
    dataset: Dataset;
    isOpen: boolean;
    onClose: () => void;
}>;

export function DeleteDatasetModal({ dataset, isOpen, onClose }: DeleteDatasetModalProps) {
    const addToast = useQuiToasts();
    return (
        <DeleteDialog
            title="Delete Dataset Confirmation"
            isOpen={isOpen}
            closeDialog={onClose}
            deleteMethod={() =>
                deleteDataset(dataset.id)
                    .then(() => {
                        addToast({
                            title: 'Dataset deleted successfully',
                            variant: 'positive',
                        });
                    })
                    .catch(() => {
                        addToast({
                            title: 'Dataset could not be deleted successfully',
                            variant: 'destructive',
                        });
                    })
                    .finally(onClose)
            }
            itemType={'dataset'}
            itemName={dataset.name}
        />
    );
}
