import { useEffect, useState } from 'react';
import { fetchPdfRedactionTemplates } from '../../stores/pdfRedactionTemplates';
import { FetchStatus, PdfRedactionTemplateBase } from '../../types';
import { isAxiosError, isCancel } from 'axios';

export function useDatasetTemplates(datasetId: string) {
    const [templates, setTemplates] = useState<PdfRedactionTemplateBase[] | undefined>(undefined);
    const [fetchStatus, setFetchStatus] = useState<FetchStatus>('init');

    useEffect(() => {
        setFetchStatus('loading');
        setTemplates(undefined);

        fetchPdfRedactionTemplates(datasetId)
            .then((response) => {
                setTemplates(response);
                setFetchStatus('success');
            })
            .catch((error) => {
                if (isAxiosError(error) && isCancel(error)) {
                    // Do nothing
                } else {
                    setFetchStatus('error');
                }
            });
    }, [datasetId]);

    return {
        templates,
        fetchStatus,
    };
}
