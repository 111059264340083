import { useCallback, useEffect, useState } from 'react';

export default function useIsMobile(mobileScreenSize = 768) {
    const [isMobile, setIsMobile] = useState(window.matchMedia(`(max-width: ${mobileScreenSize}px)`).matches);

    const checkIsMobile = useCallback((event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
    }, []);

    useEffect(() => {
        const mediaListener = window.matchMedia(`(max-width: ${mobileScreenSize}px)`);
        try {
            mediaListener.addEventListener('change', checkIsMobile);
        } catch (e) {
            console.error(e);
        }

        return () => {
            try {
                mediaListener.removeEventListener('change', checkIsMobile);
            } catch (e) {
                console.error(e);
            }
        };
    }, [mobileScreenSize, checkIsMobile]);

    return isMobile;
}
