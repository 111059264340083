export function getBlob(pdfData: string) {
    const base64 = pdfData.startsWith('data:application/pdf;base64,') ? pdfData.split('data:application/pdf;base64,')[1] : pdfData;
    const bytes = atob(base64);
    const byteNumbers = new Array(bytes.length);
    for (let i = 0; i < bytes.length; i++) {
        byteNumbers[i] = bytes.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
}
