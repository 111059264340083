import { QuiBox, QuiButton, QuiDivider, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { TemplateSelect } from './TemplateSelect';
import { useState } from 'react';

export type NewOrExistingTemplateDialogProps = Readonly<{
    isOpen: boolean;
    datasetId: string;
    onClose: () => void;
    createNewTemplate: () => void;
    editExistingTemplate: (templateId: string) => void;
}>;

export function NewOrExistingTemplateDialog({
    isOpen,
    onClose,
    datasetId,
    editExistingTemplate,
    createNewTemplate,
}: NewOrExistingTemplateDialogProps) {
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);

    return (
        <QuiModalDialog icon="info" title="Create Or Select A PDF Template" isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" flexDirection="column" gap="xl">
                    <QuiBox>Would you like to create a new PDF template or edit an existing PDF template?</QuiBox>

                    <QuiBox display="flex" flexDirection="column" gap="lg">
                        <QuiBox display="flex" flexDirection="column" gap="sm">
                            <QuiButton onClick={createNewTemplate} variant="brand-purple">
                                Create A New Template
                            </QuiButton>
                        </QuiBox>

                        <QuiDivider>Or</QuiDivider>

                        <QuiBox display="flex" flexDirection="column" gap="sm">
                            <QuiBox>Edit An Existing Template</QuiBox>
                            <QuiBox display="flex" alignItems="center" gap="md">
                                <TemplateSelect onChange={setSelectedTemplateId} value={selectedTemplateId} datasetId={datasetId} />
                                <QuiButton
                                    onClick={() => {
                                        if (selectedTemplateId) {
                                            editExistingTemplate(selectedTemplateId);
                                        }
                                    }}
                                    disabled={!selectedTemplateId}
                                    variant="brand-purple"
                                >
                                    Edit Selected Template
                                </QuiButton>
                            </QuiBox>
                        </QuiBox>
                    </QuiBox>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
