import { useFieldArray } from 'react-final-form-arrays';
import { QuiBox, QuiBadgeProps, QuiIconToken } from '@tonicai/ui-quinine';
import { ManualPdfRedactionType, PdfRedaction, PdfRedactionType } from '../../types';
import { PdfRedactionRow } from './PdfRedactionRow';

export const PDF_REDACTION_TYPE_BADGE_VARIANT_MAP: Record<ManualPdfRedactionType, QuiBadgeProps['variant']> = {
    [PdfRedactionType.AddRedaction]: 'brand-purple',
    [PdfRedactionType.RemoveRedaction]: 'disabled',
};

export const PDF_REDACTION_TYPE_ICON_MAP: Record<ManualPdfRedactionType, QuiIconToken> = {
    [PdfRedactionType.AddRedaction]: 'shield',
    [PdfRedactionType.RemoveRedaction]: 'slash',
};

export const REDACTION_TYPE_LABELS: Record<ManualPdfRedactionType, string> = {
    [PdfRedactionType.AddRedaction]: 'Add Manual Redaction',
    [PdfRedactionType.RemoveRedaction]: 'Ignore Redactions',
};

type RedactionsControlsProps = Readonly<{
    setEditingRedactionIndex: (index: number) => void;
    editingRedactionIndex: number | undefined;
    fieldName: string;
    pageIndex: number;
}>;

export function PdfRedactionsControls({ setEditingRedactionIndex, editingRedactionIndex, fieldName, pageIndex }: RedactionsControlsProps) {
    const { fields } = useFieldArray<PdfRedaction>(fieldName);
    return (
        <QuiBox>
            <QuiBox display="flex">
                <QuiBox weight="bold">Redactions</QuiBox>
            </QuiBox>
            <QuiBox display="flex" flexDirection="column" gap="sm">
                {fields.length === 0 && <QuiBox text={'text-xs'}>No redactions on this page</QuiBox>}
                {fields.map((field, index) => (
                    <PdfRedactionRow
                        remove={() => {
                            fields.remove(index);
                            setEditingRedactionIndex(-1);
                        }}
                        name={field}
                        key={field}
                        isActive={editingRedactionIndex === index}
                        setEditingRedactionIndex={() => {
                            setEditingRedactionIndex(index);
                        }}
                        pageIndex={pageIndex}
                    />
                ))}
            </QuiBox>
        </QuiBox>
    );
}
