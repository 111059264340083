import { atom, useAtomValue } from 'jotai';

import {
    CreateParseJobConfigRequest,
    FetchStatus,
    FileParseJobResponse,
    FilesParsedResponse,
    ParseJobConfigResponse,
    UpdateParseJobConfigRequest,
} from '../types';

import { crudGenerator } from './atom-creators';
import { globalStore } from './globalStore';
import { client } from '../services/HTTPClient';
import { useMemo } from 'react';
import { isAxiosError, isCancel } from 'axios';

export const {
    dataAtom: parseJobsAtom,
    fetchData: fetchParseJobs,
    createItem: createParseJob,
    updateItem: updateParseJob,
    deleteItem: deleteParseJob,
    fetchItem: fetchParseJob,
    statusAtom: parseJobsStatusAtom,
    useDataValue: useParseJobs,
} = crudGenerator<ParseJobConfigResponse, CreateParseJobConfigRequest, Partial<UpdateParseJobConfigRequest>>('/api/parsejobconfig');

type ParseJobConfigFileParseJobState = {
    fetchStatus: Exclude<FetchStatus, 'accepted'>;
    data: FileParseJobResponse[];
    abortController: AbortController | null;
};

// A global store of all the FileParseJobs stored by the key of the ParseJobConfig that they're associated with
// The object looks like:
// {
//     [parseJobConfigId_1]: {
//         status: 'loading' | 'refreshing' | 'success' | 'error';
//         data: FileParseJobResponse[];
//         abortController: AbortController | null;
//     },
//     [parseJobConfigId_2]: {
//         status: 'loading' | 'refreshing' | 'success' | 'error';
//         data: FileParseJobResponse[];
//         abortController: AbortController | null;
//     },
// }
//
export const parseJobConfigFileParseJobsAtom = atom<Record<string, ParseJobConfigFileParseJobState>>({});

export const parseJobConfigsHasQueuedOrActiveJobAtom = atom<Record<string, boolean>>((get) => {
    const parseJobConfigFileParseJobs = get(parseJobConfigFileParseJobsAtom);

    const results: Record<string, boolean> = {};

    for (const [parseJobConfigId, parseJobConfigFileParseJob] of Object.entries(parseJobConfigFileParseJobs)) {
        const hasActiveOrQueuedJob = parseJobConfigFileParseJob.data.some((job) => job.status === 'Running' || job.status === 'Queued');

        results[parseJobConfigId] = hasActiveOrQueuedJob;
    }

    return results;
});

export function fetchParseJobConfigFileParseJobs(parseJobConfigId: string) {
    const abortController = new AbortController();

    const currentState = globalStore.get(parseJobConfigFileParseJobsAtom);

    currentState?.[parseJobConfigId]?.abortController?.abort();

    globalStore.set(parseJobConfigFileParseJobsAtom, {
        ...currentState,
        [parseJobConfigId]: {
            data: currentState?.[parseJobConfigId]?.data ?? [],
            fetchStatus:
                currentState?.[parseJobConfigId]?.fetchStatus === 'success' || currentState?.[parseJobConfigId]?.fetchStatus === 'refreshing'
                    ? 'refreshing'
                    : 'loading',

            abortController,
        },
    });

    return client
        .get<FileParseJobResponse[]>(`/api/parsejob/${parseJobConfigId}/jobs`, { signal: abortController.signal })
        .then((response) => {
            globalStore.set(parseJobConfigFileParseJobsAtom, {
                ...globalStore.get(parseJobConfigFileParseJobsAtom),
                [parseJobConfigId]: {
                    data: response.data,
                    fetchStatus: 'success',
                    abortController: null,
                },
            });
        })
        .catch((error) => {
            if (isCancel(error)) return;

            if (isAxiosError(error) && error.response?.status === 404) {
                globalStore.set(parseJobConfigFileParseJobsAtom, {
                    ...globalStore.get(parseJobConfigFileParseJobsAtom),
                    [parseJobConfigId]: {
                        data: [],
                        fetchStatus: 'not-found',
                        abortController: null,
                    },
                });
            } else {
                globalStore.set(parseJobConfigFileParseJobsAtom, {
                    ...globalStore.get(parseJobConfigFileParseJobsAtom),
                    [parseJobConfigId]: {
                        data: [],
                        fetchStatus: 'error',
                        abortController: null,
                    },
                });
            }
        });
}

export function useParseJobConfigFileParseJobs(parseJobConfigId: string) {
    const atomMemoized = useMemo(() => {
        return atom<ParseJobConfigFileParseJobState>((get) => {
            const parseJobConfigFileParseJobs = get(parseJobConfigFileParseJobsAtom);

            if (parseJobConfigFileParseJobs && parseJobConfigId in parseJobConfigFileParseJobs) {
                return parseJobConfigFileParseJobs[parseJobConfigId];
            }

            return {
                fetchStatus: 'init',

                data: [],
                abortController: null,
            };
        });
    }, [parseJobConfigId]);

    return useAtomValue(atomMemoized);
}

export function useParseJobConfigHasQueuedOrActiveParseJobs(parseJobConfigId: string) {
    const atomMemoized = useMemo(() => {
        return atom<boolean>((get) => {
            return get(parseJobConfigsHasQueuedOrActiveJobAtom)?.[parseJobConfigId] === true;
        });
    }, [parseJobConfigId]);

    return useAtomValue(atomMemoized);
}

// Parse Job Config All Files
// A global store of all the Parse Job Files List stored by the key of the ParseJobConfig that they're associated with
// The object looks like:
// {
//     [parseJobConfigId_1]: {
//         status: 'loading' | 'refreshing' | 'success' | 'error';
//         data: FilesParsedResponse;
//         abortController: AbortController | null;
//     },
//     [parseJobConfigId_2]: {
//         status: 'loading' | 'refreshing' | 'success' | 'error';
//         data: FilesParsedResponse;
//         abortController: AbortController | null;
//     },
// }
type ParseJobConfigFilesState = {
    data: FilesParsedResponse | null;
    abortController: AbortController | null;
    fetchStatus: Exclude<FetchStatus, 'accepted'>;
};

export const parseJobConfigFilesAtom = atom<Record<string, ParseJobConfigFilesState>>({});

export function fetchParseJobConfigFiles(parseJobConfigId: string) {
    const abortController = new AbortController();

    const currentState = globalStore.get(parseJobConfigFilesAtom);

    currentState?.[parseJobConfigId]?.abortController?.abort();

    globalStore.set(parseJobConfigFilesAtom, {
        ...currentState,
        [parseJobConfigId]: {
            data: currentState?.[parseJobConfigId]?.data ?? null,
            fetchStatus:
                currentState?.[parseJobConfigId]?.fetchStatus === 'success' || currentState?.[parseJobConfigId]?.fetchStatus === 'refreshing'
                    ? 'refreshing'
                    : 'loading',
            abortController,
        },
    });

    return client
        .get<FilesParsedResponse>(`/api/parsejobconfig/${parseJobConfigId}/files/all`, { signal: abortController.signal })
        .then((response) => {
            globalStore.set(parseJobConfigFilesAtom, (prev) => ({
                ...prev,
                [parseJobConfigId]: {
                    data: response.data,
                    fetchStatus: 'success',
                    abortController: null,
                },
            }));
        })
        .catch((error) => {
            if (isCancel(error)) return;

            if (isAxiosError(error) && error.response?.status === 404) {
                globalStore.set(parseJobConfigFilesAtom, (prev) => ({
                    ...prev,
                    [parseJobConfigId]: {
                        data: null,
                        fetchStatus: 'not-found',
                        abortController: null,
                    },
                }));
            } else {
                globalStore.set(parseJobConfigFilesAtom, (prev) => ({
                    ...prev,
                    [parseJobConfigId]: {
                        data: null,
                        fetchStatus: 'error',
                        abortController: null,
                    },
                }));
            }
        });
}

export function useParseJobConfigFiles(parseJobConfigId: string) {
    const atomMemoized = useMemo(() => {
        return atom<ParseJobConfigFilesState>((get) => {
            const parseJobConfigFilesState = get(parseJobConfigFilesAtom);

            if (parseJobConfigFilesState && parseJobConfigId in parseJobConfigFilesState) {
                return parseJobConfigFilesState[parseJobConfigId];
            }

            return {
                fetchStatus: 'init',
                data: null,
                abortController: null,
            };
        });
    }, [parseJobConfigId]);

    return useAtomValue(atomMemoized);
}

export function startFileParseJob(parseJobConfigId: string) {
    return client.post(`/api/parsejobconfig/${parseJobConfigId}/start`).then(() => {
        fetchParseJobConfigFileParseJobs(parseJobConfigId);
    });
}

export function cancelFileParseJob(parseJobConfigId: string, fileParseJobId: string) {
    return client.post(`/api/parsejob/${fileParseJobId}/job/cancel`).then(() => {
        fetchParseJobConfigFileParseJobs(parseJobConfigId);
    });
}
