import { ReactNode, useCallback, useRef, useState } from 'react';
import { QuiBox, QuiButton, useQuiToasts } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import { useIsMounted } from '../../hooks/useIsMounted';
import { ACCEPTED_MIME_TYPES } from '../../types';
import styles from './UploadFiles.module.scss';
import { isAxiosError } from 'axios';

type UploadFilesProps = Readonly<{
    disabled?: boolean;
    onFilesSelected: (files: File[]) => Promise<unknown>;
    text?: ReactNode;
    className?: string;
    showSupportedFileTypes?: boolean;
}>;

export function UploadFiles({ onFilesSelected, disabled, text, className, showSupportedFileTypes }: UploadFilesProps) {
    const [uploading, setUploading] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const isMounted = useIsMounted();
    const addToast = useQuiToasts();

    const isDisabled = disabled || uploading;

    const wrapperClassName = classNames(styles.wrapper, {
        [styles.wrapperActive]: !isDisabled && isDragActive,
        [styles.wrapperDisabled]: isDisabled,
    });

    const handleFilesSelected = useCallback(
        async (files: File[]) => {
            setUploading(true);
            try {
                await onFilesSelected(files);
            } catch (e) {
                if (isAxiosError(e) && e.status === 409) {
                    addToast({
                        variant: 'destructive',
                        title: 'Could not upload file. A file with that name already exists on this dataset.',
                    });
                }
            } finally {
                if (isMounted()) {
                    setUploading(false);
                }
            }
        },
        [onFilesSelected, isMounted, addToast]
    );

    const handleDragEvent = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.type === 'dragenter' || e.type === 'dragover') {
                setIsDragActive(true);
            } else {
                setIsDragActive(false);
            }

            if (e.type === 'drop') {
                const files: File[] = [...e.dataTransfer.files].filter((f) => f.type.startsWith('text/'));

                if (files.length === 0) return;

                handleFilesSelected(files);
            }
        },
        [handleFilesSelected]
    );

    return (
        <div
            className={wrapperClassName}
            onDragEnter={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDrop={handleDragEvent}
            onDragOver={handleDragEvent}
            onClick={() => {
                inputRef.current?.click();
            }}
        >
            <input
                ref={inputRef}
                multiple
                onChange={(e) => {
                    // If we use this inside of a form, we don't want the FormData
                    // value to include these files. Since this is a SPA, the
                    // form data is being managed by us, and not the browser, so
                    // we don't want to inject any of these files into the native
                    // form state of the browser.
                    e.preventDefault();
                    e.stopPropagation();

                    const files = e.target.files;

                    if (!files) return;

                    const filesArray = [...files];

                    if (filesArray.length > 0) {
                        handleFilesSelected(filesArray);
                    }
                }}
                type="file"
                accept={ACCEPTED_MIME_TYPES}
                disabled={isDisabled}
                className={styles.input}
            />
            <QuiButton
                className={className}
                disabled={isDisabled}
                onClick={(e) => {
                    e.stopPropagation();
                    inputRef.current?.click();
                }}
                spinner={uploading}
                type="button"
            >
                {uploading ? 'Uploading files...' : (text ?? 'Select files to upload')}
            </QuiButton>
            <div className={styles.text}>
                <QuiBox display="flex" text="text-xs" flexDirection="column" textAlign="center" gap="sm">
                    <QuiBox text="text-xs" color="text-secondary">
                        {uploading ? '...' : 'or drag and drop files here'}
                    </QuiBox>
                    {showSupportedFileTypes ? (
                        <QuiBox text="text-xs" color="text-secondary">
                            Supports plain text, CSV, PDF, DOCX, XLSX, PNG, JPG, and TIF files.
                        </QuiBox>
                    ) : null}
                </QuiBox>
            </div>
        </div>
    );
}
