import { useFieldArray } from 'react-final-form-arrays';
import { PdfRedaction, PdfRedactionArea, PdfAndImageRedactionSource, PdfRedactionType, PdfWord, PiiTypeGeneratorState, FileType } from '../../types';
import { PdfPage } from './PdfPage';
import { useCallback, useMemo } from 'react';

type PdfPageFieldProps = Readonly<{
    pdfBlob?: string;
    pageIndex?: number;
    pdfRedactionType: PdfRedactionType | undefined;
    pdfRedactionSource: PdfAndImageRedactionSource;
    existingRedactions?: PdfRedaction[];
    pdfWords?: PdfWord[];
    fieldName: string;
    generatorSetup?: Record<string, PiiTypeGeneratorState>;
    selectedFormRedactionIndex?: number;
    pdfScale?: number;
    fileType: FileType;
}>;

export function PdfPageField({
    pdfBlob,
    pageIndex,
    pdfRedactionType,
    pdfRedactionSource,
    existingRedactions,
    pdfWords,
    fieldName,
    generatorSetup,
    selectedFormRedactionIndex,
    pdfScale,
    fileType,
}: PdfPageFieldProps) {
    const field = useFieldArray<PdfRedaction>(fieldName);

    const redactions = useMemo(() => {
        return [
            ...(field.fields.value ?? []).map((r, i) => {
                return { ...r, isSelected: i == selectedFormRedactionIndex };
            }),
            ...(existingRedactions ?? []),
        ];
    }, [field.fields.value, existingRedactions, selectedFormRedactionIndex]);

    const onNewAreaAdded = useCallback(
        (area: PdfRedactionArea) => {
            if (pdfRedactionType) {
                field.fields.push({
                    areas: [area],
                    type: pdfRedactionType,
                    source: pdfRedactionSource,
                });
            }
        },
        [field.fields, pdfRedactionType, pdfRedactionSource]
    );

    if (!pdfBlob || typeof pageIndex !== 'number') {
        return null;
    }

    return (
        <PdfPage
            fileType={fileType}
            onNewAreaAdded={onNewAreaAdded}
            newAreaType={pdfRedactionType}
            pdfBlob={pdfBlob}
            pageIndex={pageIndex}
            isEditable={true}
            redactions={redactions}
            pdfWords={pdfWords}
            isPreview={true}
            newRedactionSource={pdfRedactionSource}
            generatorSetup={generatorSetup}
            pdfScale={pdfScale}
        />
    );
}
