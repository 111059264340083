import { QuiButton, useQuiModal } from '@tonicai/ui-quinine';
import { TemplateDialog } from './TemplateDialog';
import { NewOrExistingTemplateDialog } from './NewOrExistingTemplateDialog';
import { useCallback, useState } from 'react';

type DatasetTemplatesButtonProps = Readonly<{
    datasetId: string;
}>;

export function DatasetTemplatesButton({ datasetId }: DatasetTemplatesButtonProps) {
    const { open: openNewOrExistingTemplateDialog, isOpen: newOrExistingTemplateDialogOpen, close: closeNewOrExistingTemplateDialog } = useQuiModal();
    const { open: openTemplateDialog, isOpen: templateDialogIsOpen, close: closeTemplateDialog } = useQuiModal();
    const [templateId, setTemplateId] = useState<string | undefined>(undefined);

    const createNewTemplate = useCallback(() => {
        closeNewOrExistingTemplateDialog();
        setTemplateId(undefined);
        openTemplateDialog();
    }, [closeNewOrExistingTemplateDialog, openTemplateDialog]);

    const editExistingTemplate = useCallback(
        (templateId: string) => {
            closeNewOrExistingTemplateDialog();
            setTemplateId(templateId);
            openTemplateDialog();
        },
        [closeNewOrExistingTemplateDialog, openTemplateDialog]
    );

    const onCloseTemplateDialog = useCallback(() => {
        closeTemplateDialog();
        setTemplateId(undefined);
    }, [closeTemplateDialog]);

    return (
        <>
            <QuiButton onClick={openNewOrExistingTemplateDialog} type="button" variant="brand-purple">
                PDF Templates
            </QuiButton>
            <NewOrExistingTemplateDialog
                editExistingTemplate={editExistingTemplate}
                createNewTemplate={createNewTemplate}
                isOpen={newOrExistingTemplateDialogOpen}
                onClose={closeNewOrExistingTemplateDialog}
                datasetId={datasetId}
            />
            <TemplateDialog
                setTemplateId={setTemplateId}
                templateId={templateId}
                datasetId={datasetId}
                isOpen={templateDialogIsOpen}
                onClose={onCloseTemplateDialog}
            />
        </>
    );
}
