import {
    fetchUsage,
    useBillingStatus,
    useCurrentBillingCycleUsage,
    useFreeTrialActive,
    useHasActivePlan,
    useLicenseDetails,
    useLifetimeUsage,
    useMaximumNumberOfWords,
} from '@/stores';
import { CloudSubscriptionStatus, FREE_TRIAL_WORD_COUNT } from '@/types';
import { QuiBox, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { SubscriptionBadge } from './SubscriptionBadge';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';
import { useEffect } from 'react';
import { UpgradePlanButton } from './UpgradePlanButton';
import { ManagePaymentButton } from './ManagePaymentButton';

function WordCountHeader() {
    const billingStatus = useBillingStatus();

    if (!billingStatus) return null;

    return (
        <QuiBox display="flex" justifyContent="space-between" alignItems="center">
            <SubscriptionBadge status={billingStatus} />
        </QuiBox>
    );
}

function CurrentUsageCount() {
    const billingStatus = useBillingStatus();
    const lifetimeUsage = useLifetimeUsage();
    const currentBillingCycleUsage = useCurrentBillingCycleUsage();

    if (billingStatus === CloudSubscriptionStatus.Active || billingStatus === CloudSubscriptionStatus.Expired) {
        return (
            <QuiBox text="text-xl" weight="medium">
                {currentBillingCycleUsage.toLocaleString()} {`word${currentBillingCycleUsage === 1 ? '' : 's'}`}
            </QuiBox>
        );
    }

    return (
        <QuiBox
            text="text-xl"
            weight="medium"
        >{`${lifetimeUsage.toLocaleString()} / ${FREE_TRIAL_WORD_COUNT.toLocaleString()} word${lifetimeUsage === 1 ? '' : 's'}`}</QuiBox>
    );
}

function FreeTrialProgressBar() {
    const billingStatus = useBillingStatus();
    const freeTrialActive = useFreeTrialActive();
    const lifetimeUsage = useLifetimeUsage();
    const wordsRemaining = Math.max(0, FREE_TRIAL_WORD_COUNT - lifetimeUsage);

    if (billingStatus === CloudSubscriptionStatus.Active || billingStatus === CloudSubscriptionStatus.Expired) return null;

    return (
        <QuiBox>
            <ProgressBar max={FREE_TRIAL_WORD_COUNT} value={lifetimeUsage} />
            {lifetimeUsage >= FREE_TRIAL_WORD_COUNT ? (
                <QuiBox color="danger">0 words remaining</QuiBox>
            ) : (
                <QuiBox text="text-xs" color={!freeTrialActive ? 'danger' : undefined}>
                    {`${wordsRemaining.toLocaleString('en-US')} word`}
                    {wordsRemaining === 1 ? '' : 's'}
                    {' remaining'}
                </QuiBox>
            )}
        </QuiBox>
    );
}

function CustomPlanProgressBar() {
    const licenseDetails = useLicenseDetails();
    const lifetimeUsage = useLifetimeUsage();
    const maximumNumberOfWords = useMaximumNumberOfWords();
    const wordsRemaining = Math.max(0, maximumNumberOfWords - lifetimeUsage);

    if (licenseDetails?.category !== 'Custom') return null;

    if (maximumNumberOfWords === 0) return null;

    return (
        <QuiFlexBoxColumn gap="sm">
            <ProgressBar max={maximumNumberOfWords} value={lifetimeUsage} />

            <QuiBox text="text-xs" color={wordsRemaining === 0 ? 'danger' : 'text-secondary'}>
                {`${wordsRemaining.toLocaleString('en-US')} word`}
                {wordsRemaining === 1 ? '' : 's'}
                {' remaining'}
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}

type CustomLicenseWordCountProps = Readonly<{
    variant?: 'no-border';
}>;

function CustomLicenseWordCount({ variant }: CustomLicenseWordCountProps) {
    const lifetimeUsage = useLifetimeUsage();
    const licenseDetails = useLicenseDetails();

    return (
        <QuiFlexBoxColumn
            border={variant === 'no-border' ? undefined : 'border-default'}
            borderRadius={variant === 'no-border' ? undefined : 'md'}
            padding={variant === 'no-border' ? undefined : 'md'}
        >
            <div>
                <QuiBox display="inline-block" borderRadius="md" text="text-xs" padding="xs sm" bg="green" color="white">
                    Custom License
                </QuiBox>
            </div>
            {licenseDetails?.wordCount === 0 ? (
                <QuiBox text="text-xl" weight="medium">
                    {lifetimeUsage.toLocaleString()}
                    {` word${lifetimeUsage === 1 ? '' : 's'}`}
                </QuiBox>
            ) : null}
            {typeof licenseDetails?.wordCount === 'number' && licenseDetails?.wordCount > 0 ? (
                <QuiBox text="text-xl" weight="medium">
                    {lifetimeUsage.toLocaleString()}
                    {` word${lifetimeUsage === 1 ? '' : 's'} / ${licenseDetails?.wordCount.toLocaleString()}`}
                </QuiBox>
            ) : null}

            <CustomPlanProgressBar />
        </QuiFlexBoxColumn>
    );
}

type WordCountBoxProps = Readonly<{
    onClick?: () => void;
    variant?: 'no-border';
}>;

export function WordCountBox({ onClick, variant }: WordCountBoxProps) {
    const hasActivePlan = useHasActivePlan();
    const licenseDetails = useLicenseDetails();

    useEffect(() => {
        fetchUsage();
    }, []);

    if (licenseDetails?.category === 'Custom' && typeof licenseDetails?.wordCount === 'number') {
        return <CustomLicenseWordCount variant={variant} />;
    }

    return (
        <QuiFlexBoxColumn
            style={{ maxWidth: '400px' }}
            bg="background-primary"
            gap="md"
            border={variant === 'no-border' ? undefined : 'border-default'}
            borderRadius={variant === 'no-border' ? undefined : 'md'}
            padding={variant === 'no-border' ? undefined : 'md'}
        >
            <WordCountHeader />

            <CurrentUsageCount />

            <FreeTrialProgressBar />

            {!hasActivePlan ? (
                <QuiFlexBoxColumn>
                    <QuiBox weight="medium" text="text-sm">
                        Upgrade Plan
                    </QuiBox>
                    <QuiBox text="text-sm" color="text-secondary">
                        Continue using Tonic Textual by upgrading your plan to Pay As You Go.
                    </QuiBox>
                </QuiFlexBoxColumn>
            ) : null}

            <QuiBox gap="md" display="flex" alignItems="center">
                {hasActivePlan ? <ManagePaymentButton onClick={onClick} /> : <UpgradePlanButton onClick={onClick} />}
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
