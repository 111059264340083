import { ExternalCredentialType } from '../types';
import type { ExternalCredential } from '../types';
import { client } from '../services/HTTPClient';

// Actions
export async function fetchExternalCredentials() {
    const { data } = await client.get<ExternalCredential[]>('/api/externalcredentials');
    return data;
}

export async function createExternalCredential(credentialType: ExternalCredentialType, name: string, value: string) {
    const { data: credential } = await client.post<ExternalCredential>(`/api/externalcredentials/${credentialType}`, { name, value });
    return credential;
}

export async function updateExternalCredential(credentialType: ExternalCredentialType, name: string, value: string) {
    const { data: credential } = await client.put<ExternalCredential>(`/api/externalcredentials/${credentialType}`, { name, value });
    return credential;
}

export async function deleteExternalCredential(credentialType: ExternalCredentialType) {
    await client.delete<ExternalCredential>(`/api/externalcredentials/${credentialType}`);
}
