import classNames from 'classnames';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = Readonly<{
    value: number;
    max: number;
}>;

export function ProgressBar({ value, max }: ProgressBarProps) {
    const percentage = Math.min((value / max) * 100, 100);
    const overLimit = value > max;

    const className = classNames(styles.wrapper, {
        [styles.overLimit]: overLimit,
    });

    return (
        <div className={className}>
            <div className={styles.indicator} style={{ width: `${percentage}%` }} />
        </div>
    );
}
