import { Auth } from '@/components/Auth/Auth';
import { useAtomValue } from 'jotai';
import { showOnboardingSurveyAtom } from '@/stores';
import { OnboardingSurveyDialog } from '../Home/OnboardingSurveyDialog';
import { QuiBox } from '@tonicai/ui-quinine';

export function OnboardingSurvey() {
    const shouldShowSurvey = useAtomValue(showOnboardingSurveyAtom);

    return (
        <QuiBox style={{ margin: '2rem auto', maxWidth: '580px' }}>
            <Auth>
                <OnboardingSurveyDialog shouldShowSurvey={shouldShowSurvey} />
            </Auth>
        </QuiBox>
    );
}
