import { useFormState } from 'react-final-form';
import { Message } from '../Message/Message';

export function FormError() {
    const form = useFormState({
        subscription: {
            submitError: true,
            submitFailed: true,
        },
    });

    if (!form.submitError || !form.submitFailed) {
        return null;
    }

    return <Message variant="error">{form.submitError}</Message>;
}
