import { QuiBox, QuiDivider, QuiFlexBoxColumn, QuiText } from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useHasActivePlan, useStripeMetadata } from '@/stores';
import { PRICE_PER_1000_WORDS } from '../../types';
import { BillingMetadata } from './BillingMetadata';
import { formatBillingDate, formatWordCount } from './utils';

dayjs.extend(advancedFormat);

const dateFormat = 'MM/DD/YYYY';

export function WordCountRightSidebar() {
    const hasActivePlan = useHasActivePlan();
    const stripeMetadata = useStripeMetadata();
    return (
        <QuiFlexBoxColumn gap="md" justifyContent="space-between">
            {hasActivePlan && typeof stripeMetadata?.lastPaymentDate === 'string' && typeof stripeMetadata?.currentBalance === 'number' ? (
                <QuiBox bg="white" borderRadius="md" border="border-default" padding="lg">
                    <QuiFlexBoxColumn gap="sm">
                        {typeof stripeMetadata?.wordsUsed === 'number' ? (
                            <>
                                <QuiText>Current Billing Period</QuiText>
                                <QuiText size="text-lg" weight="bold">
                                    {`${formatWordCount(stripeMetadata.wordsUsed)} words`}
                                </QuiText>
                            </>
                        ) : null}

                        <QuiText size="text-xs" color="text-secondary">
                            Unlimited at ${PRICE_PER_1000_WORDS} per 1,000 words
                        </QuiText>

                        <BillingMetadata lastPaymentDate nextPaymentDate />
                        <QuiDivider />
                        <BillingMetadata currentBalance />

                        {typeof stripeMetadata?.nextPaymentDate === 'string' ? (
                            <QuiBox text="text-xs" color="text-secondary">
                                Invoice for current balance will be billed on {formatBillingDate(new Date(stripeMetadata.nextPaymentDate))}.
                            </QuiBox>
                        ) : null}
                    </QuiFlexBoxColumn>
                </QuiBox>
            ) : null}

            {hasActivePlan && typeof stripeMetadata?.nextPaymentDate === 'string' ? (
                <QuiBox bg="white" borderRadius="md" border="border-default" padding="lg">
                    <QuiFlexBoxColumn gap="sm">
                        <QuiText>Next billing date</QuiText>

                        <QuiText size="text-lg" weight="bold">
                            {dayjs(stripeMetadata.nextPaymentDate).format(dateFormat)}
                        </QuiText>
                        <QuiText size="text-xs" color="text-secondary">
                            Next billing period begins {dayjs(stripeMetadata.nextPaymentDate).format('MMMM Do, YYYY')}.
                        </QuiText>
                    </QuiFlexBoxColumn>
                </QuiBox>
            ) : null}
        </QuiFlexBoxColumn>
    );
}
