import { client } from '../services/HTTPClient';
import { PdfRedaction, FileRedactions } from '../types';
import axios from 'axios';

const getBaseEndpointUrl = (datasetId: string, fileId: string) => `/api/dataset/${datasetId}/pdfredaction/file/${fileId}`;

let fetchFilePdfRedactionsAbortController = new AbortController();

export async function fetchFilePdfRedactions(datasetId: string, fileId: string) {
    fetchFilePdfRedactionsAbortController.abort();
    fetchFilePdfRedactionsAbortController = new AbortController();

    return await client
        .get<FileRedactions>(getBaseEndpointUrl(datasetId, fileId), { signal: fetchFilePdfRedactionsAbortController.signal })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                //do nothing
                return {
                    templateId: undefined,
                    redactions: [],
                };
            } else {
                throw e;
            }
        });
}
export async function updateFilePdfRedactions(datasetId: string, fileId: string, redactions: PdfRedaction[], templateId?: string) {
    const body = {
        redactions: redactions,
        templateId: templateId,
    };

    await client
        .put<FileRedactions>(`${getBaseEndpointUrl(datasetId, fileId)}`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(({ data }) => {
            return data;
        });
}
