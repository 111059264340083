import dayjs from 'dayjs';
import { QuiBox, QuiButton, QuiButtonLink, QuiFlexBoxColumn, QuiFlexH, QuiLink, QuiSpinner, QuiTooltip, useQuiToasts } from '@tonicai/ui-quinine';
import { Message } from '../Message/Message';
import { JobStatusTag } from '../JobStatusTag/JobStatusTag';
import {
    fetchParseJobConfigFileParseJobs,
    startFileParseJob,
    useParseJobConfigFileParseJobs,
    useParseJobConfigHasQueuedOrActiveParseJobs,
} from '../../stores/parse-jobs';
import { useEffect, useMemo } from 'react';
import { Table, TableCellProps, TableColumnConfig } from '../Table/Table';
import { FileParseJobResponse, JobStatus } from '../../types';
import { CancelFileParseJobButton } from '../CancelFileParseJobButton/CancelFileParseJobButton';
import { usePipelineContext } from '../PipelineLayout/usePipelineContext';

function NameCell({ data }: TableCellProps<FileParseJobResponse>) {
    const { parseJobConfigId } = usePipelineContext();
    return (
        <QuiLink data-test="pipeline-run-id" variant="currentcolor" to={`/pipelines/${parseJobConfigId}/runs/${data.id}`}>
            {data.id}
        </QuiLink>
    );
}

function CreatedCell({ data }: TableCellProps<FileParseJobResponse>) {
    const date = dayjs(data.createdDate);

    return (
        <QuiBox>
            <QuiBox>{date.isValid() ? date.fromNow() : '-'}</QuiBox>
            <QuiBox text="text-xs" color="text-secondary">
                {date.isValid() ? date.toString() : '-'}
            </QuiBox>
        </QuiBox>
    );
}

function StatusCell({ data }: TableCellProps<FileParseJobResponse>) {
    const { parseJobConfigId } = usePipelineContext();
    const showCancel = data.status === JobStatus.RUNNING || data.status === JobStatus.QUEUED;

    return (
        <QuiBox display="flex" gap="md" alignItems="center">
            <JobStatusTag data-test="pipeline-file-status" status={data.status} />
            {showCancel ? <CancelFileParseJobButton fileParseJobId={data.id} parseJobConfigId={parseJobConfigId} /> : null}
        </QuiBox>
    );
}

function ViewJobCell({ data }: TableCellProps<FileParseJobResponse>) {
    const { parseJobConfigId } = usePipelineContext();
    return (
        <QuiBox display="flex" justifyContent="end">
            <QuiButtonLink size="sm" iconRight="chevron-right" to={`/pipelines/${parseJobConfigId}/runs/${data.id}`}>
                View Run
            </QuiButtonLink>
        </QuiBox>
    );
}

const columns: TableColumnConfig<FileParseJobResponse>[] = [
    {
        label: 'Job',
        Component: NameCell,
        id: 'job-number',
    },
    {
        label: 'Created',
        Component: CreatedCell,
        id: 'created',
    },
    {
        label: 'Status',
        Component: StatusCell,
        id: 'status',
    },
    {
        label: '',
        Component: ViewJobCell,
        id: 'view-job',
    },
];

export function ParseJobFileParseJobs() {
    const { parseJobConfigId, canRunPipelineJob } = usePipelineContext();
    const addToast = useQuiToasts();

    const { fetchStatus: fileParseJobsStatus, data: fileParseJobs } = useParseJobConfigFileParseJobs(parseJobConfigId);

    const sortedFileParseJobs = useMemo(() => {
        return [...fileParseJobs].sort((a, b) => {
            return dayjs(b.createdDate).valueOf() - dayjs(a.createdDate).valueOf();
        });
    }, [fileParseJobs]);

    const hasActiveOrQueuedJob = useParseJobConfigHasQueuedOrActiveParseJobs(parseJobConfigId);

    // Poll for active or queued jobs so that the UI can update their status
    // without a page refresh
    useEffect(() => {
        if (hasActiveOrQueuedJob === false) return;

        const interval = window.setInterval(() => {
            fetchParseJobConfigFileParseJobs(parseJobConfigId);
        }, 2000);

        return () => {
            window.clearTimeout(interval);
        };
    }, [hasActiveOrQueuedJob, parseJobConfigId]);

    if (fileParseJobsStatus === 'error') {
        return <Message variant="error">Error fetching pipeline runs</Message>;
    }

    if (fileParseJobsStatus === 'loading') {
        return (
            <QuiBox alignItems="center" display="flex" gap="xs" style={{ height: '50vh' }}>
                <QuiFlexH justify="center" gap="md" align="center" fullWidth>
                    <QuiSpinner size="lg" />
                    <span>Loading Pipeline Files...</span>
                </QuiFlexH>
            </QuiBox>
        );
    }

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            {fileParseJobs.length === 0 ? (
                <QuiFlexBoxColumn borderRadius="lg" gap="lg" padding="lg" bg="background-tertiary">
                    <QuiBox text="text-md" weight="medium">
                        No runs found...
                    </QuiBox>
                    <QuiBox color="text-secondary" text="text-sm">
                        {`Once you've configured your job, you can start generating results using the button below. `}
                        <QuiLink variant="currentcolor" external to="https://docs.tonic.ai/textual">
                            View documentation
                        </QuiLink>
                    </QuiBox>
                    <QuiBox>
                        <QuiTooltip
                            disabled={canRunPipelineJob}
                            content="To process files, you first must select both an output path & input files/directories on the Settings page"
                        >
                            <QuiButton
                                iconLeft="plus"
                                disabled={!canRunPipelineJob}
                                onClick={() => {
                                    startFileParseJob(parseJobConfigId)
                                        .then(() => {
                                            addToast({
                                                title: 'Pipeline run added to queue',
                                                variant: 'positive',
                                            });
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            addToast({
                                                title: 'Pipeline run failed to be created',
                                                variant: 'destructive',
                                            });
                                        });
                                }}
                                type="button"
                                variant="brand-purple"
                            >
                                Run first pipeline run
                            </QuiButton>
                        </QuiTooltip>
                    </QuiBox>
                </QuiFlexBoxColumn>
            ) : (
                <Table<FileParseJobResponse> data={sortedFileParseJobs} columns={columns} noDataMessage="No runs found" />
            )}
        </QuiBox>
    );
}
