import { ComponentProps, ReactNode } from 'react';
import { IQuiButtonProps } from '@tonicai/ui-quinine';
import { FileSource } from '../../types';
import { UploadExternalFilesButton } from './UploadExternalFilesButton';
import { UploadLocalFilesButton } from './UploadLocalFilesButton';

type UploadFilesButtonProps = {
    datasetId: string;
    disabled?: boolean;
    onFilesSelected: (files: File[]) => void;
    variant?: IQuiButtonProps['variant'];
    size?: IQuiButtonProps['size'];
    iconLeft?: IQuiButtonProps['iconLeft'];
    children?: ReactNode;
    className?: string;
    spinner?: boolean;
    selectionMode?: 'multiple' | 'single';
    fileSource: FileSource;
} & ComponentProps<'button'>;

export function UploadFilesButton({ disabled, onFilesSelected, selectionMode, fileSource, datasetId, ...buttonProps }: UploadFilesButtonProps) {
    if (fileSource == 'Local')
        return <UploadLocalFilesButton disabled={disabled} onFilesSelected={onFilesSelected} selectionMode={selectionMode} {...buttonProps} />;

    return <UploadExternalFilesButton datasetId={datasetId} onFilesSelected={onFilesSelected} fileSource={fileSource} />;
}
