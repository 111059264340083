import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { QuiBox, QuiSelect } from '@tonicai/ui-quinine';
import styles from './PdfFilePagesPreview.module.scss';
import classNames from 'classnames';
import { SingleValue } from 'react-select';

type PageOption = {
    label: string;
    value: number;
};

export type PdfFilePagesPreviewProps = Readonly<{
    pdfBlob: string;
    pageIndex: number;
    setPageIndex: (newPageIndex: number) => void;
}>;

export function PdfFilePagesPreview({ pdfBlob, pageIndex, setPageIndex }: PdfFilePagesPreviewProps) {
    const [numPages, setNumPages] = useState<number>(0);

    const pageIds = useMemo(() => {
        return new Array(numPages).fill(null).map(() => nanoid());
    }, [numPages]);

    const pageOptions: PageOption[] = useMemo(
        () =>
            new Array(numPages).fill(null).map((_, pageIndex) => ({
                value: pageIndex,
                label: `Page ${pageIndex + 1}`,
            })),
        [numPages]
    );

    const handleLoadSuccess = useCallback((pdf: PDFDocumentProxy) => {
        setNumPages(pdf.numPages);
    }, []);

    const handleOnChange = useCallback(
        (v: SingleValue<PageOption>) => {
            const value = v?.value;
            if (typeof value === 'number') {
                setPageIndex(value);
            }
        },
        [setPageIndex]
    );

    const selectedValue: PageOption = useMemo(() => {
        return {
            value: pageIndex,
            label: `Page ${pageIndex + 1}`,
        };
    }, [pageIndex]);

    return (
        <Document onLoadSuccess={handleLoadSuccess} file={pdfBlob}>
            <QuiBox display="flex" alignItems="center" justifyContent="center" padding="sm">
                <QuiSelect<PageOption> value={selectedValue} onChange={handleOnChange} options={pageOptions} />
            </QuiBox>
            <QuiBox display="flex" gap="md" flexDirection="column">
                {pageIds.map((pageId, index) => {
                    const className = classNames(styles.pageButton, {
                        [styles.active]: index === pageIndex,
                    });
                    return (
                        <button
                            type="button"
                            key={pageId}
                            className={className}
                            onClick={() => {
                                setPageIndex(index);
                            }}
                        >
                            <span className={styles.pageWrapper}>
                                <Page width={90} renderAnnotationLayer={false} renderTextLayer={false} pageIndex={index} />
                            </span>
                            <QuiBox text="text-xs">{`Page ${index + 1}`}</QuiBox>
                        </button>
                    );
                })}
            </QuiBox>
        </Document>
    );
}
