import { QuiBox, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { BarChartData, barChartDataAtom, usageHistoryAtom } from '@/stores';
import { useAtomValue } from 'jotai';
import styles from './BarChart.module.scss';
import {
    FloatingArrow,
    FloatingPortal,
    flip,
    offset,
    arrow,
    shift,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole,
    autoUpdate,
} from '@floating-ui/react';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

const ARROW_HEIGHT = 8;
const GAP = 2;

function BarChartDay({
    date,
    total,
    max,
}: BarChartData & {
    max: number;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef<SVGSVGElement>(null);
    const offsetValue = ARROW_HEIGHT + GAP;
    const { refs, context, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            flip(),
            shift(),
            offset(offsetValue),
            arrow({
                padding: GAP,
                element: arrowRef,
            }),
        ],
    });

    const dismiss = useDismiss(context);
    const role = useRole(context, {
        role: 'tooltip',
    });

    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role, hover]);

    return (
        <>
            <div className={styles.day} {...getReferenceProps()} ref={refs.setReference}>
                <div className={styles.dayUsage} style={{ height: `${(total / max) * 100.0}%` }} />
            </div>
            <FloatingPortal>
                {isOpen && (
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                        }}
                        {...getFloatingProps()}
                        className={styles.tooltip}
                    >
                        <QuiFlexBoxColumn alignItems="center">
                            <QuiBox weight="medium" text="mono-text-sm">
                                {total.toLocaleString('en-US')}
                                {total === 1 ? ` word` : ' words'}
                            </QuiBox>
                            <div>{dayjs(date).format('MMMM D, YYYY')}</div>
                        </QuiFlexBoxColumn>
                        <FloatingArrow
                            height={ARROW_HEIGHT}
                            width={16}
                            tipRadius={2}
                            className={styles.tooltipArrow}
                            ref={arrowRef}
                            context={context}
                            strokeWidth={1}
                            stroke="#e3e4ec"
                            fill="#fff"
                        />
                    </div>
                )}
            </FloatingPortal>
        </>
    );
}

export default function BarChart() {
    const barChartData = useAtomValue(barChartDataAtom);
    const history = useAtomValue(usageHistoryAtom) ?? { days: [], total: 0 };
    const max = barChartData.reduce((acc, day) => (day.total > acc ? day.total : acc), 0);

    if (!history) return <QuiBox>Loading...</QuiBox>;

    return (
        <div className={styles.wrapper}>
            <QuiFlexBoxColumn justifyContent="space-between" textAlign="right" text="mono-text-xs" color="text-secondary">
                <div>{max.toLocaleString('en-US')}</div>
                <div>{0}</div>
            </QuiFlexBoxColumn>
            <div className={styles.chart}>
                {barChartData.map((day) => {
                    return <BarChartDay key={day.date.valueOf()} {...day} max={max} />;
                })}
            </div>
        </div>
    );
}
