import { Document, Thumbnail } from 'react-pdf';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { dprAtom } from '../../stores/ui';
import { QuiBox, QuiIcon, QuiSpinner } from '@tonicai/ui-quinine';
import { fetchFileBlob, useFileBlob } from '../../stores/fileBlobs';
import styles from './PdfTile.module.scss';
import { noop } from '../../utils';
import { TileButton } from './TileButton';
import classNames from 'classnames';
import { DatasetFile } from '../../types';

const MAX_DIMENSION_FALLBACK = 100;
const PREVIEW_PADDING = 10;

type Dimensions = { width: number } | { height: number } | Record<string, never>;

type PdfTileProps = Readonly<{
    datasetId: string;
    fileId: string;
    fileName: string;
    file: DatasetFile;
}>;

export function PdfTile({ datasetId, fileId, fileName, file }: PdfTileProps) {
    const fileBlob = useFileBlob(fileId);
    const [rendered, setRendered] = useState(false);
    const dpr = useAtomValue(dprAtom);
    const tileContentRef = useRef<HTMLDivElement>(null);

    const [dimensions, setDimensions] = useState<Dimensions>({});

    useEffect(() => {
        const abortController = new AbortController();

        fetchFileBlob(datasetId, fileId, abortController.signal);

        return () => abortController.abort();
    }, [datasetId, fileId]);

    const handleRenderSuccess = useCallback((p: { width: number; height: number }) => {
        const content = tileContentRef?.current;

        if (!content) {
            console.error('Tile content ref is not available');
            return;
        }

        const tileClientRect = content.getBoundingClientRect();
        const containerAspectRatio = tileClientRect.width / tileClientRect.height;
        const documentAspectRatio = p.width / p.height;

        if (documentAspectRatio >= containerAspectRatio) {
            setDimensions({
                width: (typeof tileClientRect?.width === 'number' ? tileClientRect.width : MAX_DIMENSION_FALLBACK) - 2 * PREVIEW_PADDING,
            });
        } else {
            setDimensions({
                height: (typeof tileClientRect?.height === 'number' ? tileClientRect.height : MAX_DIMENSION_FALLBACK) - 2 * PREVIEW_PADDING,
            });
        }

        setRendered(true);
    }, []);

    const className = classNames(styles.tileButton, {
        [styles.loading]: !rendered,
        [styles.loaded]: rendered,
    });

    useEffect(() => {
        const content = tileContentRef.current;

        if (!content) return;

        const visibilityObserver = new IntersectionObserver((entries) => {
            const isIntersecting = entries.some((entry) => entry.isIntersecting);
            if (isIntersecting) {
                fetchFileBlob(datasetId, fileId);
            }
        }, {});

        visibilityObserver.observe(content);

        return () => {
            visibilityObserver.disconnect();
        };
    }, [datasetId, fileId]);

    return (
        <TileButton file={file} datasetId={datasetId} className={className} fileId={fileId} fileName={fileName} ref={tileContentRef}>
            {fileBlob.fetchStatus === 'success' ? (
                <Document className={styles.document} file={fileBlob.blob} onItemClick={noop}>
                    <Thumbnail {...dimensions} pageNumber={1} devicePixelRatio={dpr} onRenderSuccess={handleRenderSuccess} />
                </Document>
            ) : null}
            {!rendered ? (
                <QuiBox>
                    <QuiSpinner size="xs" />
                    <div>
                        <QuiIcon icon="file" size="2xl" />
                    </div>
                    <QuiBox text="mono-text-xs" weight="bold">
                        {'.pdf'}
                    </QuiBox>
                </QuiBox>
            ) : null}
        </TileButton>
    );
}
