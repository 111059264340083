import { Link } from 'react-router-dom';
import styles from './PlainLink.module.scss';
import { ReactNode } from 'react';

type PlainLinkProps = Readonly<{
    children: ReactNode;
    to: string;
    ['data-test']?: string;
}>;

/**
 * A react-router-dom Link component that inherits the color of the parent & only shows an underline on hover.
 */
export function PlainLink({ to, children, ...props }: PlainLinkProps) {
    return (
        <Link to={to} className={styles.plainLink} {...props}>
            {children}
        </Link>
    );
}
