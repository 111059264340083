import { ParseJobConfigResponse } from '../../types';
import { createContext } from 'react';

export type PipelineContextValue = {
    parseJobConfig: ParseJobConfigResponse;
    parseJobConfigId: string;
    refetchParseJobConfig: () => Promise<unknown>;
    canRunPipelineJob: boolean;
};

export const PipelineContext = createContext<PipelineContextValue>({} as PipelineContextValue);
