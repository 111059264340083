import { z } from 'zod';

export const hightlightCodeRequestMessageSchema = z
    .object({
        id: z.string(),
        language: z.string(),
        code: z.string(),
    })
    .strict();

export type HightlightCodeRequestMessage = z.infer<typeof hightlightCodeRequestMessageSchema>;

export const hightlightCodeResponseMessageSchema = z.object({
    id: z.string(),
    html: z.string(),
});

export type HightlightCodeResponseMessage = z.infer<typeof hightlightCodeResponseMessageSchema>;
