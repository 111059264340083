import { UploadFilesToExternalSourceModal } from '../../pages/Dataset/UploadFilesToExternalSourceModal';
import { client } from '../../services/HTTPClient';
import { DatasetFile, FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW, JobStatus } from '../../types';
import { QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems, QuiModalDialog, useQuiModal } from '@tonicai/ui-quinine';
import { deleteDatasetFile, getDownloadFileDataUrl, setPreviouslyProcessedFilesAtom, setProcessingFilesAtom } from '../../stores/datasets';
import { PdfFileFormDialog } from '../FilePreviewDialog/PdfFileFormDialog';
import { retryJob } from '../../stores/jobs';
import { instrumentation } from '../../instrumentation/instrumentation';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';
import { useCallback } from 'react';
import { useFilePreviewContext } from '../FilePreviews/useFilePreviewContext';
import styles from '../Templates/TemplateDialog.module.scss';

type FileAdderFileItemMenuProps = Readonly<{
    file: DatasetFile;
    datasetId: string;
}>;

export function FileAdderFileItemMenu({ file, datasetId }: FileAdderFileItemMenuProps) {
    const deleteDialog = useQuiModal();
    const pdfRedactionDialog = useQuiModal();
    const externalUploadDialog = useQuiModal();
    const { openFilePreview } = useFilePreviewContext();

    const url = getDownloadFileDataUrl(file.fileId, file.datasetId);

    const handleFileDownload = useCallback(() => {
        if (file.fileSource === 'Local') {
            window.open(url);
        } else {
            externalUploadDialog.open();
        }
    }, [externalUploadDialog, url, file.fileSource]);

    return (
        <>
            {/* Menu */}
            <QuiMenu enableFlip>
                <QuiMenuButton data-test={'file-adder-item-menu'} size="sm" iconLeft="more-horizontal" />
                <QuiMenuItems>
                    {/* Download */}
                    {file.processingStatus === JobStatus.COMPLETED && (
                        <QuiMenuItem iconLeft={file.fileSource == 'Local' ? 'download' : 'upload-cloud'} onClick={handleFileDownload}>
                            {file.fileSource == 'Local' ? 'Download File' : `Upload to ${file.fileSource}`}
                        </QuiMenuItem>
                    )}

                    {/* Preview */}
                    {file.processingStatus === JobStatus.COMPLETED && (
                        <QuiMenuItem
                            data-test={'file-adder-item-menu-preview'}
                            onClick={() => {
                                openFilePreview(file.fileId);
                            }}
                            disabled={FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW.includes(file.fileType)}
                            tooltip={
                                FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW.includes(file.fileType) ? 'Preview not available for this file type' : undefined
                            }
                            tooltipProps={{
                                disabled: !FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW.includes(file.fileType),
                            }}
                            iconLeft="search"
                        >
                            Preview
                        </QuiMenuItem>
                    )}

                    {/* Retry scanning job */}
                    {(file.processingStatus === JobStatus.FAILED || file.processingStatus === JobStatus.CANCELED) && (
                        <QuiMenuItem
                            iconLeft="refresh-cw"
                            onClick={() => {
                                retryJob(file.fileId, datasetId);
                            }}
                        >
                            Retry
                        </QuiMenuItem>
                    )}
                    {/* PDF Redaction */}
                    {file.fileType === 'Pdf' && file.processingStatus === JobStatus.COMPLETED && (
                        <QuiMenuItem iconLeft="edit" onClick={pdfRedactionDialog.open}>
                            Edit Redactions
                        </QuiMenuItem>
                    )}
                    {/* Delete File */}
                    <QuiMenuItem data-test={'file-adder-item-menu-delete'} onClick={deleteDialog.open} iconLeft="trash-2">
                        Delete File
                    </QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>

            {/* Dialogs */}

            {file.fileType == 'Pdf' && pdfRedactionDialog.isOpen && (
                <QuiModalDialog closeButtonPosition={'outside'} isOpen onClose={pdfRedactionDialog.close} className={styles.templateDialog}>
                    <PdfFileFormDialog fileId={file.fileId} datasetId={file.datasetId} fileType={file.fileType} />
                </QuiModalDialog>
            )}
            {file.fileSource !== 'Local' && externalUploadDialog.isOpen && (
                <UploadFilesToExternalSourceModal
                    onClick={async () => client.get(url)}
                    fileSource={file.fileSource}
                    specificFile={file}
                    isOpen
                    onClose={externalUploadDialog.close}
                />
            )}

            {deleteDialog.isOpen && (
                <DeleteDialog
                    title="Delete File Confirmation"
                    isOpen
                    closeDialog={deleteDialog.close}
                    deleteMethod={() =>
                        deleteDatasetFile(file.datasetId, file.fileId).finally(() => {
                            instrumentation.deleteFile(file.fileId);
                            deleteDialog.close();
                            setProcessingFilesAtom((current) => current.filter((f) => f.name !== file.fileName));
                            setPreviouslyProcessedFilesAtom((current) => current.filter((f) => f.name !== file.fileName));
                        })
                    }
                    itemType={'file'}
                    itemName={file.fileName}
                />
            )}
        </>
    );
}
