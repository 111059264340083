import { useCallback, useMemo } from 'react';
import { useDatasets } from '../../stores/datasets';
import { QuiSelect } from '@tonicai/ui-quinine';
import { SingleValue } from 'react-select';

type FileOption = {
    label: string;
    value: string;
};

type FileSelectProps = Readonly<{
    value?: string;
    onChange: (value?: string) => void;
    datasetId?: string;
}>;

export function TemplateFileSelect({ value, onChange, datasetId }: FileSelectProps) {
    const datasets = useDatasets();

    const datasetFiles = useMemo(() => {
        const dataset = datasets.find((dataset) => dataset.id === datasetId);
        return dataset?.files ?? [];
    }, [datasets, datasetId]);

    const fileOptions: Readonly<FileOption[]> = useMemo(() => {
        return datasetFiles
            .filter((f) => f.fileType == 'Pdf')
            .map((file) => ({
                label: file.fileName,
                value: file.fileId,
            }));
    }, [datasetFiles]);

    const handleOnChange = useCallback(
        (v: SingleValue<FileOption>) => {
            onChange(v?.value);
        },
        [onChange]
    );

    const selectedValue = useMemo(() => {
        return fileOptions.find((option) => option.value === value);
    }, [value, fileOptions]);

    return (
        <QuiSelect<FileOption, false>
            isClearable
            disabled={datasetId === undefined}
            value={selectedValue}
            onChange={handleOnChange}
            options={fileOptions}
        />
    );
}
