import { StripeInvoiceStatus as StripeInvoiceStatusType } from '@/types';
import { QuiAnyBackgroundColor, QuiAnyForegroundColor, QuiBox } from '@tonicai/ui-quinine';
import { toTitleCase } from './utils';

type Props = { status: StripeInvoiceStatusType };
export default function StripeInvoiceStatus({ status }: Props) {
    const redBG = 'danger-100';
    const redFG = 'danger-800';
    const grayBG = 'white-600';
    const grayFG = 'text-primary';
    const backgroundColor: Record<string, QuiAnyBackgroundColor> = {
        paid: 'success-100',
        pending: grayBG,
        open: grayBG,
        draft: grayBG,
        uncollectible: redBG,
        void: redBG,
        deleted: redBG,
    };
    const textColor: Record<string, QuiAnyForegroundColor> = {
        paid: 'success-800',
        pending: grayFG,
        open: grayFG,
        draft: grayFG,
        uncollectible: redFG,
        void: redFG,
        deleted: redFG,
    };
    return (
        <QuiBox
            bg={backgroundColor[status]}
            color={textColor[status]}
            textAlign="center"
            borderRadius="md"
            padding="xs md"
            text="text-xs"
            style={{ width: 'min-content' }}
        >
            {toTitleCase(status)}
        </QuiBox>
    );
}
