import { QuiBox, QuiButton, QuiLink } from '@tonicai/ui-quinine';
import { useIsHostedProd } from '../../stores/settings';

export function StartChatButton() {
    const isHostedProd = useIsHostedProd();

    if (isHostedProd && window?.Intercom) {
        return (
            <QuiBox text="text-sm">
                <QuiButton
                    iconLeft="message-square"
                    size="sm"
                    onClick={() => {
                        window?.Intercom?.('showNewMessage');
                    }}
                    className={'start-chat-btn'}
                    variant="minimal"
                >
                    Start a chat
                </QuiButton>
                {` or reach out at `}
                <QuiLink variant="currentcolor" external to="mailto:support@tonic.ai">
                    support@tonic.ai
                </QuiLink>
            </QuiBox>
        );
    }

    return (
        <QuiBox display="inline" text="text-sm">
            {`or reach out at `}
            <QuiLink variant="currentcolor" external to="mailto:support@tonic.ai">
                support@tonic.ai
            </QuiLink>
        </QuiBox>
    );
}
