import { useCallback, useEffect, useRef, useState } from 'react';
import { isAxiosError, isCancel } from 'axios';
import { FetchStatus } from '../types';
import { client } from '../services/HTTPClient';

export function useEndpoint<T>(endpoint: string) {
    const [data, setData] = useState<T | null>(null);
    const [fetchStatus, setFetchStatus] =
        useState<Extract<FetchStatus, 'init' | 'loading' | 'error' | 'success' | 'refreshing' | 'not-found'>>('init');
    const abortControllerRef = useRef<AbortController | null>(null);

    const fetchData = useCallback(() => {
        // Cancel existing request (if exists)
        abortControllerRef.current?.abort();

        abortControllerRef.current = new AbortController();

        setFetchStatus((previousStatus) => {
            if (previousStatus === 'refreshing' || previousStatus === 'loading') {
                return previousStatus;
            }

            if (previousStatus === 'success') {
                return 'refreshing';
            }

            return 'loading';
        });

        return client
            .get<T>(endpoint, { signal: abortControllerRef.current.signal })
            .then(({ data }) => {
                setData(data);
                setFetchStatus('success');
            })
            .catch((e) => {
                if (isAxiosError(e) && e.status === 404) {
                    setFetchStatus('not-found');
                } else if (!isCancel(e)) {
                    setFetchStatus('error');
                }
            });
    }, [endpoint]);

    // Fetch data on mount
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return {
        data,
        fetchStatus,
        fetchData,
    };
}
