import { Dataset } from '../../types';
import { PdfTile } from './PdfTile';
import styles from './FilesGrid.module.scss';
import { TileButton } from './TileButton';

type FilesGridProps = Readonly<{
    dataset: Dataset;
}>;

export function FilesGrid({ dataset }: FilesGridProps) {
    return (
        <div className={styles.filesGrid}>
            {dataset.files.map((file) => {
                if (file.fileType === 'Pdf') {
                    return <PdfTile file={file} key={file.fileId} fileName={file.fileName} fileId={file.fileId} datasetId={dataset.id} />;
                } else {
                    return <TileButton file={file} datasetId={dataset.id} key={file.fileId} fileId={file.fileId} fileName={file.fileName} />;
                }
            })}
        </div>
    );
}
