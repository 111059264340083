// src/components/ToastListener.tsx
import React from 'react';
import { useAtom } from 'jotai';
import { useQuiToasts } from '@tonicai/ui-quinine';
import { httpClientToastAtom, HttpClientToastMessage } from '@/stores/httpToast';

export function HttpToastListener() {
    const [toasts, setToasts] = useAtom(httpClientToastAtom);
    const addToast = useQuiToasts();

    React.useEffect(() => {
        if (toasts.length > 0) {
            // Display each toast
            toasts.forEach((toast: HttpClientToastMessage) => {
                addToast({
                    title: toast.title,
                    variant: toast.variant,
                    action: toast.action,
                });
            });

            setToasts([]);
        }
    }, [toasts, addToast, setToasts]);

    return null;
}
