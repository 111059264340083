import { QuiModalDialog, QuiForm, QuiModalContent, QuiBox, QuiTextField, QuiSubmitButton, useQuiToasts } from '@tonicai/ui-quinine';
import { updateDataset } from '../../stores/datasets';
import axios from 'axios';
import { requiredString } from '../../validation';
import { Dataset } from '../../types';

export type EditDatasetNameModalProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    dataset: Dataset;
}>;

export function EditDatasetNameModal({ isOpen, onClose, dataset }: EditDatasetNameModalProps) {
    const addToast = useQuiToasts();

    return (
        <QuiModalDialog title="Create a new dataset" className={'modal'} disableDismissOnEscapeKeyDown={false} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" gap="md" padding="lg" flexDirection="column">
                    <QuiBox>A dataset is a set of files to redact.</QuiBox>
                    <QuiForm<{ name: string }>
                        initialValues={{ name: dataset.name }}
                        onSubmit={({ name }) => {
                            return updateDataset({
                                ...dataset,
                                name,
                            })
                                .catch((e) => {
                                    if (axios.isAxiosError(e) && e.response?.status === 409) {
                                        addToast({
                                            title: "There's already a dataset with that name. Please choose another name.",
                                            variant: 'destructive',
                                        });
                                    } else {
                                        addToast({
                                            title: 'Something unexpected went wrong. Please try again.',
                                            variant: 'destructive',
                                        });
                                    }
                                })
                                .finally(() => {
                                    onClose();
                                });
                        }}
                    >
                        <QuiBox display="flex" gap="md" flexDirection="column">
                            <QuiTextField
                                placeholder="Enter the name for the dataset..."
                                validate={requiredString}
                                name="name"
                                label="Dataset Name"
                            />

                            <div>
                                <QuiSubmitButton variant="brand-purple">Save Dataset</QuiSubmitButton>
                            </div>
                        </QuiBox>
                    </QuiForm>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
