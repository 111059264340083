import { CloudSubscriptionStatus } from '@/types';
import { QuiBox } from '@tonicai/ui-quinine';

type SubscriptionBadgeProps = Readonly<{
    status: CloudSubscriptionStatus;
}>;

export function SubscriptionBadge({ status }: SubscriptionBadgeProps) {
    if (status === CloudSubscriptionStatus.Active) {
        return (
            <QuiBox weight="medium" text="text-lg">
                Pay As You Go
            </QuiBox>
        );
    }

    if (status === CloudSubscriptionStatus.None) {
        return (
            <QuiBox weight="medium" text="text-lg">
                Free Trial
            </QuiBox>
        );
    }

    if (status === CloudSubscriptionStatus.Expired) {
        return (
            <QuiBox weight="medium" text="text-lg">
                Expired
            </QuiBox>
        );
    }

    return null;
}
