import { SubmitError } from '@/components/Auth/SubmitError';
import { QuiFlexH, QuiFlexV, QuiLink, QuiSubmitButton, QuiText, QuiTextField, required } from '@tonicai/ui-quinine';
import { useFormState } from 'react-final-form';

export function ForgotPasswordFormBody() {
    const { submitting, submitSucceeded, dirtySinceLastSubmit } = useFormState();

    if (submitSucceeded) {
        return (
            <QuiFlexV gap="lg">
                <QuiText size={'text-sm'} weight={'normal'}>
                    If your account exists, you will receive an email with instructions to reset your password.
                </QuiText>

                <QuiLink size="text-sm" to="/login" variant="currentcolor" data-test="signin-link">
                    Back to sign In
                </QuiLink>
            </QuiFlexV>
        );
    }

    return (
        <QuiFlexV gap="lg">
            <QuiTextField name="emailAddress" label="Email address" disabled={submitting} data-test="email-input" validate={required} />

            <QuiSubmitButton size="lg" variant="primary" data-test="reset-password-button">
                Send Reset Password Link
            </QuiSubmitButton>
            {!dirtySinceLastSubmit && <SubmitError />}
            <QuiFlexH justify="center" gap="xs">
                <QuiLink size="text-sm" to="/login" variant="currentcolor" data-test="signin-link">
                    Back to sign In
                </QuiLink>
            </QuiFlexH>
        </QuiFlexV>
    );
}
