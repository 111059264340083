import { useCallback, useEffect, useRef } from 'react';

/**
 * Detect whether or not the consuming component is still mounted
 */
export function useIsMounted() {
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return useCallback(() => {
        return isMountedRef.current;
    }, []);
}
