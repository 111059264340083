/* eslint-disable react/no-array-index-key */

// We're disabling this rule because the props values are stable (e.g. not
// changing based on user actions).

import { BaseDocument, Content, OcrTable, OcrTableCell, PdfTableCellType, TableContent } from '@/types';
import { QuiBox, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import styles from './TablesTab.module.scss';
import { RenderContent } from './RenderContent';

function getTableContent(table: TableContent | OcrTable) {
    try {
        const tableHeaders: string[][] = [];
        const tableRows: Content[][] = [];

        if (isTableContent(table)) {
            tableHeaders.push(table.header ?? []);

            (table.data ?? []).forEach((row) => {
                const rowCells: Content[] = [];

                row.forEach((cell) => {
                    rowCells.push(cell ?? { text: '', entities: [] });
                });

                tableRows.push(rowCells);
            });
        } else {
            const headerRows: (OcrTableCell | null)[][] = [];
            const bodyRows: (OcrTableCell | null)[][] = [];
            let inHeader = true;
            for (const row of table) {
                if (inHeader && row.every((cell) => cell?.type === PdfTableCellType.ColumnHeader)) {
                    headerRows.push(row);
                } else {
                    inHeader = false;
                    bodyRows.push(row);
                }
            }

            headerRows.forEach((row) => {
                tableHeaders.push(
                    row.map((cell) => {
                        return cell?.content.text ?? '';
                    })
                );
            });

            bodyRows.forEach((row) => {
                tableRows.push(
                    row.map((cell) => {
                        return cell?.content ?? { text: '', entities: [] };
                    })
                );
            });
        }

        return { tableHeaders, tableRows };
    } catch (error) {
        console.error(error);
        return { tableHeaders: [], tableRows: [] };
    }
}

function isTableContent(table: TableContent | OcrTable): table is TableContent {
    return 'tableName' in table;
}

type TablesTabProps = Readonly<{
    deidentifyResponse: Partial<BaseDocument>;
}>;

export function TablesTab({ deidentifyResponse }: TablesTabProps) {
    const tables = deidentifyResponse?.tables ?? [];

    if (!tables || tables.length === 0) {
        return (
            <QuiBox text="text-sm" color="text-secondary">
                No tables found.
            </QuiBox>
        );
    }

    return (
        <QuiFlexBoxColumn gap="md">
            {tables.map((table, tableIndex) => {
                const { tableHeaders, tableRows } = getTableContent(table);

                if (tableHeaders.length === 0 && tableRows.length === 0) {
                    // Nothing to display
                    return null;
                }

                return (
                    <div key={tableIndex}>
                        <QuiBox border="border-default" display="inline-block">
                            <table className={styles.table}>
                                {tableHeaders.length > 0 ? (
                                    <thead>
                                        {tableHeaders.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <th key={cellIndex}>{cell}</th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                ) : null}
                                {tableRows.length > 0 ? (
                                    <tbody>
                                        {tableRows.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {row.map((cell, cellIndex) => {
                                                    return <td key={cellIndex}>{<RenderContent content={cell} />}</td>;
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : null}
                            </table>
                        </QuiBox>
                    </div>
                );
            })}
        </QuiFlexBoxColumn>
    );
}
