import { setDPR } from '../stores/ui';
import { useEffect, useRef } from 'react';

/**
 * NOTE: This should only be used once across the entire app inside the
 * <App /> component. It's not necessary to have multiple instances of this,
 * since the global store will be updated with the same value across the app,
 *
 * Monitor the user's device pixel ratio (DPR) and update the global store. This
 * will allow us to detect when a user changes their DPR (e.g. by zooming in or
 * by dragging a window to a different monitor with a different resolution). It
 * will update the global store with the current DPR and listen for changes.
 *
 * To accomplish this, we kick off a loop that listens for changes to a
 * MediaQueryList that's created every time the DPR changes. Everytime
 * the DPR changes, we have to create a new MediaQueryList via the
 * `window.matchMedia` method. Its not as simple as setting up a single
 * listener, because the `change` event that we listen to is only valid
 * for a single MediaQueryList. So we have to create a new one every time.
 * It's not a problem though, since this event doesn't fire that many times.
 */
export function useDPRMonitor() {
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        function updatePixelRatio() {
            setDPR(window.devicePixelRatio);

            const media = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);

            // Cancel previous listener (if any) and create new AbortController
            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            media.addEventListener('change', updatePixelRatio, { signal: abortControllerRef.current?.signal });
        }

        // Begin the loop after the initial render
        updatePixelRatio();
    }, []);

    // Remove event listeners
    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);
}
