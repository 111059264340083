import isEmail from 'validator/es/lib/isEmail';

export function requiredEmail(v: unknown) {
    if (typeof v === 'string' && isEmail(v)) return undefined;

    return 'Invalid email address';
}

export function requiredString(v: unknown) {
    if (typeof v === 'string' && v.trim().length > 0) return undefined;

    return 'Required';
}

// matches the identity options in the Startup.cs file
// Uses Microsoft defaults except for length
// Any changes must be reflected in the Startup.cs file
export function requiredPassword(v: unknown) {
    if (typeof v !== 'string' || v.trim().length === 0) return 'Required';

    const password = v;

    if (password.length < 12) return 'Password must be at least 12 characters long';
    if (!password.match(/\d+/)) return 'Password must contain at least 1 digit';
    if (!password.match(/[a-z]/)) return 'Password must contain at least 1 lowercase character';
    if (!password.match(/[A-Z]/)) return 'Password must contain at least 1 uppercase character';
    if (!password.match(/[^a-zA-Z0-9:]/)) return 'Password must contain at least 1 non-alphanumeric character';

    return undefined;
}

export function modelNameValidator(v: unknown) {
    if (typeof v !== 'string' || v.trim().length === 0) return 'Required';
    if (v.length > 128) return 'Name must be less than 128 characters';
    return undefined;
}

export function formatAndParseModelName(v: unknown) {
    if (typeof v !== 'string') return '';
    return v.substring(0, 128);
}
