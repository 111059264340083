import { useRef, useState } from 'react';
import { isCancel } from 'axios';
import { QuiBox, QuiButton, QuiButtonExternalLink, QuiFlexBoxColumn, useQuiToasts } from '@tonicai/ui-quinine';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';
import { client } from '../../services/HTTPClient';
import { ParseJobConfigResponse } from '../../types';
import { Navigate } from 'react-router-dom';
import { fetchParseJobs } from '../../stores/parse-jobs';

type HeaderCardProps = Readonly<{
    openCreatePipelineDialog: () => void;
}>;

export function HeaderCard({ openCreatePipelineDialog }: HeaderCardProps) {
    const abortControllerRef = useRef<AbortController | null>(null);
    const [creatingDemoPipeline, setCreatingDemoPipeline] = useState(false);
    const [newSamplePipeline, setNewSamplePipeline] = useState<ParseJobConfigResponse | null>(null);
    const addToast = useQuiToasts();

    if (newSamplePipeline) {
        return <Navigate to={`/pipelines/${newSamplePipeline.id}`} />;
    }

    return (
        <QuiFlexBoxColumn bg="background-primary" gap="lg" padding="lg" border="border-default" borderRadius="md">
            <QuiBox text="text-md" weight="medium">
                Prepare text for RAG systems and LLMs
            </QuiBox>
            <QuiBox text="text-sm" color="text-secondary">
                To get started, create a pipeline to process files. You either select files from cloud storage, or upload the files directly.
            </QuiBox>
            <QuiBox display="flex" gap="lg" flexWrap="wrap" maxWidth="100percent">
                <QuiButton variant="brand-purple" size="lg" type="button" onClick={openCreatePipelineDialog}>
                    Create a Pipeline
                </QuiButton>
                <QuiButton
                    disabled={creatingDemoPipeline}
                    variant="default"
                    size="lg"
                    type="button"
                    onClick={() => {
                        setCreatingDemoPipeline(true);
                        abortControllerRef.current?.abort();
                        abortControllerRef.current = new AbortController();
                        client
                            .post<ParseJobConfigResponse>('/api/parsejobconfig/create-sample-pipeline', {
                                signal: abortControllerRef.current?.signal,
                            })
                            .then(async ({ data }) => {
                                setNewSamplePipeline(data);

                                // Update global state
                                fetchParseJobs();

                                addToast({
                                    title: 'Demo pipeline created',
                                    variant: 'positive',
                                });
                            })
                            .catch((e) => {
                                if (!isCancel(e)) {
                                    setCreatingDemoPipeline(false);
                                    addToast({
                                        title: 'Unable to create demo pipeline',
                                        variant: 'destructive',
                                    });
                                }
                            });
                    }}
                >
                    Try Demo Pipeline
                </QuiButton>
                <QuiButtonExternalLink variant="minimal" size="lg" href={TEXTUAL_DOCUMENTATION_URL} iconRight="arrow-up-right">
                    Getting Started Guide
                </QuiButtonExternalLink>
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
