import { ReactNode, useEffect, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { fileUploadPipelineAPI } from './fileUploadPipelineAPI';
import { FileUploadPipelineContext } from './FileUploadPipelineContext';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

type FileUploadPipelineProviderProps = Readonly<{
    children: ReactNode;
}>;

export function FileUploadPipelineProvider({ children }: FileUploadPipelineProviderProps) {
    const {
        parseJobConfigId,
        parseJobConfig: { useInternalBucket },
    } = usePipelineContext();
    const api = useMemo(() => fileUploadPipelineAPI(parseJobConfigId), [parseJobConfigId]);
    const fileUploads = useAtomValue(api.fileUploadsAtom, api.storeOptions);
    const numberOfActiveUploads = useAtomValue(api.numberOfActiveUploadsAtom, api.storeOptions);
    const allFileUploadsComplete = useAtomValue(api.allFileUploadsCompleteAtom, api.storeOptions);
    const hasUnprocessedFiles = useAtomValue(api.hasUnprocessedFilesAtom, api.storeOptions);

    // Fetch all files that have already been uploaded to the server
    useEffect(() => {
        if (!useInternalBucket) return;

        api.fetchUploadedFiles();
    }, [api, useInternalBucket]);

    // Once all uploads are resolved, fetch the server-side files
    useEffect(() => {
        if (fileUploads.length === 0 || numberOfActiveUploads !== 0 || !allFileUploadsComplete) return;

        api.fetchUploadedFiles();

        const timeout = window.setTimeout(() => {
            api.fetchUploadedFiles();
        }, 3000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [numberOfActiveUploads, fileUploads, allFileUploadsComplete, api]);

    // While there are active uploads or files that are being processed/queued, poll the server for new files
    useEffect(() => {
        if ((fileUploads.length === 0 || numberOfActiveUploads === 0) && !hasUnprocessedFiles) return;

        const interval = window.setInterval(() => {
            api.fetchUploadedFiles();
        }, 3000);

        return () => {
            window.clearInterval(interval);
        };
    }, [fileUploads, numberOfActiveUploads, api, hasUnprocessedFiles]);

    // Cancel all in-progress uploads when the component is unmounted
    // We should probably prevent the user from navigating away from the page
    // while uploads are active, but this will work for now.
    useEffect(() => {
        return () => {
            api.cleanup();
        };
    }, [api]);

    return <FileUploadPipelineContext.Provider value={api}>{children}</FileUploadPipelineContext.Provider>;
}
