import { ReactNode } from 'react';
import { FilePreviewContext } from './FilePreviewContext';
import { useFilePreviews } from './useFilePreviews';

type DatasetProviderProps = Readonly<{
    datasetId: string;
    children: ReactNode;
}>;

export function FilePreviewProvider({ datasetId, children }: DatasetProviderProps) {
    const value = useFilePreviews(datasetId);

    return <FilePreviewContext.Provider value={value}>{children}</FilePreviewContext.Provider>;
}
