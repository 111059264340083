import { client } from '../../services/HTTPClient';
import { getDownloadAllDatasetFilesUrl } from '../../stores/datasets';
import { QuiButton, useQuiToasts, useQuiModal } from '@tonicai/ui-quinine';
import { FileSource } from '../../types';
import { UploadFilesToExternalSourceModal } from './UploadFilesToExternalSourceModal';

type DownloadAllFilesButtonProps = Readonly<{
    datasetId: string;
    fileSource: FileSource;
}>;

export function DownloadAllFilesButton({ datasetId, fileSource }: DownloadAllFilesButtonProps) {
    const addToast = useQuiToasts();

    const uploadToExtenalModal = useQuiModal();

    const url = getDownloadAllDatasetFilesUrl(datasetId);

    return (
        <>
            <QuiButton
                onClick={() => {
                    if (fileSource === 'Local') {
                        addToast({
                            title: 'Download Started',
                            variant: 'positive',
                        });

                        window.open(url);
                    } else {
                        uploadToExtenalModal.open();
                    }
                }}
            >
                {fileSource === 'Local' ? 'Download All Files' : `Upload All Files`}
            </QuiButton>
            <UploadFilesToExternalSourceModal
                fileSource={fileSource}
                onClose={uploadToExtenalModal.close}
                isOpen={uploadToExtenalModal.isOpen}
                onClick={async () => client.get(url)}
            />
        </>
    );
}
