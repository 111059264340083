import { useParams } from 'react-router-dom';
import { FileParseResultApiModel } from '../../types';
import { EndpointGuard } from '../../components/EndpointGuard/EndpointGuard';
import { FileLayout } from './FileLayout';
import { QuiBox } from '@tonicai/ui-quinine';

export function FileParseJobFile() {
    const params = useParams<{ fileParseJobId: string; fileParseResultId: string }>();
    const fileParseJobId = params.fileParseJobId!;
    const fileParseResultId = params.fileParseResultId!;

    return (
        <QuiBox padding="md">
            <EndpointGuard<FileParseResultApiModel>
                endpoint={`/api/parsejob/${fileParseJobId}/files/${fileParseResultId}`}
                errorMessage="Error loading file"
                notFoundMessage="File not found"
                Component={FileLayout}
            />
        </QuiBox>
    );
}
