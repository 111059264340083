import { Document, Page, Thumbnail } from 'react-pdf';
import { EndpointGuard } from '../EndpointGuard/EndpointGuard';
import { getBlob, noop } from '../../utils';
import { useMemo, useRef, useState } from 'react';
import { useScale } from '../Templates/useScale';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';
import { useAtomValue } from 'jotai';
import { dprAtom } from '../../stores/ui';
import styles from './PipelineSynthesizedPdfPreview.module.scss';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
// import { PdfRedaction } from '../../types';

// Uncomment the code below to support highlighting redactions once we support highlighting detected entities in the original files
// We'll also need to add the generator config then too, but only in a "preview generator config on this file" mode

// SCRATCH PAD
//
// https://localhost:3002/api/preview/<datasetId>/files/<fileId>/original
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/pdfredaction/file/62ad8bf5-d7ef-3092-ae31-41bb076e19bd
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/file/62ad8bf5-d7ef-3092-ae31-41bb076e19bd/pdfwords
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/pdfredaction/deidentifyjob/e56e29b9-234d-e9fb-17a4-40ef5f418d2f

// [Route("api/dataset/{datasetId}/file/{fileId}/pdfwords")]
// [Route("api/dataset/{datasetId}/pdfredaction/file")]
// [Route("{datasetId}/files/{fileId}/original")]
// [Route("api/dataset/{datasetId}/pdfredaction/deidentifyjob/{jobId}")]

// string
// const blobUrl = `/api/preview/${datasetId}/files/${file.fileId}/original`;

// FileRedactions
// const fileRedactionsUrl = `/api/dataset/${datasetId}/pdfredaction/file/${file.fileId}`; // { templateId, redactions }

// PdfWord[]
// const pdfWordsUrl = `/api/dataset/${datasetId}/file/${file.fileId}/pdfwords`; // { content: string, pageNumber: number, .... }[]

// PdfRedaction[]
// const pdfRedactionsUrl = `/api/dataset/<datasetId>/pdfredaction/deidentifyjob/<file.mostRecentCompletedJobId>`; // { type: "AutomaticRedaction" | ...., id: string, piiTypeLabel: string }[]

// https://localhost:3002/api/preview/2180af9e-33f0-94c2-7282-116346b11e02/files/62ad8bf5-d7ef-3092-ae31-41bb076e19bd/original
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/pdfredaction/file/62ad8bf5-d7ef-3092-ae31-41bb076e19bd
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/file/62ad8bf5-d7ef-3092-ae31-41bb076e19bd/pdfwords
// https://localhost:3002/api/dataset/2180af9e-33f0-94c2-7282-116346b11e02/pdfredaction/deidentifyjob/e56e29b9-234d-e9fb-17a4-40ef5f418d2f

// [Route("api/dataset/{datasetId}/file/{fileId}/pdfwords")]
// [Route("api/dataset/{datasetId}/pdfredaction/file")]
// [Route("{datasetId}/files/{fileId}/original")]
//
// END SCRATCH PAD

// type Dimensions = {
//     height: number;
//     width: number;
// };
// type PageDimensions = {
//     original: Dimensions;
//     display: Dimensions;
// };

type PipelineSynthesizedPdfPreviewContentProps = Readonly<{
    blobString: string;
    // For when we want to add redactions to the original file preview
    // redactions: PdfRedaction[];
}>;

function PipelineSynthesizedPdfPreviewContent({ blobString }: PipelineSynthesizedPdfPreviewContentProps) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const blob = useMemo(() => getBlob(blobString), [blobString]);
    const scale = useScale();
    const dpr = useAtomValue(dprAtom);
    const [pageIds, setPageIds] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    // const [pageDimensions, setPageDimensions] = useState<PageDimensions | null>(null);
    // const [pageStatus, setPageStatus] = useState<'loading' | 'success' | 'error'>('loading');
    // const [documentStatus, setDocumentStatus] = useState<'loading' | 'success' | 'error'>('loading');

    // const isLoadedSuccessfully = useMemo(() => {
    //     return documentStatus === 'success' && pageStatus === 'success';
    // }, [pageStatus, documentStatus]);

    // const drawAnnotations = useCallback(() => {
    //     // Bail early if the PDF isn't loaded yet
    //     if (!isLoadedSuccessfully || !canvasRef.current || !toggle.isOpen || !pageDimensions) return;

    //     const ctx = canvasRef.current.getContext('2d');

    //     // Bail early if the canvas context isn't available (not likely to happen)
    //     if (!ctx) return;

    //     // Render the redactions
    //     ctx.save();
    //     const currentOperation = ctx.globalCompositeOperation;
    //     ctx.globalCompositeOperation = 'darken';
    //     redactions.forEach((redaction) => {
    //         const originalDimensions: Dimensions = {
    //             width: pageDimensions.original.width,
    //             height: pageDimensions.original.height,
    //         };

    //         const canvasDimensions: Dimensions = {
    //             width: ctx.canvas.width,
    //             height: ctx.canvas.height,
    //         };

    //         redaction.areas
    //             .filter((a) => a.pageNumber - 1 === currentIndex)
    //             .forEach(({ x, y, width, height }) => {
    //                 const relativeWidth = width / originalDimensions.width;
    //                 const relativeHeight = height / originalDimensions.height;
    //                 const relativeX = x / originalDimensions.width;
    //                 const relativeY = y / originalDimensions.height;

    //                 const scaledWidth = relativeWidth * canvasDimensions.width;
    //                 const scaledHeight = relativeHeight * canvasDimensions.height;
    //                 const scaledX = relativeX * canvasDimensions.width;
    //                 const scaledY = relativeY * canvasDimensions.height;

    //                 ctx.save();
    //                 ctx.fillStyle = '#f0ddff';
    //                 ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
    //                 ctx.restore();
    //             });
    //     });
    //     ctx.globalCompositeOperation = currentOperation;
    //     ctx.restore();
    // }, [isLoadedSuccessfully, toggle.isOpen, redactions, pageDimensions, currentIndex]);

    // useEffect(() => {
    //     drawAnnotations();
    // }, [drawAnnotations, pageStatus, pageDimensions, documentStatus, redactions]);

    const wrapperClassName = classNames(styles.wrapper, {
        [styles.withThumbnails]: pageIds.length > 1,
        [styles.withoutThumbnails]: pageIds.length < 2,
    });

    return (
        <div className={styles.wrapper}>
            <Document
                onItemClick={noop}
                file={blob}
                className={styles.document}
                onLoadStart={() => {
                    // setDocumentStatus('loading');
                }}
                onLoadError={() => {
                    // setDocumentStatus('error');
                }}
                onProgress={() => {
                    // setDocumentStatus('loading');
                }}
                onLoadSuccess={(document) => {
                    // setDocumentStatus('success');
                    setPageIds(new Array(document.numPages).fill(null).map(() => nanoid()));
                    setCurrentIndex(0);
                    // drawAnnotations();
                }}
            >
                <div className={wrapperClassName}>
                    {pageIds.length > 1 ? (
                        <div className={styles.thumbnails}>
                            {pageIds.map((id, index) => {
                                const buttonClassName = classNames(styles.thumbnailButton, {
                                    [styles.active]: index === currentIndex,
                                });
                                return (
                                    <button
                                        className={buttonClassName}
                                        key={id}
                                        type="button"
                                        onClick={() => {
                                            setCurrentIndex(index);
                                        }}
                                    >
                                        <Thumbnail width={100} className={styles.thumbnail} pageIndex={index} />
                                    </button>
                                );
                            })}
                        </div>
                    ) : null}
                    <div className={styles.mainWrapper}>
                        <div className={styles.main}>
                            <Page
                                className={styles.pageMain}
                                canvasRef={canvasRef}
                                devicePixelRatio={dpr}
                                scale={scale.scale}
                                // onLoadStart={() => {
                                // setPageStatus('loading');
                                // }}
                                // onLoadSuccess={(page) => {
                                // setPageStatus('success');
                                // setPageDimensions({
                                //     display: { height: page.height, width: page.width },
                                //     original: { height: page.originalHeight, width: page.originalWidth },
                                // });
                                // drawAnnotations();
                                // }}
                                // onLoadError={() => {
                                // setPageStatus('error');
                                // }}
                                // onProgress={() => {
                                // setPageStatus('loading');
                                // }}
                                // onRenderSuccess={() => {
                                // setPageStatus('success');
                                // drawAnnotations();
                                // }}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                pageIndex={currentIndex}
                            />
                        </div>
                        <ZoomButtons className={styles.zoomButtons} {...scale} />
                    </div>
                </div>
            </Document>
        </div>
    );
}

type PipelineSynthesizedPdfPreviewProps = Readonly<{
    fileBlobUrl: string;
    // redactionsUrl: string;
}>;

export function PipelineSynthesizedPdfPreview({ fileBlobUrl }: PipelineSynthesizedPdfPreviewProps) {
    return (
        <EndpointGuard<string>
            endpoint={fileBlobUrl}
            notFoundMessage="File not found"
            errorMessage="Error loading file preview"
            render={({ data: blobString }) => {
                return <PipelineSynthesizedPdfPreviewContent blobString={blobString} />;
            }}
            // render={({ data: blobString }) => {
            //     return (
            //         <EndpointGuard<PdfRedaction[]>
            //             endpoint={redactionsUrl}
            //             notFoundMessage="File not found"
            //             errorMessage="Error loading file preview"
            //             render={({ data: redactions }) => {
            //                 return <PipelineSynthesizedPdfPreviewContent blobString={blobString} redactions={redactions} />;
            //             }}
            //         />
            //     );
            // }}
        />
    );
}
