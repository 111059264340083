import { hasOpenAiKeyAtom } from '../../stores/settings';
import { llmSynthesisActuallyEnabledAtom, toggleLLMSynthesis } from './state';
import { QuiBox, QuiButton, QuiIcon, QuiSwitch, QuiTooltip } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { SampleMenu } from './SampleMenu';
import styles from './styles.module.scss';
import { usePlaygroundContext } from './usePlaygroundContext';

type ControlsProps = Readonly<{
    setEditorContent: (content: string) => void;
    clearEditor: () => void;
    showClearButton?: boolean;
}>;

export function Controls({ setEditorContent, clearEditor }: ControlsProps) {
    const storeOptions = usePlaygroundContext();
    const llmSynthEnabled = useAtomValue(llmSynthesisActuallyEnabledAtom, storeOptions);
    const hasOpenAiKey = useAtomValue(hasOpenAiKeyAtom);
    const tooltipContent = hasOpenAiKey
        ? 'When LLM Synthesis is enabled, instead of using its internal algorithm, Textual uses an LLM to replace the detected entities with realistic values.'
        : 'To enable LLM Synthesis, you need to provide an OpenAI API key on installation.';

    return (
        <div className={styles.app}>
            <div>
                <QuiBox display="flex" alignItems="end" justifyContent="space-between">
                    <SampleMenu setEditorContent={setEditorContent} />
                    <QuiButton size="xs" variant="minimal" iconLeft="x" onClick={clearEditor}>
                        Clear
                    </QuiButton>
                </QuiBox>
            </div>
            <div>
                <QuiBox display="flex" alignItems="center" justifyContent="end" gap="sm">
                    <QuiSwitch
                        checked={llmSynthEnabled}
                        onChange={() => {
                            toggleLLMSynthesis(storeOptions.store);
                        }}
                        label="Enable LLM Synthesis"
                        disabled={!hasOpenAiKey}
                    />
                    <QuiTooltip content={tooltipContent}>
                        <QuiIcon icon="info" size="md" />
                    </QuiTooltip>
                </QuiBox>
            </div>
        </div>
    );
}
