import { Dataset, PiiTypeEnum } from '../../types';
import { QuiBox } from '@tonicai/ui-quinine';
import { RedactionTypeOptions } from './RedactTypeOptions';
import { useEffect, useRef } from 'react';
import { useField, useForm } from 'react-final-form';
import fastDeepEqual from 'fast-deep-equal';

type RedactionTypesProps = Readonly<{
    keys: string[];
    dataset: Dataset;
}>;

export function RedactionTypes({ keys, dataset }: RedactionTypesProps) {
    const form = useForm();

    // Automatically submit the form anytime a generator setup value changes
    const {
        input: { value: generatorSetupValue },
    } = useField(`generatorSetup`);
    const generatorSetupValueRef = useRef(generatorSetupValue);

    const {
        input: { value: datasetGeneratorMetadataValue },
    } = useField('datasetGeneratorMetadata');

    useEffect(() => {
        if (!fastDeepEqual(generatorSetupValueRef.current, generatorSetupValue)) {
            form.submit();
        }

        generatorSetupValueRef.current = generatorSetupValue;
    }, [generatorSetupValue, form, datasetGeneratorMetadataValue]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs">
            {keys.map((key) => (
                <RedactionTypeOptions key={key} piiType={key as PiiTypeEnum} dataset={dataset} />
            ))}
        </QuiBox>
    );
}
