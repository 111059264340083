import { QuiButton, useQuiModal } from '@tonicai/ui-quinine';
import { ApiKeyModal } from '../ApiKeyModal/ApiKeyModal';

export function CreateApiKeyButton() {
    const dialog = useQuiModal();
    return (
        <>
            <QuiButton type="button" onClick={dialog.open} iconLeft="key" size="sm" />
            <ApiKeyModal isOpen={dialog.isOpen} onClose={dialog.close} />
        </>
    );
}
