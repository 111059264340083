import { QuiBox } from '@tonicai/ui-quinine';
import { PageTitle } from '../../components/PageTitle/PageTitle';

export function NotFound() {
    return (
        <QuiBox padding="md">
            <PageTitle icon="alert-triangle" title="Page Not Found" />
        </QuiBox>
    );
}
