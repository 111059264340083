import { QuiBox } from '@tonicai/ui-quinine';
import ReactJson from 'react-json-view';
import { BaseDocument } from '@/types';

type JsonTabProps = Readonly<{
    deidentifyResponse: BaseDocument;
}>;

export function JsonTab({ deidentifyResponse }: JsonTabProps) {
    return (
        <QuiBox bg="background-primary" border="border-default" borderRadius="md">
            <QuiBox borderBottom="border-default" padding="sm" text="mono-text-xs" display="flex" justifyContent="space-between">
                <QuiBox display="flex" gap="sm" alignItems="center">
                    JSON
                </QuiBox>
            </QuiBox>
            <QuiBox bg="background-secondary" padding="md">
                <ReactJson
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={deidentifyResponse}
                    collapsed={1}
                    collapseStringsAfterLength={500}
                />
            </QuiBox>
        </QuiBox>
    );
}
