import { QuiSelect } from '@tonicai/ui-quinine';
import isPlainObject from 'is-plain-obj';
import { useField } from 'react-final-form';
import { SUPPORTED_PARSE_JOB_FILE_EXTENSIONS } from '../../types';

const fileExtensionOptions: readonly { label: string; value: string }[] = SUPPORTED_PARSE_JOB_FILE_EXTENSIONS.map((ext) => ({
    label: ext,
    value: ext,
}));

type FileExtensionsFieldProps = Readonly<{
    name: string;
}>;

export function FileExtensionsField({ name }: FileExtensionsFieldProps) {
    const field = useField<string[], HTMLElement, { label: string; value: string }[]>(name, {
        parse: (value) => {
            if (Array.isArray(value)) {
                return Array.from(new Set(value.filter((v) => isPlainObject(v) && 'value' in v && typeof v.value === 'string').map((v) => v.value)));
            }

            return [];
        },
        format: (value) => {
            const selectedOptions: { label: string; value: string }[] = [];

            if (Array.isArray(value)) {
                for (const v of value) {
                    if (
                        typeof v === 'string' &&
                        SUPPORTED_PARSE_JOB_FILE_EXTENSIONS.includes(v) &&
                        selectedOptions.find((o) => o.value === v) === undefined
                    ) {
                        selectedOptions.push({ label: v, value: v });
                    }
                }
            }

            return selectedOptions;
        },
    });

    return (
        <QuiSelect<{ label: string; value: string }, true>
            value={field.input.value}
            onChange={(newValue) => {
                field.input.onChange(newValue);
            }}
            isMulti
            options={fileExtensionOptions}
        />
    );
}
