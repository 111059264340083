import { createStore, getDefaultStore, useAtomValue } from 'jotai';

export type JotaiStore = ReturnType<typeof createStore>;

export const globalStore = getDefaultStore();

export type UseAtomOptions = Parameters<typeof useAtomValue>[1] & {
    store: JotaiStore;
};

export const globalStoreOptions: UseAtomOptions = { store: globalStore };
