import styles from './PageContainer.module.scss';
import { ReactNode } from 'react';

type PageContainerProps = Readonly<{
    children: ReactNode;
}>;

export function PageContainer({ children }: PageContainerProps) {
    return <div className={styles.pageContainer}>{children}</div>;
}
