import { useEffect, useState } from 'react';
import { fetchUserApiKeys, revokeApiKey, useUserAPIKeys } from '../../stores/userAPIKeys';
import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiPopover, QuiText, useQuiModal } from '@tonicai/ui-quinine';
import { ApiKeyModal } from '../../components/ApiKeyModal/ApiKeyModal';
import { formatDateTime } from '../../utils';
import { useIsMounted } from '../../hooks/useIsMounted';
import { instrumentation } from '../../instrumentation/instrumentation';
import { PageTitle } from '../../components/PageTitle/PageTitle';

export function UserAPIKeys() {
    const [editingId, setEditingId] = useState<string | null>(null);
    const isMounted = useIsMounted();

    const createAPIKeyDialog = useQuiModal();
    const revokeAPIKeyDialog = useQuiModal();

    const { fetchStatus, userAPIKeys } = useUserAPIKeys();

    useEffect(() => {
        fetchUserApiKeys();
    }, []);

    return (
        <QuiFlexBoxColumn gap="lg" padding="md">
            <QuiBox display="flex" justifyContent="space-between" gap="sm">
                <PageTitle icon="key" title="User API Keys" />

                <QuiButton onClick={createAPIKeyDialog.open}>Create API Key</QuiButton>
            </QuiBox>

            <ApiKeyModal isOpen={createAPIKeyDialog.isOpen} onClose={createAPIKeyDialog.close} />

            <QuiBox display="flex" flexDirection="column" gap="sm">
                {fetchStatus === 'success' && userAPIKeys.length === 0 ? (
                    <QuiBox text="text-md" color="text-secondary">
                        You have not created any API keys yet.
                    </QuiBox>
                ) : null}
                {userAPIKeys.map((key) => (
                    <QuiBox display="flex" border="white-600" borderRadius="md" padding="sm" bg="white" gap="md" key={key.id}>
                        <QuiBox flexGrow="1" flexDirection="column" display="flex">
                            <QuiBox text="text-sm" weight="bold">
                                {key.name}
                            </QuiBox>
                            <QuiBox text="mono-text-xs" color="black-600">
                                {key.key}
                            </QuiBox>
                            <QuiBox text="text-xs" color="black-600">
                                {`Created At: ${key.createdDate ? formatDateTime(new Date(key.createdDate)) : '--'}`}
                            </QuiBox>
                            <QuiBox text="text-xs" color="black-600">
                                {`Last Used: ${key.lastUsed ? formatDateTime(new Date(key.lastUsed)) : '--'}`}
                            </QuiBox>
                        </QuiBox>

                        <div>
                            <QuiPopover
                                content={
                                    <QuiBox display="flex" flexDirection="column" gap="sm">
                                        <QuiText size="text-sm">Are you sure you want to revoke this key?</QuiText>
                                        <QuiBox display="flex" gap="sm" justifyContent="end">
                                            <QuiButton
                                                size="sm"
                                                onClick={() => {
                                                    setEditingId(null);
                                                    revokeAPIKeyDialog.close();
                                                }}
                                            >
                                                Cancel
                                            </QuiButton>
                                            <QuiButton
                                                size="sm"
                                                variant="destructive"
                                                onClick={() => {
                                                    revokeApiKey(key.id).then(() => {
                                                        instrumentation.revokeAPIKey(key.id);

                                                        if (isMounted()) {
                                                            setEditingId(null);
                                                            revokeAPIKeyDialog.close();
                                                        }
                                                    });
                                                }}
                                            >
                                                Revoke
                                            </QuiButton>
                                        </QuiBox>
                                    </QuiBox>
                                }
                                isOpen={revokeAPIKeyDialog.isOpen && editingId === key.id}
                                onClose={revokeAPIKeyDialog.close}
                            >
                                <QuiButton
                                    onClick={() => {
                                        setEditingId(key.id);
                                        revokeAPIKeyDialog.open();
                                    }}
                                    size="sm"
                                    variant="destructive"
                                >
                                    Revoke
                                </QuiButton>
                            </QuiPopover>
                        </div>
                    </QuiBox>
                ))}
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
