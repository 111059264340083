import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import React, { ReactElement, ReactNode } from 'react';

type SettingsSectionInfoProps = Readonly<{
    title: ReactNode;
    description?: ReactNode;
    children?: React.ReactNode;
}>;

export function SettingsSectionInfo({ title, description, children }: SettingsSectionInfoProps) {
    return (
        <div className="qui-cell-3-fixed">
            <QuiBox display="flex" gap="md" flexDirection="column">
                <QuiText as="h2" size="display-xs" weight="medium">
                    {title}
                </QuiText>
                {description ? <div>{description}</div> : null}
                {children ? <div>{children}</div> : null}
            </QuiBox>
        </div>
    );
}

type SettingsSectionContentProps = Readonly<{
    children: React.ReactNode;
}>;

export function SettingsSectionContent({ children }: SettingsSectionContentProps) {
    return <div className="qui-cell">{children}</div>;
}

type SettingsSectionProps = Readonly<{
    children: [ReactElement<typeof SettingsSectionInfo>, ReactElement<typeof SettingsSectionInfo>];
}>;

export function SettingsSection({ children }: SettingsSectionProps) {
    return <div className="qui-grid qui-grid-gap-md">{children}</div>;
}
