import { ITestAwsResponse, TestAwsCredentialsButton } from '@/components/TestCredentialsButton/TestAwsCredentialsButton';
import {
    QuiBox,
    QuiButton,
    QuiCell,
    QuiFlexBoxColumn,
    QuiForm,
    QuiGridLayout,
    QuiModalContent,
    QuiModalDialog,
    QuiPasswordField,
    QuiSelectField,
    QuiSubmitButton,
    QuiTextField,
    useQuiModal,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import { FORM_ERROR } from 'final-form';
import { FormSpy, useForm } from 'react-final-form';
import { FormError } from '../../components/FormError/FormError';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';
import { TitleBox } from '../../components/TitleBox/TitleBox';
import { client } from '../../services/HTTPClient';
import { updateParseJob } from '../../stores/parse-jobs';
import { AWS_S3_REGIONS_OPTIONS, AwsCredential, AwsCredentialSchema, FileSourceEnum } from '../../types';
import { requiredString } from '../../validation';

const INITIAL_VALUES: AwsCredential = {
    accessKey: '',
    secretKey: '',
    region: '',
    sessionToken: '',
};

export function AWSCredentials() {
    const { parseJobConfig } = usePipelineContext();
    const updateCredentialsDialog = useQuiModal();

    const addToast = useQuiToasts();

    const form = useForm();

    return (
        <>
            <TitleBox title="AWS Credentials">
                <QuiGridLayout gap="md">
                    <QuiCell width={4}>
                        <QuiTextField disabled label="Access Key" name="parseJobExternalCredential.credential.accessKey" id="aws-access-key" />
                    </QuiCell>
                    <QuiCell width={4}>
                        <QuiPasswordField
                            disabled
                            label="Secret Access Key"
                            name="parseJobExternalCredential.credential.secretKey"
                            id="aws-secret-key"
                        />
                    </QuiCell>
                    <QuiCell width={4}>
                        <QuiSelectField
                            disabled
                            label="Region"
                            name="parseJobExternalCredential.credential.region"
                            id="aws-region"
                            options={AWS_S3_REGIONS_OPTIONS}
                        />
                    </QuiCell>
                    <QuiCell width={4}>
                        <QuiPasswordField
                            disabled
                            label="Session Token"
                            name="parseJobExternalCredential.credential.sessionToken"
                            id="aws-session-token"
                        />
                    </QuiCell>
                </QuiGridLayout>
                <QuiBox display="flex" alignItems="center" gap="md">
                    <QuiButton type="button" onClick={updateCredentialsDialog.open}>
                        Update AWS Credentials
                    </QuiButton>
                </QuiBox>
            </TitleBox>

            <QuiModalDialog title="Update Amazon S3 Credentials" isOpen={updateCredentialsDialog.isOpen} onClose={updateCredentialsDialog.close}>
                <QuiModalContent>
                    <QuiForm<AwsCredential>
                        initialValues={INITIAL_VALUES}
                        validate={(values) => {
                            const awsCredentials = AwsCredentialSchema.safeParse(values);

                            if (awsCredentials.error) {
                                const actualErrors: Partial<Record<keyof AwsCredential, string>> = {};

                                const { fieldErrors } = awsCredentials.error.flatten();

                                Object.entries(fieldErrors).forEach(([field, error]) => {
                                    if (Array.isArray(error) && error.length > 0) {
                                        actualErrors[field as keyof AwsCredential] = error[0];
                                    }
                                });

                                if (Object.keys(actualErrors).length > 0) {
                                    return actualErrors;
                                }
                            }

                            return undefined;
                        }}
                        onSubmit={async (values) => {
                            const cloudConnectionResult = await client.post<ITestAwsResponse>(`/api/cloudfile/test-cloud-connection`, {
                                fileSource: FileSourceEnum.Aws,
                                credential: values,
                            });

                            if (!cloudConnectionResult.data.success) {
                                return {
                                    [FORM_ERROR]: 'Invalid AWS Credentials: ' + cloudConnectionResult.data.errorMessage,
                                };
                            }

                            return await updateParseJob(parseJobConfig.id, {
                                parseJobExternalCredential: {
                                    fileSource: FileSourceEnum.Aws,
                                    credential: values,
                                },
                            })
                                .then(() => {
                                    form.change('parseJobExternalCredential', values);

                                    addToast({
                                        title: 'AWS Credentials updated successfully',
                                        variant: 'positive',
                                    });

                                    updateCredentialsDialog.close();

                                    return undefined;
                                })
                                .catch((error) => {
                                    console.error(error);

                                    if (error.response && error.response.data && error.response.data.errorMessage) {
                                        return { [FORM_ERROR]: error.response.data.errorMessage };
                                    }

                                    return { [FORM_ERROR]: 'Failed to update AWS credentials. Please check your input and try again.' };
                                });
                        }}
                    >
                        <QuiFlexBoxColumn gap="md">
                            <QuiTextField validate={requiredString} label="Access Key" name="accessKey" id="aws-access-key" />

                            <QuiPasswordField validate={requiredString} label="Secret Access Key" name="secretKey" id="aws-access-key" />

                            <QuiSelectField
                                validate={requiredString}
                                label="Region"
                                name="region"
                                id="aws-region"
                                options={AWS_S3_REGIONS_OPTIONS}
                                menuPortalTarget={document.body}
                            />

                            <QuiPasswordField label="Session Token" name="sessionToken" id="aws-session-token" />

                            <TestAwsCredentialsButton
                                awsKeyFieldName="accessKey"
                                awsAccessSecretFieldName="secretKey"
                                awsRegionFieldName="region"
                                awsSessionTokenFieldName="sessionToken"
                            />

                            <FormError />

                            <QuiBox display="flex" justifyContent="end" gap="sm">
                                <QuiButton type="button" onClick={updateCredentialsDialog.close}>
                                    Cancel
                                </QuiButton>
                                <FormSpy subscription={{ validating: true, valid: true }}>
                                    {({ valid, validating }) => (
                                        <QuiSubmitButton disabled={!valid || validating} variant="brand-purple">
                                            Update AWS Credentials
                                        </QuiSubmitButton>
                                    )}
                                </FormSpy>
                            </QuiBox>
                        </QuiFlexBoxColumn>
                    </QuiForm>
                </QuiModalContent>
            </QuiModalDialog>
        </>
    );
}
