import { useEffect, useState } from 'react';
import { fetchPdfRedactionTemplate } from '../stores/pdfRedactionTemplates';
import { FetchStatus, PdfRedactionTemplate } from '../types';
import { isAxiosError, isCancel } from 'axios';

export function useTemplate(datasetId: string, templateId: string | undefined) {
    const [fetchStatus, setFetchStatus] = useState<FetchStatus>('init');
    const [template, setTemplate] = useState<PdfRedactionTemplate | undefined>(undefined);

    useEffect(() => {
        // Wipe current state
        setTemplate(undefined);

        if (!templateId) {
            setFetchStatus('init');
            return;
        }

        setFetchStatus('loading');

        const abortController = new AbortController();

        fetchPdfRedactionTemplate(datasetId, templateId)
            .then((template) => {
                setTemplate(template);
                setFetchStatus('success');
            })
            .catch((error) => {
                if (isAxiosError(error) && isCancel(error)) {
                    // Do nothing
                } else {
                    setFetchStatus('error');
                }
            });

        return () => {
            abortController.abort();
        };
    }, [datasetId, templateId]);

    return {
        fetchStatus,
        template,
    };
}
