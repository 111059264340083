import { DatasetFile } from '../../types';
import { QuiButton, useQuiModal, useQuiToasts } from '@tonicai/ui-quinine';
import { deleteDatasetFile } from '../../stores/datasets';
import { instrumentation } from '../../instrumentation/instrumentation';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';
import { useFilePreviewContext } from './useFilePreviewContext';

type FileDeleteButtonProps = Readonly<{
    file: DatasetFile;
}>;

export function FileDeleteButton({ file }: FileDeleteButtonProps) {
    const deleteDialog = useQuiModal();
    const { openPreview } = useFilePreviewContext();
    const addToast = useQuiToasts();

    return (
        <>
            <QuiButton size="sm" onClick={deleteDialog.open} iconLeft="trash-2">
                Delete File
            </QuiButton>

            {deleteDialog.isOpen && (
                <DeleteDialog
                    title="Delete File Confirmation"
                    isOpen
                    closeDialog={deleteDialog.close}
                    deleteMethod={() =>
                        deleteDatasetFile(file.datasetId, file.fileId)
                            .then(() => {
                                addToast({ title: 'File deleted successfully', variant: 'positive' });
                            })
                            .finally(() => {
                                instrumentation.deleteFile(file.fileId);
                                deleteDialog.close();

                                // Navigate the modal back to list of all files
                                openPreview();
                            })
                    }
                    itemType={'file'}
                    itemName={file.fileName}
                />
            )}
        </>
    );
}
