import { Message } from '../../components/Message/Message';
import { DatasetFile, FileSource } from '../../types';
import { QuiModalDialog, QuiBox, QuiButton, QuiText, useQuiToasts } from '@tonicai/ui-quinine';
import { getFileNameFromUrl, getRedactedFileUrl } from '../../utils';
import styles from './UploadExternalFilesModal.module.scss';
import { useState } from 'react';

type UploadExternalFilesModalProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    specificFile?: DatasetFile;
    fileSource: FileSource;
    onClick: () => Promise<void>;
}>;

const redactedSuffix = '_REDACTED'; //must stay in sync with the backend

export function UploadFilesToExternalSourceModal({ isOpen, onClose, specificFile, fileSource, onClick }: UploadExternalFilesModalProps) {
    const isForAllFiles = !specificFile;

    const addToast = useQuiToasts();

    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [finishedRedacting, setFinishedRedacting] = useState(false);

    const filename = getFileNameFromUrl(specificFile?.fileName ?? '');

    const textPart1 = isForAllFiles
        ? `Redacted versions of each file will be uploaded to ${fileSource}.`
        : `The redacted version of ${filename} will be uploaded to ${fileSource}.`;

    const textPart2 = isForAllFiles
        ? `The redacted files will appear in the same directory as the original files, with a ${redactedSuffix} suffix on the filename.`
        : `The redacted file will appear in the same directory as the original file, with a ${redactedSuffix} suffix on the filename.`;
    const textPart3 = 'This may take a few moments.';

    const handleClose = () => {
        setFinishedRedacting(false);
        onClose();
    };

    return (
        <QuiModalDialog
            className={styles.modal}
            title={isForAllFiles ? `Upload Files to ${fileSource}` : `Upload File to ${fileSource}`}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <QuiBox display={'flex'} flexDirection={'column'} gap={'md'} padding={'sm'}>
                <QuiBox display={'flex'} flexDirection={'column'} gap={'xs'}>
                    <QuiText>{textPart1}</QuiText>
                    <QuiText>{textPart2}</QuiText>
                    <QuiText>{textPart3}</QuiText>
                    {finishedRedacting && (
                        <Message variant="success">
                            <QuiText>File uploaded successfully!</QuiText>
                            {isForAllFiles ? (
                                <QuiText>{`You can view Redacted Files in ${fileSource}`}</QuiText>
                            ) : (
                                <QuiText>
                                    {'View Redacted File '}
                                    <a href={getRedactedFileUrl(specificFile?.fileName ?? '')} target="_blank" rel={'noreferrer'}>
                                        {'here'}
                                    </a>
                                </QuiText>
                            )}
                        </Message>
                    )}
                </QuiBox>
                <QuiBox display={'flex'} gap={'sm'} justifyContent={'end'}>
                    <QuiButton onClick={handleClose}>Cancel</QuiButton>
                    {!finishedRedacting && (
                        <QuiButton
                            variant={'primary'}
                            onClick={() => {
                                setUploadingFiles(true);
                                onClick()
                                    .then(() => {
                                        setFinishedRedacting(true);
                                    })
                                    .catch((error) => {
                                        addToast({ title: error.response?.data?.message ?? 'Something went wrong', variant: 'destructive' });
                                    })
                                    .finally(() => {
                                        setUploadingFiles(false);
                                    });
                            }}
                            spinner={uploadingFiles}
                        >
                            Upload
                        </QuiButton>
                    )}
                    {finishedRedacting && (
                        <QuiButton variant={'primary'} onClick={handleClose}>
                            Close
                        </QuiButton>
                    )}
                </QuiBox>
            </QuiBox>
        </QuiModalDialog>
    );
}
