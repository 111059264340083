export type PiiTypeGeneratorState = 'Off' | 'Synthesis' | 'Redaction';

// Keep in sync with /backend/Solar.Core/Enums/PiiTypeEnum.cs
export enum PiiTypeEnum {
    NUMERIC_VALUE = 'NUMERIC_VALUE',
    LANGUAGE = 'LANGUAGE',
    MONEY = 'MONEY',
    PRODUCT = 'PRODUCT',
    EVENT = 'EVENT',
    WORK_OF_ART = 'WORK_OF_ART',
    LAW = 'LAW',
    US_PASSPORT = 'US_PASSPORT',
    MEDICAL_LICENSE = 'MEDICAL_LICENSE',
    DATE_TIME = 'DATE_TIME',
    US_BANK_NUMBER = 'US_BANK_NUMBER',
    NRP = 'NRP',
    US_SSN = 'US_SSN',
    IP_ADDRESS = 'IP_ADDRESS',
    ORGANIZATION = 'ORGANIZATION',
    PHONE_NUMBER = 'PHONE_NUMBER',
    US_ITIN = 'US_ITIN',
    LOCATION = 'LOCATION',
    LOCATION_ADDRESS = 'LOCATION_ADDRESS',
    LOCATION_CITY = 'LOCATION_CITY',
    LOCATION_STATE = 'LOCATION_STATE',
    LOCATION_ZIP = 'LOCATION_ZIP',
    LOCATION_COUNTRY = 'LOCATION_COUNTRY',
    CREDIT_CARD = 'CREDIT_CARD',
    US_DRIVER_LICENSE = 'US_DRIVER_LICENSE',
    EMAIL_ADDRESS = 'EMAIL_ADDRESS',
    IBAN_CODE = 'IBAN_CODE',
    URL = 'URL',
    NAME_GIVEN = 'NAME_GIVEN',
    NAME_FAMILY = 'NAME_FAMILY',
    GENDER_IDENTIFIER = 'GENDER_IDENTIFIER',
    PERSON = 'PERSON',
    USERNAME = 'USERNAME',
    PASSWORD = 'PASSWORD',
    PERSON_AGE = 'PERSON_AGE',
    DOB = 'DOB',
    CC_EXP = 'CC_EXP',
    CVV = 'CVV',
    PROJECT_NAME = 'PROJECT_NAME',
    OCCUPATION = 'OCCUPATION',
    MICR_CODE = 'MICR_CODE',
    HEALTHCARE_ID = 'HEALTHCARE_ID',
}
