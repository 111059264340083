import { ComponentType, ReactNode } from 'react';
import { QuiBox } from '@tonicai/ui-quinine';
import styles from './Table.module.scss';

export type TableColumnConfig<T> = {
    label: ReactNode;
    id: string;
    Component: ComponentType<{ data: T }>;
};

export type TableCellProps<T> = Readonly<{ data: T }>;

export type TableProps<T extends { id: string }> = Readonly<{
    data: T[];
    columns: TableColumnConfig<T>[];
    noDataMessage: string;
    ['data-test']?: string;
}>;

export function Table<T extends { id: string }>({ data, columns, noDataMessage, ...props }: TableProps<T>) {
    return (
        <QuiBox bg="background-primary" border="border-default" borderRadius="md" text="text-sm" className={styles.wrapper}>
            <table {...props} className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.id}>
                                <QuiBox text="text-sm" color={'text-secondary'} weight={'normal'}>
                                    {column.label}
                                </QuiBox>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {data.length > 0 ? (
                        data.map((row) => (
                            <tr key={row.id}>
                                {columns.map(({ id, Component }) => (
                                    <td key={id}>
                                        <Component data={row} />
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length}>
                                <QuiBox color="text-secondary" textAlign="center">
                                    {noDataMessage}
                                </QuiBox>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </QuiBox>
    );
}
