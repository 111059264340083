import { useIsHostedProd } from '../../stores/settings';
import {
    QuiIconEnum,
    QuiMenu,
    QuiMenuItem,
    QuiMenuItemBox,
    QuiMenuItems,
    QuiNavButton,
    QuiNavRight,
    QuiNav,
    QuiNavAvatarMenuButton,
    TonicTextualIcon,
    QuiBox,
    QuiMenuItemDivider,
} from '@tonicai/ui-quinine';
import { currentUserAtom, signOut } from '../../stores/auth';
import { useAtomValue } from 'jotai';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';
import { WordCountButton } from '../../pages/Billing/WordCountButton';
import { versionAtom } from '../../stores/version';
import { useMemo } from 'react';
import { BillingGuard } from '@/pages/Billing/BillingGuard';

export function Navigation({ className }: Readonly<{ className?: string }>) {
    const isHostedProd = useIsHostedProd();
    const currentUser = useAtomValue(currentUserAtom);
    const showSystemSettings = !isHostedProd || currentUser?.userName?.endsWith('@tonic.ai');
    const version = useAtomValue(versionAtom);

    // Format the version number for display in the user menu
    const versionString = useMemo(() => {
        let versionString = '';

        if (typeof version !== 'string' && typeof version !== 'number') return null;

        if (typeof version === 'number') {
            versionString = version.toString();
        } else {
            versionString = version;
        }

        const isNumeric = versionString.split('').every((part) => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(part));

        return isNumeric ? `v${version}` : version;
    }, [version]);

    return (
        <QuiNav className={className} fluid variant="purple">
            <TonicTextualIcon height="1rem" />
            <QuiNavButton data-test="nav-link-home" iconLeft="home" to="/">
                Home
            </QuiNavButton>
            <QuiNavButton data-test="nav-link-pipelines" iconLeft="git-branch" to="/pipelines">
                Pipelines
            </QuiNavButton>
            <QuiNavButton data-test="nav-link-datasets" iconLeft="database" to="/datasets">
                Datasets
            </QuiNavButton>
            <QuiNavButton data-test="nav-link-playground" iconLeft="target" to="/playground">
                Playground
            </QuiNavButton>
            <QuiNavRight>
                <QuiNavButton
                    className={'qui-inset-none-sm'}
                    to={TEXTUAL_DOCUMENTATION_URL}
                    external={true}
                    iconLeft={QuiIconEnum.LifeBuoy}
                    style={{ width: 'fit-content' }}
                >
                    View Docs
                </QuiNavButton>
                <QuiNavButton iconLeft="key" to="/user-api-keys">
                    User API Keys
                </QuiNavButton>
                <BillingGuard>{isHostedProd ? <WordCountButton /> : null}</BillingGuard>
                <QuiMenu placement="bottom-end">
                    <QuiNavAvatarMenuButton imageUrl={currentUser?.photoURL ?? undefined} />
                    <QuiMenuItems>
                        <QuiMenuItemBox>
                            <QuiBox padding="sm" display="flex" alignItems="center" gap="sm">
                                <CurrentUserAvatar />
                                <div>{currentUser?.displayName}</div>
                            </QuiBox>
                            {versionString ? (
                                <QuiBox color="text-secondary" textAlign="right" padding="none sm sm sm" text="mono-text-xs">
                                    {versionString}
                                </QuiBox>
                            ) : null}
                        </QuiMenuItemBox>
                        <QuiMenuItemDivider />
                        {showSystemSettings && (
                            <QuiMenuItem iconLeft="settings" to="/system-settings">
                                System Settings
                            </QuiMenuItem>
                        )}
                        <QuiMenuItem iconLeft="log-out" onClick={signOut}>
                            Sign Out
                        </QuiMenuItem>
                    </QuiMenuItems>
                </QuiMenu>
            </QuiNavRight>
        </QuiNav>
    );
}
