import { useAtomValue } from 'jotai';
import {
    deidResponseAtom,
    deidResponseStatusAtom,
    llmSynthesisActuallyEnabledAtom,
    llmSynthesisResponseAtom,
    llmSynthesisResponseStatusAtom,
    previewStatusAtom,
} from './state';
import { usePlaygroundContext } from './usePlaygroundContext';
import { useMemo } from 'react';
import { CodeBox } from '../../components/CodeBlock/CodeBox';

export function DeidResponsePreview() {
    const storeOptions = usePlaygroundContext();
    const deidResponseStatus = useAtomValue(deidResponseStatusAtom, storeOptions);
    const deidResponse = useAtomValue(deidResponseAtom, storeOptions);
    const llmSynthesisResponse = useAtomValue(llmSynthesisResponseAtom, storeOptions);
    const llmSynthesisEnabled = useAtomValue(llmSynthesisActuallyEnabledAtom, storeOptions);
    const llmSynthesisStatus = useAtomValue(llmSynthesisResponseStatusAtom, storeOptions);
    const previewStatus = useAtomValue(previewStatusAtom, storeOptions);

    const code = useMemo(() => {
        if (previewStatus === 'loading') return 'Loading...';
        if (previewStatus === 'error') return 'Error fetching deidentification response';

        if (llmSynthesisEnabled && llmSynthesisStatus === 'success' && llmSynthesisStatus) return JSON.stringify(llmSynthesisResponse, null, 2);

        if (!llmSynthesisEnabled && deidResponseStatus === 'success' && deidResponse) return JSON.stringify(deidResponse, null, 2);

        return 'Write some text above to see the results of deidentification';
    }, [deidResponse, deidResponseStatus, previewStatus, llmSynthesisEnabled, llmSynthesisStatus, llmSynthesisResponse]);

    return (
        <CodeBox
            loading={previewStatus === 'loading'}
            title="De-identification Response"
            language="json"
            disableCopy={deidResponse === null}
            code={code}
            wrapLongLines
        />
    );
}
