import { useState, useCallback } from 'react';
import { Document, Thumbnail } from 'react-pdf';
import { nanoid } from 'nanoid';
import { useFilePreviewContext } from './useFilePreviewContext';
import { useDatasetFile } from '../../stores/datasetFiles';
import { useFileBlob } from '../../stores/fileBlobs';
import { noop } from '../../utils';
import styles from './PdfPagesList.module.scss';
import classNames from 'classnames';

export function PdfPagesList() {
    const { selectedFileId, setNumPages, setPageNumber, pageNumber } = useFilePreviewContext();
    const selectedFile = useDatasetFile(selectedFileId);
    const fileBlob = useFileBlob(selectedFileId);
    const [pageIds, setPageIds] = useState<string[]>([]);
    const onDocumentLoadSuccess = useCallback(
        ({ numPages }: { numPages: number }) => {
            setPageIds(Array.from(new Array(numPages), () => nanoid()));
            setNumPages(numPages);
        },
        [setNumPages]
    );

    if (!selectedFile || selectedFile.fileType !== 'Pdf') return null;

    if (fileBlob.fetchStatus !== 'success') return null;

    return (
        <Document className={styles.pdfPagesList} onItemClick={noop} file={fileBlob.blob} onLoadSuccess={onDocumentLoadSuccess}>
            {pageIds.map((id, index) => (
                <Thumbnail
                    className={classNames(styles.thumbnail, { [styles.active]: index === pageNumber })}
                    width={120}
                    key={id}
                    pageNumber={index + 1}
                    onClick={() => {
                        setPageNumber(index);
                    }}
                />
            ))}
        </Document>
    );
}
