import { useDebounce } from '@/hooks/useDebounce';
import { client } from '@/services/HTTPClient';
import { RegexPreviewMatch, RegexPreviewResponse } from '@/types/datasets';
import { QuiBox, QuiButton, QuiIconEnum, QuiSelect, QuiTextField } from '@tonicai/ui-quinine';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

const PREVIEW_HIGHLIGHT_COLOR = 'rgba(255, 223, 107, 0.5)';
const PREVIEW_DEBOUNCE_DELAY = 500;

type RegexListFieldProps = Readonly<{
    entityType?: string;
    listType: string;
}>;

export function RegexListField({ entityType, listType }: RegexListFieldProps) {
    const fieldName = `${listType}.${entityType}.regexes`;
    const { fields } = useFieldArray(fieldName);
    const form = useForm();
    const [isTestPreviewFetching, setIsTestPreviewFetching] = useState(false);
    const finalFieldIndex = fields.length ? fields.length - 1 : 0;
    const [isTesting, setIsTesting] = useState<{ fieldName: string; value: string; label: string }>();
    const [previewText, setPreviewText] = useState<string>();
    const focusRef = useRef<HTMLInputElement>();
    const [previewResponse, setPreviewResponse] = useState<RegexPreviewResponse>();

    // TODO: more elegant focus with callback ref in separate component
    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    const fetchPreviewResults = async () => {
        try {
            setIsTestPreviewFetching(true);
            const response = await client.get('/api/dataset/preview', {
                params: { text: previewText, regex: form.getFieldState(isTesting!.fieldName)?.value },
            });

            setPreviewResponse(response.data);
        } finally {
            setIsTestPreviewFetching(false);
        }
    };
    const debouncedPreviewText = useDebounce(previewText, PREVIEW_DEBOUNCE_DELAY);
    const debouncedFieldText = useDebounce(isTesting?.fieldName ? form.getFieldState(isTesting.fieldName)?.value : undefined, PREVIEW_DEBOUNCE_DELAY);
    useEffect(() => {
        setPreviewResponse(undefined);
        if (debouncedFieldText && debouncedPreviewText && isTesting) {
            fetchPreviewResults();
        }
        // eslint-disable-next-line
    }, [debouncedFieldText, debouncedPreviewText, isTesting]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="md">
            <QuiBox display="flex" gap="xl">
                <QuiBox style={{ flex: 7 }} padding="none md none none" borderRight="white-600">
                    <QuiBox color="brand-black-600" style={{ marginBottom: '1em' }}>
                        {listType === 'labelAllowLists' && (
                            <>
                                Manually provide additional values to identify as the selected entity type. Ensures that values that Textual did not
                                automatically recognize are detected and labeled appropriately.
                            </>
                        )}
                        {listType === 'labelBlockLists' && (
                            <>
                                Manually provide values that Textual should not identify as the selected entity type. Use this to exclude values that
                                are detected incorrectly.
                            </>
                        )}
                    </QuiBox>

                    <QuiBox weight="medium">Keywords, Phrases, or Regexes</QuiBox>
                    <QuiBox color="brand-black-600">
                        For each entry, you can provide a regular expression that matches the values to{' '}
                        {listType === 'labelAllowLists' ? 'add to' : 'exclude from'} detection.
                    </QuiBox>

                    {fields.map((name: string, index: number) => {
                        return (
                            <QuiBox display="flex" alignItems="center" gap="sm" key={name} margin="sm none">
                                <div style={{ marginRight: '1em' }}>{index + 1}</div>
                                <QuiTextField
                                    style={{ width: '100%' }}
                                    name={name}
                                    // @ts-expect-error quinine doesn't know about onFocus event
                                    onFocus={() => {
                                        if (index === finalFieldIndex) {
                                            fields.push(undefined);
                                        }
                                    }}
                                    onBlur={() => {
                                        if (index !== finalFieldIndex && !fields.value[index]) {
                                            fields.remove(index);
                                        }
                                    }}
                                    onChange={() => {
                                        if (isTesting?.fieldName === name) {
                                            setPreviewResponse(undefined);
                                        }
                                    }}
                                    {...(name === `${fieldName}[0]` && { ref: focusRef })}
                                />
                                {index !== finalFieldIndex && (
                                    <QuiButton
                                        iconRight={QuiIconEnum.Trash}
                                        onClick={() => {
                                            if (isTesting?.fieldName === name) {
                                                setPreviewResponse(undefined);
                                                setIsTesting(undefined);
                                            }
                                            fields.remove(index);
                                        }}
                                    />
                                )}
                            </QuiBox>
                        );
                    })}
                </QuiBox>

                <QuiBox display="flex" flexDirection="column" gap="md" style={{ flex: 3 }}>
                    <QuiBox>
                        <QuiBox weight="medium">Test Entry</QuiBox>
                        <QuiSelect
                            value={isTesting || null}
                            onChange={(selection) => {
                                if (selection) {
                                    setPreviewResponse(undefined);
                                    setIsTesting({ fieldName: `${fieldName}[${selection.value}]`, value: selection.value, label: selection.label });
                                }
                            }}
                            options={fields.value?.filter(Boolean).map((_, index) => {
                                return { fieldName: `${fieldName}[${index}]`, value: String(index), label: `${index + 1}` };
                            })}
                        />
                    </QuiBox>
                    <textarea
                        className="qui-textarea"
                        placeholder="Input sample text to test your selected value."
                        value={previewText}
                        onChange={({ target }) => {
                            setPreviewText(String.raw`${target.value}`);
                        }}
                        style={{
                            minHeight: '100px',
                            padding: '.5em',
                        }}
                    />

                    <div>Results</div>
                    <QuiBox bg="background-secondary" border="white-600" style={{ minHeight: '100px' }} color="brand-black-600" padding="sm">
                        {isTestPreviewFetching && <>Loading...</>}
                        {!isTestPreviewFetching &&
                            previewResponse?.matches.map((segment: RegexPreviewMatch, index: number) => {
                                return (
                                    <span
                                        style={{ backgroundColor: segment.highlighted ? PREVIEW_HIGHLIGHT_COLOR : undefined }}
                                        // eslint-disable-next-line
                                        key={`${segment.text}-${index}`}
                                    >
                                        {segment.text}
                                    </span>
                                );
                            })}
                    </QuiBox>
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
