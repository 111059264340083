// Solar & Quinine
import './scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { QuiToastsProvider } from '@tonicai/ui-quinine';
import * as Sentry from '@sentry/react';
import { pdfjs } from 'react-pdf';
import { HttpToastListener } from './components/HttpToastListener/HttpToastListener';

// Initialize pdf worker options
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

// Sentry: Monitor errors in production
Sentry.init({
    // only enable Sentry in production
    enabled: import.meta.env.PROD,

    // Hard-coded value, but doesn't matter because this will be public anyway. One less env var to setup.
    dsn: 'https://1c7c799154144d66dbe9899c020ff878@o4506077836541952.ingest.sentry.io/4506082985246720',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

dayjs.extend(relativeTime);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <QuiToastsProvider>
                <App />
                <HttpToastListener />
            </QuiToastsProvider>
        </BrowserRouter>
    </React.StrictMode>
);
