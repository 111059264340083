import { z } from 'zod';

export const renderMarkdownToHtmlRequestMessageSchema = z
    .object({
        id: z.string(),
        markdown: z.string(),
    })
    .strict();

export type RenderMarkdownToHtmlRequestMessage = z.infer<typeof renderMarkdownToHtmlRequestMessageSchema>;

export const renderMarkdownToHtmlResponseMessageSchema = z.object({
    id: z.string(),
    html: z.string(),
});

export type RenderMarkdownToHtmlResponseMessage = z.infer<typeof renderMarkdownToHtmlResponseMessageSchema>;
