import { QuiToastAction, QuiToastVariant } from '@tonicai/ui-quinine';
import { atom } from 'jotai';

export interface HttpClientToastMessage {
    title: string;
    variant: QuiToastVariant;
    action?: QuiToastAction;
}

export const httpClientToastAtom = atom<HttpClientToastMessage[]>([]);
