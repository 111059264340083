import { QuiBox, QuiButton, QuiConditionalField, QuiFlexBoxColumn, QuiPasswordField, QuiTextField, QuiText } from '@tonicai/ui-quinine';
import styles from '@/components/CreatePipelineDialog/CreatePipelineDialog.module.scss';
import { SourceType } from '@/components/CreatePipelineDialog/SourceType';
import { Message } from '@/components/Message/Message';
import { requiredString } from '@/validation';

interface DatabricksCredentialsProps {
    testStatus: 'untested' | 'testing' | 'success' | 'error';
    testConnectionEnabled: boolean | undefined;
    submitting: boolean;
    onClick: () => void;
    errorToShow: 'form' | 'test' | 'none';
    testErrorMessage?: string | null;
}

function convertToString(value: unknown): string {
    try {
        return String(value);
    } catch (e) {
        return '';
    }
}

function parseDatabricksUrl(url: unknown): string {
    const urlString = convertToString(url);
    if (urlString === '') return urlString;

    if (urlString.startsWith('https://')) {
        return urlString;
    }
    if (urlString.startsWith('http://')) {
        return urlString.slice(7);
    }
    return `https://${urlString}`;
}

function formatDatabricksUrl(url: unknown): string {
    // Convert to string and if we can't then return empty string
    const urlString = convertToString(url);
    if (urlString === '') return urlString;

    if (urlString.startsWith('https://')) {
        return urlString.slice(8);
    }
    if (urlString.startsWith('http://')) {
        return urlString.slice(7);
    }
    return urlString;
}

export function DatabricksCredentials({
    testStatus,
    testConnectionEnabled,
    submitting,
    onClick,
    errorToShow,
    testErrorMessage,
}: DatabricksCredentialsProps) {
    return (
        <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Databricks}>
            <QuiFlexBoxColumn className={styles.databricksCredentials} gap="md">
                <QuiBox weight="medium" text="text-sm">
                    Databricks Credentials
                </QuiBox>

                <QuiTextField
                    size="sm"
                    validate={requiredString}
                    iconLeft={<QuiText weight="bold">https://</QuiText>}
                    placeholder="your-workspace.cloud.databricks.com"
                    label="Databricks URL"
                    name="databricksUrl"
                    config={{ parse: parseDatabricksUrl, format: formatDatabricksUrl }}
                    id="databricks-workspace-url"
                />

                <QuiPasswordField
                    size="sm"
                    validate={requiredString}
                    label="Access Token"
                    name="databricksAccessToken"
                    id="databricks-access-token"
                />

                <QuiButton
                    spinner={testStatus === 'testing'}
                    iconRight="globe"
                    type="button"
                    size="sm"
                    disabled={!testConnectionEnabled || testStatus === 'testing' || submitting}
                    onClick={onClick}
                >
                    Test Databricks Connection
                </QuiButton>

                {/* Success Message */}
                {errorToShow === 'test' && testStatus === 'success' && <Message variant="success">Successfully connected to Databricks</Message>}

                {/* Error Message */}
                {errorToShow === 'test' && testStatus === 'error' && (
                    <Message variant="error">{testErrorMessage ?? 'Could not connect to Databricks'}</Message>
                )}
            </QuiFlexBoxColumn>
        </QuiConditionalField>
    );
}
