import { useCallback } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { CloudFileExplorer, FileSystemType } from '../../components/CloudFileExplorer/CloudFileExplorer';
import { TitleBox } from '../../components/TitleBox/TitleBox';

type PrefixesAndFilesFieldProps = Readonly<{
    parseJobConfigId: string;
    fileSystemType: FileSystemType;
}>;

export function PathPrefixesAndSelectedFilesFields({ parseJobConfigId, fileSystemType }: PrefixesAndFilesFieldProps) {
    const {
        fields: { push: addPrefixPattern, remove: removePrefixPattern, value: pathPrefixes },
    } = useFieldArray<string>('pathPrefixes');
    const {
        fields: { push: addSelectedFile, remove: removeSelectedFile, value: selectedFiles },
    } = useFieldArray<string>('selectedFiles');

    const onBucketOrPathSelected = useCallback(
        (pathOrBucket: string) => {
            if (pathPrefixes?.includes(pathOrBucket)) {
                removePrefixPattern(pathPrefixes?.indexOf(pathOrBucket));
            } else {
                addPrefixPattern(pathOrBucket);
            }
        },
        [pathPrefixes, addPrefixPattern, removePrefixPattern]
    );

    const onFileSelected = useCallback(
        (filePath: string) => {
            if (selectedFiles?.includes(filePath)) {
                removeSelectedFile(selectedFiles?.indexOf(filePath));
            } else {
                addSelectedFile(filePath);
            }
        },
        [removeSelectedFile, addSelectedFile, selectedFiles]
    );

    return (
        <TitleBox title="Select folders and files to add to run" padding="none">
            <CloudFileExplorer
                disableNestedPaths={true}
                allowFileSelection={true}
                allowMultipleSelection
                onFileSelected={onFileSelected}
                onPathSelected={onBucketOrPathSelected}
                parseJobConfigId={parseJobConfigId}
                pathPrefixes={pathPrefixes ?? []}
                selectedFiles={selectedFiles ?? []}
                fileSystemType={fileSystemType}
            />
        </TitleBox>
    );
}
