import { QuiBox } from '@tonicai/ui-quinine';
import { FileType } from '../../types';
import styles from './TextFilePreviewTable.module.scss';

type TextFilePreviewTableProps = Readonly<{
    filePreviewHtml: string;
    fileName: string;
    fileType: FileType;
}>;

export function TextFilePreviewTable({ filePreviewHtml, fileName, fileType }: TextFilePreviewTableProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>{fileName}</div>
                <div className={styles.content}>
                    {fileType === 'DocX' ? (
                        <QuiBox style={{ height: '100%' }} display="flex" bg="background-primary">
                            <iframe
                                referrerPolicy={'no-referrer'}
                                sandbox={''}
                                title={fileName}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                srcDoc={filePreviewHtml}
                            />
                        </QuiBox>
                    ) : (
                        <div data-test={'file-preview-table'} className={'preview-table'} dangerouslySetInnerHTML={{ __html: filePreviewHtml }} />
                    )}
                </div>
            </div>
        </div>
    );
}
