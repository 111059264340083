import { QuiBox, QuiButton, QuiPopover, QuiSpinner, QuiText, useQuiModal, useQuiToasts } from '@tonicai/ui-quinine';
import { Message } from '../../components/Message/Message';
import { useAtomValue } from 'jotai';
import { useFileUploadPipelineContext } from '../../contexts/FileUploadPipeline/useFileUploadPipelineContext';
import { Table, TableCellProps, TableColumnConfig } from '../../components/Table/Table';
import { FileUploadPipelineFileRow } from '../../contexts/FileUploadPipeline/fileUploadPipelineAPI';
import { client } from '../../services/HTTPClient';
import { FileUploadPipelineFileStatusBadge } from './FileUploadPipelineFileStatusBadge';
import { PlainLink } from '../../components/PlainLink/PlainLink';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

function FilenameCell({ data }: TableCellProps<FileUploadPipelineFileRow>) {
    if (data.fileLink) {
        return <PlainLink to={data.fileLink}>{data.fileName}</PlainLink>;
    }

    return data.fileName;
}

function FileStatusCell({ data }: TableCellProps<FileUploadPipelineFileRow>) {
    return <FileUploadPipelineFileStatusBadge data-test="pipeline-run-file-status" status={data.status} />;
}

function DeleteCell({ data }: TableCellProps<FileUploadPipelineFileRow>) {
    const deletePopover = useQuiModal();
    const api = useFileUploadPipelineContext();
    const { parseJobConfigId } = usePipelineContext();
    const addToast = useQuiToasts();
    const deleteEndpoint = `/api/parsejobconfig/${parseJobConfigId}/local-files/${encodeURIComponent(data.fileName)}`;

    if (!data.isDeletable) return null;

    return (
        <QuiPopover
            isOpen={deletePopover.isOpen}
            onClose={deletePopover.close}
            content={
                <QuiBox display="flex" flexDirection="column" gap="sm" padding="md">
                    <QuiText size="text-sm">Are you sure you want to delete this file?</QuiText>
                    <QuiBox>{data.fileName}</QuiBox>
                    <QuiBox display="flex" gap="sm" justifyContent="end">
                        <QuiButton size="sm" onClick={deletePopover.close}>
                            Cancel
                        </QuiButton>
                        <QuiButton
                            size="sm"
                            variant="destructive"
                            data-test="confirm-delete-file-upload-pipeline-file-button"
                            onClick={() => {
                                client
                                    .delete(deleteEndpoint)
                                    .then(() => {
                                        addToast({
                                            title: `File ${data.fileName} deleted successfully`,
                                            variant: 'positive',
                                        });

                                        if (data?.uploadId) {
                                            api.deleteFileUpload(data.uploadId);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        addToast({
                                            title: `Unable to delete the file: ${data.fileName}`,
                                            variant: 'destructive',
                                        });
                                    })
                                    .finally(() => {
                                        return api.fetchUploadedFiles();
                                    });
                                deletePopover.close();
                            }}
                        >
                            Delete
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            }
        >
            <QuiButton size="sm" data-test="delete-file-upload-pipeline-file-button" onClick={deletePopover.toggle} iconRight="trash-2" />
        </QuiPopover>
    );
}

const config: TableColumnConfig<FileUploadPipelineFileRow>[] = [
    {
        id: 'filename',
        Component: FilenameCell,
        label: 'Filename',
    },
    {
        id: 'status',
        Component: FileStatusCell,
        label: 'Status',
    },
    {
        id: 'delete',
        Component: DeleteCell,
        label: '',
    },
];

export function FilesTableLocal() {
    const api = useFileUploadPipelineContext();
    const uiStatus = useAtomValue(api.uiStatusAtom, api.storeOptions);
    const filesTableRowData = useAtomValue(api.filesTableRowDataAtom, api.storeOptions);

    if (uiStatus === 'loading') return <QuiSpinner />;

    if (uiStatus === 'error') return <Message variant="error">Unable to fetch files</Message>;

    return (
        <Table<FileUploadPipelineFileRow>
            data-test="pipeline-files-table"
            data={filesTableRowData}
            columns={config}
            noDataMessage="No Files Uploaded Yet"
        />
    );
}
