import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { globalStore } from './globalStore';
import { client } from '../services/HTTPClient';
import {
    LoginAndRefreshTokenResponseModel,
    SignInFormValues,
    SignUpFormValues,
    SsoSettingsFromApi,
    EnabledSSOConfig,
    SSOSignInConfig,
    CurrentUser,
    SSOCallbackData,
    SSOErrorData,
    SsoProvider,
    ResetPasswordRequestModel,
    SsoSettingsResponse,
    LoginAndRefreshTokenResponseModelSchema,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    OnboardingSurveyData,
} from '../types';
import { appURL, parseDate } from '../utils';
import { FORM_ERROR } from 'final-form';
import { isAxiosError } from 'axios';
import { instrumentation } from '../instrumentation/instrumentation';
import { fullStoryAnonymize, fullStoryIdentify } from '../instrumentation/fullstory';
import { hubspot } from '../instrumentation/hubspot';
import { gtag } from '../instrumentation/gtag';

// internal constants (not exported)

const GENERIC_ERROR_MESSAGE = 'Something went wrong';
const AUTH_STATE_STORAGE_KEY = 'solar.auth-state';

// internal utilities (not exported)

function isLoginAndRefreshTokenResponseExpired(authResponse: LoginAndRefreshTokenResponseModel): boolean {
    const createdDate = new Date(authResponse.createdDate);
    const createdTimestamp = createdDate.valueOf();
    const expirationTimestamp = authResponse.expiresIn * 1000 + createdTimestamp;
    const nowTimestamp = new Date().valueOf();

    return expirationTimestamp < nowTimestamp;
}

function getAuthStateFromLocalStorage(): LoginAndRefreshTokenResponseModel | null {
    const value = window.localStorage.getItem(AUTH_STATE_STORAGE_KEY);

    if (value === null) return null;

    try {
        const parsed = JSON.parse(value);
        const validationResult = LoginAndRefreshTokenResponseModelSchema.safeParse(parsed);

        if (validationResult.error) return null;

        const loginAndRefreshTokenResponseModel = validationResult.data;

        if (isLoginAndRefreshTokenResponseExpired(validationResult.data)) {
            window.localStorage.removeItem(AUTH_STATE_STORAGE_KEY);
            instrumentation.resetUser();
            return null;
        }

        if (
            loginAndRefreshTokenResponseModel?.amplitudeApiKey &&
            loginAndRefreshTokenResponseModel?.amplitudeApiUrl &&
            loginAndRefreshTokenResponseModel.user?.userName
        ) {
            instrumentation.init(
                loginAndRefreshTokenResponseModel.amplitudeApiKey,
                loginAndRefreshTokenResponseModel.amplitudeApiUrl,
                loginAndRefreshTokenResponseModel.user?.userName
            );
        }

        return loginAndRefreshTokenResponseModel;
    } catch {
        return null;
    }
}

// internal actions

function setAuthResponse(authResponse: LoginAndRefreshTokenResponseModel | null) {
    const validationResult = LoginAndRefreshTokenResponseModelSchema.safeParse(authResponse);
    const value = validationResult.error ? null : validationResult.data;

    if (value) {
        if (value.amplitudeApiKey && value.amplitudeApiUrl && value.user?.userName) {
            instrumentation.init(value.amplitudeApiKey, value.amplitudeApiUrl, value.user.userName);
        }
    } else {
        instrumentation.resetUser();
    }
    globalStore.set(authResponseAtom, value);
}

// atoms (primitive)

export const authResponseAtom = atomWithStorage<LoginAndRefreshTokenResponseModel | null>(AUTH_STATE_STORAGE_KEY, getAuthStateFromLocalStorage());
export const activeSsoProviderAtom = atom<string | null>(null);
export const hasUsersAtom = atom<null | boolean>(null);
export const showOnboardingSurveyAtom = atom<boolean>(false);
export const ssoSettingsAtom = atom<null | SsoSettingsFromApi[]>(null);
export const isSsoRequiredAtom = atom<boolean>(false);

// atoms (computed)
export const currentUserAtom = atom<CurrentUser | null>((get) => {
    const authResponse = get(authResponseAtom) ?? null;

    if (authResponse === null) return null;

    const user = authResponse.user;

    if (!user) return null;

    // Create displayName field from API's user response
    let displayName = user.userName;
    if (user.firstName) {
        if (user.lastName) {
            displayName = `${user.firstName} ${user.lastName}`;
        } else {
            displayName = user.firstName;
        }
    }

    // Identify user with FullStory. Does nothing if FullStory is not loaded,
    // so it is safe to be called for every user
    if (user.id && user.userName) {
        fullStoryIdentify(user.id, {
            email: user.userName,
            displayName: displayName,
        });
    }

    // Identify user in Hubspot. We're doing it here since this will catch both
    // email sign ups and SSO sign ins. This will track every logged in user,
    // but we have protections in the hubspot code to ensure this only happens
    // in the hosted environment.
    if (user.userName) {
        hubspot.identifyUser(user.userName, displayName !== user.userName ? displayName : undefined);
    }

    return {
        displayName,
        id: user.id,
        userName: user.userName,
        createdDate: parseDate(authResponse.createdDate),
        lastActivityDate: parseDate(user?.lastActivityDate),
        photoURL: user.photoUpload?.url ?? null,
    };
});

export const enabledSSOConfigsAtom = atom((get) => {
    const settingsFromApi = get(ssoSettingsAtom);

    if (!settingsFromApi) return null;

    const enabledSettings: EnabledSSOConfig[] = [];

    settingsFromApi.forEach((s) => {
        if (!s.enabled || !s.clientId || !s.provider) return;

        enabledSettings.push({
            clientId: s.clientId,
            provider: s.provider,
            domain: s.domain,
            identityProviderId: s.identityProviderId,
            authorizationServerId: s.authorizationServerId,
            canUseAsExternalFileSource: s.canUseAsExternalFileSource,
        });
    });

    return enabledSettings.length > 0 ? enabledSettings : null;
});

export const refreshTokenTimeoutAtom = atom((get) => {
    const authResponse = get(authResponseAtom);

    if (!authResponse) return null;

    const createdDate = new Date(authResponse.createdDate);
    const createdTimestamp = createdDate.valueOf();
    const nowTimestamp = new Date().valueOf();
    const refreshTokenTimeout = createdTimestamp + authResponse.refreshTokenRefreshIntervalInSeconds * 1000 - nowTimestamp;

    // as a safety precaution (since this shouldn't ever be called)
    if (refreshTokenTimeout < 0) {
        return null;
    }

    return refreshTokenTimeout;
});

/**
 * Have we called the "has users" & "SSO settings" & "onboarding survey" endpoints?
 */
export const authInitializedAtom = atom((get) => {
    if (get(hasUsersAtom) === null) return false;

    if (get(ssoSettingsAtom) === null) return false;

    if (get(showOnboardingSurveyAtom) === null) return false;

    return get(ssoCallbackDataAtom) === null;
});

/**
 * Whether a user is signed in or not
 */
export const signedInAtom = atom((get) => {
    return get(authResponseAtom) !== null;
});

/**
 *
 * ACTIONS
 *
 */

export const ssoCallbackDataAtom = atom<SSOCallbackData | SSOErrorData | null>(null);

export function setSSOCallbackData(data: SSOCallbackData | SSOErrorData | null) {
    globalStore.set(ssoCallbackDataAtom, data);
}

export async function ssoSignIn(token: string, provider: SsoProvider, parameters: Record<string, string | null>) {
    try {
        const postData: SSOSignInConfig = {
            parameters,
            originalRedirectUrl: appURL(`/sso/callback/${provider}`),
            token,
            provider,
        };
        const { data } = await client.post<LoginAndRefreshTokenResponseModel>('/api/sso/login', postData);

        const validationResult = LoginAndRefreshTokenResponseModelSchema.safeParse(data);

        if (validationResult.success) {
            gtag.trackSignup(validationResult.data.user?.createdAt);
        }

        setAuthResponse(data);
    } catch (e) {
        console.error(e);
        setAuthResponse(null);
        // Fire off a notification
    }
}

export async function signOut() {
    const authResponse = globalStore.get(authResponseAtom);

    if (authResponse) {
        await client.post('/api/auth/logout', { refeshToken: authResponse.refreshToken });
    }

    // Reset session for FullStory. Does nothing if FullStory is not loaded.
    fullStoryAnonymize();

    setAuthResponse(null);
}

export async function refreshAccessToken() {
    const authResponse = globalStore.get(authResponseAtom);

    if (!authResponse) {
        setAuthResponse(null);
        return;
    }

    try {
        const { data } = await client.post<LoginAndRefreshTokenResponseModel>('/api/auth/token_refresh', {
            refreshToken: authResponse.refreshToken,
        });

        setAuthResponse(data);
    } catch (e) {
        signOut();
    }
}

export async function signIn(values: SignInFormValues) {
    try {
        const { data } = await client.post<LoginAndRefreshTokenResponseModel>('/api/auth/login', values);
        setAuthResponse(data);
        return undefined;
    } catch (e) {
        signOut();
        let errorMessage = GENERIC_ERROR_MESSAGE;

        if (isAxiosError(e)) {
            errorMessage = e.response?.data?.login_failure?.[0] ?? errorMessage;
        }

        return {
            [FORM_ERROR]: errorMessage,
        };
    }
}

export async function signUp(values: SignUpFormValues) {
    try {
        const { data } = await client.post<'Account created'>('/api/accounts', values);
        if (data === 'Account created') {
            gtag.trackSignup(new Date().toISOString());
            return undefined;
        } else {
            throw new Error(GENERIC_ERROR_MESSAGE);
        }
    } catch (e) {
        signOut();

        let errorMessage = GENERIC_ERROR_MESSAGE;

        if (isAxiosError(e)) {
            errorMessage = e.response?.data?.login_failure?.[0] ?? errorMessage;
        }

        return {
            [FORM_ERROR]: errorMessage,
        };
    }
}

export async function resetPassword(data: ResetPasswordRequestModel) {
    return client.post('/api/accounts/passwordreset', data);
}

export async function resetForgottenPassword(request: ResetPasswordRequest): Promise<boolean> {
    return await client.post(`/api/reset-password`, request).then((res) => res.status === 200);
}

export async function forgotPassword(request: ForgotPasswordRequest): Promise<void> {
    const res = await client.post(`/api/forgot-password`, request);

    if (res.status === 200) {
        return;
    }

    throw new Error('Error resetting password');
}

export async function fetchUsersExist() {
    try {
        const { data } = await client.get<boolean>('/api/users/exists');
        globalStore.set(hasUsersAtom, data);
    } catch (e) {
        console.error(e);
    }
}

export async function fetchShowSurvey() {
    try {
        const { data } = await client.get<boolean>('/api/users/onboarding-survey/show-survey');

        globalStore.set(showOnboardingSurveyAtom, data);
    } catch (e) {
        console.error(e);
    }
}

export async function postOnboardingSurveyResults(surveyData: OnboardingSurveyData) {
    try {
        await client.post<OnboardingSurveyData>('/api/users/onboarding-survey', surveyData);
        globalStore.set(showOnboardingSurveyAtom, false);
    } catch (e) {
        console.error(e);
    }
}

export async function fetchSsoSettings() {
    try {
        const { data } = await client.get<SsoSettingsResponse>('/api/sso/settings');
        globalStore.set(ssoSettingsAtom, data.settings);
        globalStore.set(isSsoRequiredAtom, data.isRequired);
    } catch (e) {
        console.error(e);
    }
}

export function initAuth() {
    fetchSsoSettings();
    fetchUsersExist();
}

// Helper hooks

export function useCurrentUser() {
    return useAtomValue(currentUserAtom);
}
