import { useCallback, useMemo, useState } from 'react';

/**
 * A hook that should only be used within the FilePreviewProvider component. It contains
 * all of the logic for handling file previews, whether displaying: all files, a single file, paginated file, etc.
 *
 * @param datasetId A string representing the dataset id
 */
export function useFilePreviews(datasetId: string) {
    const [preview, setPreview] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState<string | undefined>(undefined);
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);

    const _setPageNumber = useCallback(
        (newPageNumber: number) => {
            setPageNumber(Math.max(0, Math.min(newPageNumber, numPages - 1)));
        },
        [numPages]
    );

    const _setNumPages = useCallback((numPages: number) => {
        setNumPages(numPages);
        setPageNumber(0);
    }, []);

    const hasPreviousPage = useMemo(() => {
        return pageNumber > 0;
    }, [pageNumber]);

    const hasNextPage = useMemo(() => {
        return pageNumber < numPages - 1;
    }, [pageNumber, numPages]);

    const nextPage = useCallback(() => {
        setPageNumber((n) => n++);

        setPageNumber((currentPageNumber) => {
            if (currentPageNumber < numPages - 1) {
                return currentPageNumber + 1;
            } else {
                return currentPageNumber;
            }
        });
    }, [numPages]);

    const previousPage = useCallback(() => {
        setPageNumber((currentPageNumber) => {
            if (currentPageNumber > 0) {
                return currentPageNumber - 1;
            }

            return 0;
        });
    }, []);

    const openPreview = useCallback(() => {
        setSelectedFileId(undefined);
        setPreview(true);
    }, []);

    const openFilePreview = useCallback((fileId: string) => {
        setSelectedFileId(fileId);
        setPreview(true);
    }, []);

    const closePreview = useCallback(() => {
        setPreview(false);
        setSelectedFileId(undefined);
    }, []);

    return {
        preview,
        selectedFileId,
        numPages,
        pageNumber,
        hasPreviousPage,
        hasNextPage,
        nextPage,
        previousPage,
        openPreview,
        openFilePreview,
        closePreview,
        datasetId,
        setNumPages: _setNumPages, // We want to reset the current page whenever the number of pages changes
        setPageNumber: _setPageNumber, // This has a check to ensure the new page number is within the bounds of document
    };
}
