import {
    QuiBox,
    QuiButton,
    QuiCell,
    QuiFlexBoxColumn,
    QuiForm,
    QuiGridLayout,
    QuiModalContent,
    QuiModalDialog,
    QuiPasswordField,
    QuiSubmitButton,
    QuiTextField,
    useQuiModal,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import { FORM_ERROR } from 'final-form';
import { FormSpy, useForm } from 'react-final-form';
import { FormError } from '../../components/FormError/FormError';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';
import { ITestResponse, TestCredentialsButton } from '../../components/TestCredentialsButton/TestCredentialsButton';
import { TitleBox } from '../../components/TitleBox/TitleBox';
import { client } from '../../services/HTTPClient';
import { updateParseJob } from '../../stores/parse-jobs';
import { AzureCredential, AzureCredentialSchema, FileSourceEnum } from '../../types';
import { requiredString } from '../../validation';

const INITIAL_VALUES: AzureCredential = {
    accountName: '',
    accountKey: '',
};

export function AzureCredentials() {
    const { parseJobConfig } = usePipelineContext();
    const updateCredentialsDialog = useQuiModal();

    const addToast = useQuiToasts();

    const form = useForm();

    return (
        <>
            <TitleBox title="Azure Credentials">
                <QuiGridLayout gap="md">
                    <QuiCell width={4}>
                        <QuiTextField
                            disabled
                            label="Account Name"
                            name="parseJobExternalCredential.credential.accountName"
                            id="azure-account-name"
                        />
                    </QuiCell>
                    <QuiCell width={4}>
                        <QuiPasswordField
                            disabled
                            label="Account Key"
                            name="parseJobExternalCredential.credential.accountKey"
                            id="azure-account-key"
                        />
                    </QuiCell>
                </QuiGridLayout>
                <QuiBox display="flex" alignItems="center" gap="md">
                    <QuiButton type="button" onClick={updateCredentialsDialog.open}>
                        Update Azure Credentials
                    </QuiButton>
                </QuiBox>
            </TitleBox>

            <QuiModalDialog title="Update Azure Credentials" isOpen={updateCredentialsDialog.isOpen} onClose={updateCredentialsDialog.close}>
                <QuiModalContent>
                    <QuiForm<AzureCredential>
                        initialValues={INITIAL_VALUES}
                        validate={(values) => {
                            const azureCredentials = AzureCredentialSchema.safeParse(values);

                            if (azureCredentials.error) {
                                const actualErrors: Partial<Record<keyof AzureCredential, string>> = {};

                                const { fieldErrors } = azureCredentials.error.flatten();

                                Object.entries(fieldErrors).forEach(([field, error]) => {
                                    if (Array.isArray(error) && error.length > 0) {
                                        actualErrors[field as keyof AzureCredential] = error[0];
                                    }
                                });

                                if (Object.keys(actualErrors).length > 0) {
                                    return actualErrors;
                                }
                            }

                            return undefined;
                        }}
                        onSubmit={async (values) => {
                            const cloudConnectionResult = await client.post<ITestResponse>(`/api/cloudfile/test-cloud-connection`, {
                                fileSource: FileSourceEnum.Azure,
                                credential: values,
                            });

                            if (!cloudConnectionResult.data.success) {
                                return {
                                    [FORM_ERROR]: 'Invalid Azure credentials: ' + cloudConnectionResult.data.errorMessage,
                                };
                            }

                            return await updateParseJob(parseJobConfig.id, {
                                parseJobExternalCredential: {
                                    fileSource: FileSourceEnum.Azure,
                                    credential: values,
                                },
                            })
                                .then(() => {
                                    form.change('parseJobExternalCredential', values);

                                    addToast({
                                        title: 'Azure Credentials updated successfully',
                                        variant: 'positive',
                                    });

                                    updateCredentialsDialog.close();

                                    return undefined;
                                })
                                .catch((error) => {
                                    console.error(error);

                                    if (error.response && error.response.data && error.response.data.errorMessage) {
                                        return { [FORM_ERROR]: error.response.data.errorMessage };
                                    }

                                    return { [FORM_ERROR]: 'Failed to update Azure credentials. Please check your input and try again.' };
                                });
                        }}
                    >
                        <QuiFlexBoxColumn gap="md">
                            <QuiTextField validate={requiredString} label="Account Name" name="accountName" id="azure-account-name" />
                            <QuiPasswordField validate={requiredString} label="Account Key" name="accountKey" id="azure-account-key" />
                            <TestCredentialsButton fileSystemType="azure" accountNameFieldName="accountName" accountKeyFieldName="accountKey" />
                            <FormError />
                            <QuiBox display="flex" justifyContent="end" gap="sm">
                                <QuiButton type="button" onClick={updateCredentialsDialog.close}>
                                    Cancel
                                </QuiButton>
                                <FormSpy subscription={{ validating: true, valid: true }}>
                                    {({ valid, validating }) => (
                                        <QuiSubmitButton disabled={!valid || validating} variant="brand-purple">
                                            Update Azure Credentials
                                        </QuiSubmitButton>
                                    )}
                                </FormSpy>
                            </QuiBox>
                        </QuiFlexBoxColumn>
                    </QuiForm>
                </QuiModalContent>
            </QuiModalDialog>
        </>
    );
}
