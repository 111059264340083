export enum SolarEnvironmentVariable {
    IsHostedProd = 'SOLAR_IS_HOSTED_PROD',
    OcrEnabled = 'OCR_ENABLED',
    SolarSharepointUploadEnabled = 'SOLAR_SHAREPOINT_UPLOAD_ENABLED',
    HAS_OPENAI_API_KEY = 'HAS_OPENAI_API_KEY',
    ARE_AWS_CREDS_AVAILABLE = 'AWS_CREDS_AVAILABLE',
    INTERNAL_BUCKET_SET = 'INTERNAL_BUCKET_SET',
}

export type SolarEnvironmentVariables = Record<SolarEnvironmentVariable, unknown>;
