import { client } from '../services/HTTPClient';
import { PdfRedaction, PdfRedactionTemplate, PdfRedactionTemplateBase } from '../types';
import axios from 'axios';

type UpdatePdfManualRedactionTemplateRequest = {
    name?: string;
    redactions?: PdfRedaction[];
};

type CreatePdfManualRedactionTemplateRequest = {
    name: string;
    redactions: PdfRedaction[];
};

const getBaseEndpointUrl = (datasetId: string) => `/api/dataset/${datasetId}/pdfredaction/template`;

let fetchPdfRedactionTemplatesAbortController = new AbortController();

export function fetchPdfRedactionTemplates(datasetId: string) {
    fetchPdfRedactionTemplatesAbortController.abort();
    fetchPdfRedactionTemplatesAbortController = new AbortController();
    return client
        .get<PdfRedactionTemplateBase[]>(getBaseEndpointUrl(datasetId), { signal: fetchPdfRedactionTemplatesAbortController.signal })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                //do nothing
                return [];
            } else {
                throw e;
            }
        });
}

let fetchPdfRedactionTemplatesForFileAbortController = new AbortController();

export async function fetchPdfRedactionTemplate(datasetId: string, templateId?: string) {
    if (!templateId) return Promise.resolve(undefined);

    fetchPdfRedactionTemplatesForFileAbortController.abort();
    fetchPdfRedactionTemplatesForFileAbortController = new AbortController();

    return await client
        .get<PdfRedactionTemplate>(`${getBaseEndpointUrl(datasetId)}/${templateId}`, {
            signal: fetchPdfRedactionTemplatesForFileAbortController.signal,
        })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                //do nothing
                return undefined;
            } else {
                throw e;
            }
        });
}

export async function createPdfManualRedactionTemplate(datasetId: string, templateName: string, redactions: PdfRedaction[]) {
    const request: CreatePdfManualRedactionTemplateRequest = {
        name: templateName,
        redactions: redactions,
    };

    return client.post<PdfRedactionTemplate>(getBaseEndpointUrl(datasetId), request, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function updatePdfManualRedactionTemplate(datasetId: string, templateId: string, templateName?: string, redactions?: PdfRedaction[]) {
    const request: UpdatePdfManualRedactionTemplateRequest = {
        name: templateName,
        redactions: redactions,
    };

    await client.put<PdfRedactionTemplate>(`${getBaseEndpointUrl(datasetId)}/${templateId}`, request, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function deletePdfRedactionTemplate(datasetId: string, templateId: string) {
    await client.delete(`${getBaseEndpointUrl(datasetId)}/${templateId}`);
}
