import { QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { useFilePreviewContext } from './useFilePreviewContext';
import { useDataset } from '../../stores/datasets';
import { FilesGrid } from './FilesGrid';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import classNames from 'classnames';
import { useDatasetFile } from '../../stores/datasetFiles';
import { PdfPagesList } from './PdfPagesList';
import { TextFilePreview } from './TextFilePreview';
import { PdfFilePreview } from './PdfFilePreview';
import { FilePreviewDialogTitle } from './FilePreviewDialogTitle';
import styles from './FilePreviewDialog.module.scss';

export function FilePreviewDialog() {
    const { preview, closePreview, datasetId, selectedFileId } = useFilePreviewContext();
    const dataset = useDataset(datasetId);
    const selectedFile = useDatasetFile(selectedFileId);

    const isPdf = selectedFile?.fileType === 'Pdf';

    const isImageFile =
        selectedFile?.fileType === 'Png' || selectedFile?.fileType === 'Jpg' || selectedFile?.fileType === 'Tif' || selectedFile?.fileType === 'Pdf';
    const isTextFile = selectedFile?.fileType === 'Raw' || selectedFile?.fileType === 'Csv' || selectedFile?.fileType === 'DocX';

    useBodyOverflowHidden(preview);

    if (!dataset) return null;

    const className = classNames(styles.content, {
        [styles.withSidebar]: isPdf,
        [styles.withoutSidebar]: !isPdf,
    });

    return (
        <QuiModalDialog className={styles.previewDialog} icon={'file'} title={<FilePreviewDialogTitle />} isOpen={preview} onClose={closePreview}>
            <QuiModalContent className={className}>
                {isPdf && (
                    <div className={styles.sidebar}>
                        <div className={styles.sidebarTiles}>{selectedFile?.fileType === 'Pdf' && <PdfPagesList />}</div>
                    </div>
                )}
                <div className={styles.main}>
                    {selectedFile && isImageFile ? <PdfFilePreview datasetId={datasetId} file={selectedFile} /> : null}
                    {selectedFile && isTextFile ? <TextFilePreview datasetId={datasetId} file={selectedFile} /> : null}
                    {!selectedFile ? <FilesGrid dataset={dataset} /> : null}
                </div>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
