import { QuiBox, QuiBoxProps } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';

type TitleBoxProps = Readonly<{
    title: ReactNode;
    children: React.ReactNode;
    padding?: QuiBoxProps['padding'];
}>;

export function TitleBox({ title, children, padding }: TitleBoxProps) {
    return (
        <QuiBox bg="background-primary" border="border-default" borderRadius="md">
            <QuiBox text="text-sm" weight="medium" padding="md" borderBottom="border-default">
                {title}
            </QuiBox>
            <QuiBox padding={padding ?? 'md'} display="flex" flexDirection="column" gap="md">
                {children}
            </QuiBox>
        </QuiBox>
    );
}
