import { useEffect } from 'react';

export function useBodyOverflowHidden(enabled: boolean) {
    useEffect(() => {
        if (enabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [enabled]);
}
