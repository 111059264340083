import { useFetchedBillingData } from '@/stores';
import { ReactNode } from 'react';

/**
 * A component that only renders its children if the billing data has been fetched.
 *
 * Useful for ensuring any billing UI is only visible once its most up-to-date server
 * state has been fetched.
 */

export function BillingGuard({ children }: Readonly<{ children: ReactNode }>) {
    const fetchedBillingData = useFetchedBillingData();

    if (!fetchedBillingData) return null;

    return children;
}
