import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { QuiBox, QuiIconToken, WithIcon } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import styles from './Tabs.module.scss';
import { Link, useLocation, useMatch } from 'react-router-dom';

type TabsContextValue = {
    activeTab: string;
    setActiveTab: (tab: string) => void;
};

const TabsContext = createContext<TabsContextValue>({} as TabsContextValue);

function useTabs() {
    return useContext(TabsContext);
}

type TabsListProps = Readonly<{
    children: ReactNode;
}>;

function TabTriggers({ children }: TabsListProps) {
    return <QuiBox display="flex">{children}</QuiBox>;
}

type TabTriggerProps = Readonly<{
    id: string;
    children: ReactNode;
    icon?: QuiIconToken;
    ['data-test']?: string;
}>;

function TabTrigger({ icon, children, id, ...props }: TabTriggerProps) {
    const { activeTab, setActiveTab } = useTabs();

    const className = classNames(styles.tabTrigger, {
        [styles.tabTriggerActive]: id === activeTab,
    });

    return (
        <button
            {...props}
            className={className}
            type="button"
            onClick={() => {
                setActiveTab(id);
            }}
        >
            <WithIcon iconLeft={icon}>{children}</WithIcon>

            <span className={styles.tabTriggerBorderBottom} />
        </button>
    );
}

type TabTriggerLinkProps = Readonly<{
    to: string;
    icon?: QuiIconToken;
    children: ReactNode;
}>;

function TabTriggerLink({ icon, children, to }: TabTriggerLinkProps) {
    const location = useLocation();

    const className = classNames(styles.tabTrigger, {
        [styles.tabTriggerActive]: location.pathname === to,
    });

    return (
        <Link className={className} to={to}>
            <WithIcon iconLeft={icon}>{children}</WithIcon>
        </Link>
    );
}

type TabContentProps = Readonly<{
    id: string;
    children: ReactNode;
}>;

function TabContent({ id, children }: TabContentProps) {
    const { activeTab } = useTabs();

    if (id !== activeTab) return null;

    return (
        <QuiBox borderTop="border-default" padding="md none" position="relative">
            {children}
        </QuiBox>
    );
}

type TabLinkContentProps = Readonly<{
    to: string;
    children: ReactNode;
}>;

function TabLinkContent({ to, children }: TabLinkContentProps) {
    const match = useMatch(to);

    if (!match) return null;

    return (
        <QuiBox borderTop="border-default" padding="md" position="relative" bg="background-primary">
            {children}
        </QuiBox>
    );
}

type TabsProps = Readonly<{
    children: ReactNode;
    defaultTab: string;
}>;

function Container({ children, defaultTab }: TabsProps) {
    const [activeTab, setActiveTab] = useState<string | null>(null);

    const contextValue = useMemo<TabsContextValue>(
        () => ({
            activeTab: activeTab ?? defaultTab,
            setActiveTab,
        }),
        [activeTab, defaultTab, setActiveTab]
    );

    return (
        <div>
            <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>
        </div>
    );
}

export const Tabs = {
    Container,
    TabTriggers,
    TabTrigger,
    TabTriggerLink,
    TabContent,
    TabLinkContent,
};
