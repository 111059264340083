import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { client } from '../services/HTTPClient';
import axios from 'axios';
import { FetchStatus, FileType } from '../types';
import { useIsMounted } from './useIsMounted';
import { useDatasetFile } from '../stores/datasetFiles';
import { useFilePreviewContext } from '../components/FilePreviews/useFilePreviewContext';

const NUM_ROWS = 100;

export type FilePreview = ReturnType<typeof useFilePreview>;

export function useFilePreview(fileId: string, datasetId: string) {
    const isMounted = useIsMounted();
    const { setNumPages, pageNumber } = useFilePreviewContext();

    const datasetFile = useDatasetFile(fileId);

    useEffect(() => {
        setNumPages(datasetFile?.numRows ? Math.ceil(datasetFile.numRows / NUM_ROWS) : 0);
    }, [datasetFile?.numRows, setNumPages]);

    const [originalDataStatus, setOriginalDataStatus] = useState<FetchStatus>('init');
    const [originalHtml, setOriginalHtml] = useState<string>();

    const [deidentifiedDataStatus, setDeidentifiedDataStatus] = useState<FetchStatus>('init');
    const [deidentifiedHtml, setDeidentifiedHtml] = useState<string>();

    const originalAbortControllerRef = useRef<null | AbortController>(null);
    const deidentifyAbortControllerRef = useRef<null | AbortController>(null);

    const setDeidentifiedDataLoading = useCallback(() => {
        setDeidentifiedDataStatus((currentStatus) => (currentStatus === 'success' ? 'refreshing' : 'loading'));
    }, []);

    // Derive the preview URL from the state
    const previewURLs = useMemo(() => {
        return calculateUrls(datasetId, fileId, pageNumber, datasetFile?.fileType);
    }, [fileId, pageNumber, datasetId, datasetFile?.fileType]);

    const fetchOriginalData = useCallback(() => {
        fetchData(
            previewURLs.originalDataURL,
            originalAbortControllerRef,
            setDeidentifiedDataLoading,
            setOriginalHtml,
            setOriginalDataStatus,
            isMounted
        );
    }, [previewURLs.originalDataURL, isMounted, setDeidentifiedDataLoading]);

    const fetchDeidentifiedData = useCallback(() => {
        fetchData(
            previewURLs.deidentifiedDataURL,
            deidentifyAbortControllerRef,
            setDeidentifiedDataLoading,
            setDeidentifiedHtml,
            setDeidentifiedDataStatus,
            isMounted
        );
    }, [previewURLs.deidentifiedDataURL, isMounted, setDeidentifiedDataLoading]);

    // Anytime the preview URL changes (including after first render), fetch
    // the data
    useEffect(() => {
        fetchDeidentifiedData();
        fetchOriginalData();
    }, [fetchDeidentifiedData, fetchOriginalData]);

    // Try re-fetching data that's _just_ been uploaded and isn't processed yet.
    // Rety every 3 seconds until we can get the data
    useEffect(() => {
        if (deidentifiedDataStatus !== 'accepted') return;

        const timeout = window.setTimeout(() => {
            fetchDeidentifiedData();
        }, 3000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [deidentifiedDataStatus, fetchDeidentifiedData]);

    // Abort any existing requests on unmount
    useEffect(() => {
        fetchDeidentifiedData();
        fetchOriginalData();

        // Capture the current values of the refs in the effect's closure
        const currentDeidentifyAbortController = deidentifyAbortControllerRef.current;
        const currentOriginalAbortController = originalAbortControllerRef.current;

        return () => {
            // Use the captured values in the cleanup function
            if (currentDeidentifyAbortController) {
                currentDeidentifyAbortController.abort();
            }
            if (currentOriginalAbortController) {
                currentOriginalAbortController.abort();
            }
        };
    }, [fetchDeidentifiedData, fetchOriginalData]);

    return {
        deidentifiedDataStatus,
        originalDataStatus,
        originalHtml,
        deidentifiedHtml,
        numRows: NUM_ROWS,
        fileName: datasetFile?.fileName ?? fileId,
        fileType: datasetFile?.fileType ?? 'Raw',
    };
}

const fetchData = (
    dataUrl: string,
    abortControllerRef: React.MutableRefObject<AbortController | null>,
    setDeidentifiedDataLoading: () => void,
    setHtml: React.Dispatch<React.SetStateAction<string | undefined>>,
    setDeidentifiedDataStatus: React.Dispatch<React.SetStateAction<FetchStatus>>,
    isMounted: () => boolean
) => {
    if (abortControllerRef.current !== null) {
        abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    setDeidentifiedDataLoading();
    client
        .get<string>(dataUrl, {
            signal: abortControllerRef.current.signal,
        })
        .then(({ data }) => {
            setHtml(() => {
                return data;
            });
            setDeidentifiedDataStatus('success');
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                return;
            } else if (axios.isAxiosError(e)) {
                if (e.code === '409') {
                    if (isMounted()) {
                        setDeidentifiedDataStatus('accepted');
                    }
                    return;
                }
            } else if (isMounted()) {
                setHtml(undefined);
                setDeidentifiedDataStatus('error');
            }
        });
};

const calculateUrls = (datasetId: string, fileId: string, pageNum: number, fileType?: FileType) => {
    let originalDataURL = `/api/preview/${datasetId}/files/${fileId}/original`;
    let deidentifiedDataURL = `/api/preview/${datasetId}/files/${fileId}/redacted`;

    if (fileType !== 'Pdf') {
        const searchParams = new URLSearchParams();
        searchParams.set('numRows', `${NUM_ROWS}`);
        searchParams.set('startingRow', `${NUM_ROWS * pageNum}`);

        originalDataURL += `?${searchParams.toString()}`;
        deidentifiedDataURL += `?${searchParams.toString()}`;
    }

    return {
        originalDataURL,
        deidentifiedDataURL,
    };
};
