import { QuiBox, QuiFlexBoxColumn, QuiModalContent, QuiModalDialog, QuiText } from '@tonicai/ui-quinine';
import { UserAPIKeyForm } from './UserAPIKeyForm';
import styles from './ApiKeyModal.module.scss';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export function ApiKeyModal({ isOpen, onClose }: IProps) {
    return (
        <QuiModalDialog className={styles.modal} disableDismissOnEscapeKeyDown={false} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent className={styles.modalContent}>
                <QuiBox display="flex" flexDirection="column" gap="md">
                    <QuiFlexBoxColumn padding="lg none none none" gap="lg">
                        <QuiText size="text-md" weight="medium">
                            {`Create a new user API key`}
                        </QuiText>
                        <QuiText size="text-sm" color="text-secondary">
                            {`Let's first get the name of the user who will be using this API key. You will be able to manage the key later.`}
                        </QuiText>
                        <UserAPIKeyForm />
                    </QuiFlexBoxColumn>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
