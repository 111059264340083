/* eslint-disable react/no-array-index-key */

// We're disabling this rule because the props values are stable (e.g. not
// changing based on user actions).

import { BaseDocument } from '@/types';
import { QuiBox } from '@tonicai/ui-quinine';
import { RenderContent } from './RenderContent';
import styles from './KeyValuesPairTab.module.scss';

type KeyValuePairsTabProps = Readonly<{
    deidentifyResponse: Partial<BaseDocument>;
}>;

export function KeyValuePairsTab({ deidentifyResponse }: KeyValuePairsTabProps) {
    if (!deidentifyResponse?.keyValuePairs || deidentifyResponse.keyValuePairs.length === 0) {
        return (
            <QuiBox text="text-sm" color="text-secondary">
                No key-value pairs found.
            </QuiBox>
        );
    }

    return (
        <QuiBox border="border-default" display="inline-block">
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {deidentifyResponse.keyValuePairs.map((kv, index) => {
                        return (
                            <tr key={index}>
                                <td>{kv.key}</td>
                                <td>
                                    <RenderContent content={kv.value} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </QuiBox>
    );
}
