import { QuiBox, QuiForm, QuiModalContent, QuiModalDialog, QuiSubmitButton, QuiTextField } from '@tonicai/ui-quinine';
import { useForm } from 'react-final-form';
import { Dataset, FileSource } from '../../types';
import styles from './UploadExternalFilesModal.module.scss';

type UploadExternalFilesModalProps = Readonly<{
    dataset: Dataset;
    isOpen: boolean;
    onClose: () => void;
    onFilesSelected: (files: File[]) => void;
    fileSource: FileSource;
}>;

type UploadExternalFilesFormValues = Readonly<{
    filePath: string;
}>;

export function UploadExternalFilesModal({ dataset, isOpen, onClose, onFilesSelected }: UploadExternalFilesModalProps) {
    const form = useForm();

    return (
        <QuiModalDialog className={styles['upload-external-files-modal']} title={dataset.name} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" flexDirection="column" gap="md" padding="md">
                    <QuiBox text="text-md" weight="medium">
                        {`Scan Files From ${dataset.fileSource}`}
                    </QuiBox>
                    <QuiBox text="text-sm" color="black-600">
                        Enter the URL of the file you want to scan.
                    </QuiBox>
                    <QuiForm<UploadExternalFilesFormValues>
                        onSubmit={(values: UploadExternalFilesFormValues) => {
                            const newFile: File = new File([], values.filePath);
                            onFilesSelected([newFile]);
                            form.submit();
                            onClose();
                        }}
                    >
                        <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                            <QuiTextField label="File Path" name="filePath" placeholder={'Add file path...'} />
                            <QuiBox display={'flex'} justifyContent={'end'}>
                                <QuiSubmitButton
                                    variant={'brand-purple'}
                                    onSubmit={() => {
                                        onClose();
                                    }}
                                >
                                    Upload
                                </QuiSubmitButton>
                            </QuiBox>
                        </QuiBox>
                    </QuiForm>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
