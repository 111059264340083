import { ReactNode, useRef } from 'react';
import { IQuiButtonProps, QuiButton } from '@tonicai/ui-quinine';
import { ACCEPTED_MIME_TYPES } from '../../types';
import styles from './UploadFilesButton.module.scss';

type UploadLocalFilesButtonProps = {
    disabled?: boolean;
    onFilesSelected: (files: File[]) => void;
    variant?: IQuiButtonProps['variant'];
    size?: IQuiButtonProps['size'];
    iconLeft?: IQuiButtonProps['iconLeft'];
    children?: ReactNode;
    className?: string;
    spinner?: boolean;
    acceptedFileTypes?: string;
    selectionMode?: 'multiple' | 'single';
};

export function UploadLocalFilesButton({ disabled, onFilesSelected, acceptedFileTypes, selectionMode, ...buttonProps }: UploadLocalFilesButtonProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <input
                ref={inputRef}
                multiple={!(selectionMode === 'single')}
                onChange={(e) => {
                    const files = e.target.files;

                    if (!files) return;

                    const filesArray = [...files];

                    if (filesArray.length > 0) {
                        onFilesSelected(filesArray);
                    }
                }}
                type="file"
                accept={acceptedFileTypes ?? ACCEPTED_MIME_TYPES}
                disabled={disabled}
                className={styles.input}
                value=""
            />
            <QuiButton
                {...buttonProps}
                type={'button'}
                disabled={disabled}
                onClick={() => {
                    if (!disabled) {
                        inputRef.current?.click();
                    }
                }}
            />
        </>
    );
}
