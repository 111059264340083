import { useIsMounted } from '@/hooks/useIsMounted';
import { createChekcoutSession, useBillingStatus } from '@/stores';
import { CloudSubscriptionStatus } from '@/types';
import { QuiButton } from '@tonicai/ui-quinine';
import { useState } from 'react';

export function SelectPayAsYouGoPlan() {
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(false);
    const billingStatus = useBillingStatus();

    return (
        <QuiButton
            spinner={isLoading}
            disabled={isLoading || billingStatus === CloudSubscriptionStatus.Active}
            variant="green"
            onClick={() => {
                if (billingStatus === CloudSubscriptionStatus.Active) {
                    // Failsafe
                    return;
                }

                setIsLoading(true);
                createChekcoutSession()
                    .then((sessionUrl) => {
                        window.location.href = sessionUrl;
                    })
                    .catch(() => {
                        if (isMounted()) {
                            setIsLoading(false);
                        }
                    });
            }}
            type="button"
            iconRight="arrow-up-right"
        >
            {billingStatus === CloudSubscriptionStatus.Active ? 'Current Plan' : 'Select Plan'}
        </QuiButton>
    );
}
