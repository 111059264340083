import { QuiButton } from '@tonicai/ui-quinine';
import { SourceType } from '@/components/CreatePipelineDialog/SourceType';
import AzurePortal from '../icons/azure-portal.svg?react';
import { useInternalBucketSet } from '@/stores';

const FILE_SOURCE_CHANGE_BUTTON_VARIANTS = {
    primary: 'primary',
    outlineDefault: 'outline-default',
} as const;

interface FileSourceButtonsProps {
    fileSource: SourceType;
    onChange: (source: SourceType) => void;
    styles: Record<string, string>;
}

export function FileSourceButtons({ fileSource, onChange, styles }: FileSourceButtonsProps) {
    const isInternalBucketSet = useInternalBucketSet();
    return (
        <div className={styles.fileSourceGrid}>
            <QuiButton
                type="button"
                iconLeft="upload"
                disabled={!isInternalBucketSet}
                title={!isInternalBucketSet ? 'Please set the SOLAR_INTERNAL_BUCKET_NAME environment variable' : undefined}
                variant={
                    fileSource === SourceType.Files ? FILE_SOURCE_CHANGE_BUTTON_VARIANTS.primary : FILE_SOURCE_CHANGE_BUTTON_VARIANTS.outlineDefault
                }
                onClick={() => onChange(SourceType.Files)}
                className={styles.fileSourceButton}
            >
                File Upload
            </QuiButton>
            <QuiButton
                type="button"
                iconLeft="cloud"
                variant={
                    fileSource === SourceType.Aws ? FILE_SOURCE_CHANGE_BUTTON_VARIANTS.primary : FILE_SOURCE_CHANGE_BUTTON_VARIANTS.outlineDefault
                }
                onClick={() => onChange(SourceType.Aws)}
                className={styles.fileSourceButton}
            >
                Amazon S3
            </QuiButton>
            <QuiButton
                type="button"
                iconLeft="box"
                variant={
                    fileSource === SourceType.Databricks
                        ? FILE_SOURCE_CHANGE_BUTTON_VARIANTS.primary
                        : FILE_SOURCE_CHANGE_BUTTON_VARIANTS.outlineDefault
                }
                onClick={() => onChange(SourceType.Databricks)}
                className={styles.fileSourceButton}
            >
                Databricks
            </QuiButton>
            <QuiButton
                type="button"
                variant={
                    fileSource === SourceType.Azure ? FILE_SOURCE_CHANGE_BUTTON_VARIANTS.primary : FILE_SOURCE_CHANGE_BUTTON_VARIANTS.outlineDefault
                }
                onClick={() => onChange(SourceType.Azure)}
                className={styles.fileSourceButton}
            >
                <AzurePortal className={styles.azureIcon} />
                Azure
            </QuiButton>
        </div>
    );
}
