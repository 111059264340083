// This code should only run on the signup page for the hosted experience
let alreadyLoadedIntercom = false;
export function loadIntercomOnHosted() {
    const isHosted = window.location.hostname === 'solar.tonic.ai' || window.location.hostname === 'textual.tonic.ai';

    if ((isHosted || !!sessionStorage.getItem('solar.hosted-tracking-test')) && !alreadyLoadedIntercom) {
        alreadyLoadedIntercom = true;
        //Set your APP_ID
        var APP_ID = 'l9jilb9x';

        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
    }
}
