import axios from 'axios';
import { globalStore } from '../stores/globalStore';
import { authResponseAtom } from '../stores/auth';
import { httpClientToastAtom, HttpClientToastMessage } from '@/stores/httpToast';
import { openPlanDialog } from '@/stores';

export const client = axios.create();

// Add access token to request (if it exists)
client.interceptors.request.use((config) => {
    const authResponse = globalStore.get(authResponseAtom);

    if (authResponse === null) return config;

    const newConfig = { ...config };

    newConfig.headers.set('Authorization', `Bearer ${authResponse.jwt}`);

    return newConfig;
});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isAxiosError(error)) {
            switch (error.response?.status) {
                case 401:
                    globalStore.set(authResponseAtom, null);
                    break;
                case 403: {
                    const errorCode = error.response?.headers['x-error-code'];
                    if (errorCode === 'WORD_COUNT_LIMIT_EXCEEDED') {
                        const newToast: HttpClientToastMessage = {
                            title: error.response?.data ?? 'You have exceeded the maximum word count for free trial accounts.',
                            variant: 'destructive',
                            action: {
                                text: 'Upgrade Plan',
                                onClick: () => {
                                    openPlanDialog();
                                },
                            },
                        };

                        // Update the toastAtom with the new message
                        globalStore.set(httpClientToastAtom, (prev) => [...prev, newToast]);
                    }
                    break;
                }
                case 500: {
                    const newToast: HttpClientToastMessage = {
                        title: error.response?.data?.error ?? 'Internal server error- please try again shortly.',
                        variant: 'destructive',
                    };
                    globalStore.set(httpClientToastAtom, (prev) => [...prev, newToast]);
                }
            }
        }
        return Promise.reject(error);
    }
);
