import { TEXTUAL_DOCUMENTATION_URL } from '@/constants';
import { QuiBadge, QuiBox, QuiLink } from '@tonicai/ui-quinine';

export function HelpDocumentation() {
    return (
        <QuiBox display="flex" alignItems="center" gap="md" bg="white" borderRadius="md" border="border-default" padding="lg">
            <QuiBox>
                <QuiBadge iconLeft="life-buoy" variant="brand-purple" size="sm" />
            </QuiBox>

            <QuiLink variant="currentcolor" to={TEXTUAL_DOCUMENTATION_URL} size="text-sm" iconRight="arrow-up-right">
                Help Documentation
            </QuiLink>
        </QuiBox>
    );
}
