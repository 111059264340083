import { QuiBox, QuiButton, QuiButtonExternalLink, QuiSegmentedControl, QuiSegmentedControlButton } from '@tonicai/ui-quinine';
import { CodeBlock } from '../../components/CodeBlock/CodeBlock';
import { authResponseAtom } from '../../stores/auth';
import { globalStore } from '../../stores/globalStore';
import { FileParseResultApiModel, FileType } from '../../types';
import { useState } from 'react';
import { copyToClipboard, isImageFileType } from '../../utils';
import { MarkdownHTMLPreview } from './MarkdownHTMLPreview';
import { PipelineSynthesizedPdfPreview } from '../../components/PipelineSynthesizedPdfPreview/PipelineSynthesizedPdfPreview';
import { PipelineSynthesizedImagePreview } from '../../components/PipelineSynthesizedImagePreview/PipelineSynthesizedImagePreview';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

type SegmentedControlValue = 'html' | 'markdown' | Extract<FileType, 'Pdf' | 'Png' | 'Tif' | 'Jpg'>;

type OriginalProps = Readonly<{
    text: string;
    fileParseResult: FileParseResultApiModel;
}>;

export function Original({ text, fileParseResult }: OriginalProps) {
    const { parseJobConfig } = usePipelineContext();
    const enableToggle = fileParseResult.file.fileType !== 'Raw';
    const [mode, setMode] = useState<SegmentedControlValue>('markdown'); // 'html' | 'markdown
    const authResponse = globalStore.get(authResponseAtom);

    return (
        <QuiBox bg="background-primary" border="border-default" borderRadius="md">
            <QuiBox
                alignItems="center"
                borderBottom="border-default"
                padding="sm"
                text="mono-text-xs"
                display={'flex'}
                justifyContent={'space-between'}
            >
                {enableToggle ? (
                    <QuiSegmentedControl<SegmentedControlValue> value={mode} onChange={setMode}>
                        <QuiSegmentedControlButton<SegmentedControlValue> value="markdown">Markdown</QuiSegmentedControlButton>
                        <QuiSegmentedControlButton<SegmentedControlValue> value="html">Rendered</QuiSegmentedControlButton>
                        {parseJobConfig.synthesizeFiles && fileParseResult.file.fileType === 'Pdf' ? (
                            <QuiSegmentedControlButton<SegmentedControlValue> value="Pdf">Redacted PDF</QuiSegmentedControlButton>
                        ) : null}
                        {parseJobConfig.synthesizeFiles && isImageFileType(fileParseResult.file.fileType) ? (
                            <QuiSegmentedControlButton<SegmentedControlValue> value={fileParseResult.file.fileType}>
                                Redacted Image
                            </QuiSegmentedControlButton>
                        ) : null}
                    </QuiSegmentedControl>
                ) : (
                    <span>Original Text</span>
                )}
                {mode === 'html' || mode === 'markdown' ? (
                    <QuiButton iconLeft="copy" size="xs" variant="minimal" onClick={() => copyToClipboard(text)}>
                        Copy
                    </QuiButton>
                ) : (
                    <QuiButtonExternalLink
                        size="xs"
                        variant="minimal"
                        iconLeft="download-cloud"
                        download={fileParseResult.file.fileName}
                        href={`/api/parsejobconfig/${parseJobConfig.id}/jobs/${fileParseResult.fileParseJobId}/files/${fileParseResult.id}/redacted/download?access_token=${authResponse?.jwt}`}
                    >
                        Download
                    </QuiButtonExternalLink>
                )}
            </QuiBox>

            <QuiBox bg="background-secondary">
                {parseJobConfig.synthesizeFiles && mode === 'Pdf' ? (
                    <PipelineSynthesizedPdfPreview fileBlobUrl={`/api/parsejobconfig/${parseJobConfig.id}/files/${fileParseResult.id}/redacted`} />
                ) : null}
                {parseJobConfig.synthesizeFiles && isImageFileType(mode as FileType) ? (
                    <PipelineSynthesizedImagePreview fileBlobUrl={`/api/parsejobconfig/${parseJobConfig.id}/files/${fileParseResult.id}/redacted`} />
                ) : null}
                {mode === 'markdown' ? <CodeBlock wrapLongLines code={text} language="markdown" /> : null}
                {mode === 'html' ? (
                    <QuiBox padding="md" bg="background-tertiary">
                        <MarkdownHTMLPreview markdown={text} />
                    </QuiBox>
                ) : null}
            </QuiBox>
        </QuiBox>
    );
}
