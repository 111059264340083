import { TEXTUAL_PRICING_URL } from '@/constants';
import { freeTrialExpiredAtom, useFreeTrialExpired } from '@/stores';
import { QuiBanner, QuiBox, QuiButtonLink, QuiIcon, QuiLink } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';

type FreeTrialBannerProps = Readonly<{
    className: string;
    mode: Exclude<ReturnType<typeof useAtomValue<typeof freeTrialExpiredAtom>>, false>;
}>;

export function FreeTrialBanner({ className }: FreeTrialBannerProps) {
    const freeTrialExpired = useFreeTrialExpired();

    if (!freeTrialExpired) {
        return null;
    }

    return (
        <QuiBanner className={className}>
            <QuiBox display="flex" alignItems="center" gap="md">
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiIcon icon="info" />

                    <QuiBox display="flex" alignItems="center">
                        <QuiBox weight="bold">Free trial word limit reached.&nbsp;</QuiBox>
                    </QuiBox>

                    <span>
                        &nbsp;Upgrade to continue using Textual. Ready to save? Reach out to learn more about discounted annual capacity commitments
                    </span>
                </QuiBox>
                <QuiLink to={TEXTUAL_PRICING_URL} variant="currentcolor" iconRight="arrow-up-right">
                    Pricing
                </QuiLink>
                <QuiButtonLink to="/billing" type="button" size="sm">
                    Upgrade Plan
                </QuiButtonLink>
            </QuiBox>
        </QuiBanner>
    );
}
