import { ReactNode, forwardRef } from 'react';
import { useFilePreviewContext } from './useFilePreviewContext';
import { QuiBox, QuiIcon, QuiTooltip } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import styles from './TileButton.module.scss';
import { FileAdderFileItemMenu } from '../FileAdder/FileAdderFileItemMenu';
import { DatasetFile, FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW, JobStatus } from '../../types';
import { FileIcon } from '../FileIcon/FileIcon';
import { FileDetails } from '../FileAdder/FileDetails';

export type FileTileProps = Readonly<{
    children?: ReactNode;
    file: DatasetFile;
    fileId: string;
    fileName: string;
    className?: string;
    datasetId: string;
}>;

export const TileButton = forwardRef<HTMLDivElement, FileTileProps>(function TileButton(
    { children, file, datasetId, fileId, fileName, className },
    ref
) {
    const { openFilePreview } = useFilePreviewContext();

    const cn = classNames(styles.tileButton, className);

    return (
        <span className={styles.wrapper}>
            <button
                type="button"
                className={cn}
                disabled={file.processingStatus !== JobStatus.COMPLETED || FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW.includes(file.fileType)}
                onClick={() => {
                    openFilePreview(fileId);
                }}
            >
                <div className={styles.content} ref={ref}>
                    {children !== undefined ? (
                        children
                    ) : (
                        <>
                            <div>
                                <QuiIcon icon="file" size="2xl" />
                            </div>
                            <QuiBox text="mono-text-xs" weight="bold">{`${fileName.substring(fileName.lastIndexOf('.')).toLowerCase()}`}</QuiBox>
                        </>
                    )}
                </div>
                <div className={styles.fileNameWrapper}>
                    <span className={styles.fileNameIcon}>
                        <QuiTooltip
                            content={
                                <FileDetails
                                    jobStatus={file.processingStatus}
                                    numRows={file.numRows}
                                    wordCount={file.wordCount}
                                    processingError={file.processingError}
                                />
                            }
                        >
                            <FileIcon jobStatus={file.processingStatus} />
                        </QuiTooltip>
                    </span>
                    <span className={styles.fileName}>{fileName}</span>
                </div>
            </button>
            <span className={styles.menuButtonWrapper}>
                <FileAdderFileItemMenu file={file} datasetId={datasetId} />
            </span>
        </span>
    );
});
