import { useAtomValue } from 'jotai';
import { enabledSSOConfigsAtom, isSsoRequiredAtom } from '../../stores/auth';
import { Or } from './Or';
import { getSSOURL } from '../../utils';
import GitHubLogo from '../icons/github.svg?react';
import GoogleLogo from '../icons/google.svg?react';
import OktaLogo from '../icons/okta.svg?react';
import AzureLogo from '../icons/azure-active-directory.svg?react';
import OpenIdLogo from '../icons/open-id.svg?react';
import { SsoProvider } from '../../types';
import styles from './SSOButtons.module.scss';
import { QuiBox } from '@tonicai/ui-quinine';

const SSOIcons: Record<
    SsoProvider,
    React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >
> = {
    [SsoProvider.github]: GitHubLogo,
    [SsoProvider.google]: GoogleLogo,
    [SsoProvider.okta]: OktaLogo,
    [SsoProvider.azure]: AzureLogo,
    [SsoProvider.oidc]: OpenIdLogo,
};

type SSOButtonsProps = {
    prefix: string;
};

export function SSOButtons({ prefix }: SSOButtonsProps) {
    const enabledSSOConfigs = useAtomValue(enabledSSOConfigsAtom);
    const isSsoRequired = useAtomValue(isSsoRequiredAtom);

    if (!enabledSSOConfigs) return null;

    return (
        <>
            <QuiBox gap="sm" display="flex" flexDirection="column">
                {enabledSSOConfigs.map((ssoSetting) => {
                    const ssoURL = getSSOURL(
                        ssoSetting.provider,
                        ssoSetting.clientId,
                        ssoSetting.canUseAsExternalFileSource,
                        ssoSetting.domain,
                        ssoSetting.identityProviderId,
                        ssoSetting.authorizationServerId
                    );

                    if (!ssoURL) return null;

                    const Icon = SSOIcons[ssoSetting.provider];

                    return (
                        <QuiBox flexDirection="column" key={ssoSetting.provider} display="flex">
                            <a className={styles.buttonLink} href={ssoURL} key={ssoSetting.provider}>
                                <Icon />
                                <span>{`${prefix} ${ssoSetting.provider}`}</span>
                            </a>
                        </QuiBox>
                    );
                })}
            </QuiBox>
            {!isSsoRequired && <Or />}
        </>
    );
}
