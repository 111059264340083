import { QuiBox, QuiFlexBoxColumn, QuiIconEnum, QuiLink, QuiText } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import { SDK_DOCUMENTATION_URL } from '../../constants';
import { getPipelineCodeSnippet } from '../../utils/getPipelineCodeSnippet';
import { CodeBox } from '../CodeBlock/CodeBox';
import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { CreateApiKeyButton } from '../CreateApiKeyButton/CreateApiKeyButton';
import Notice from '../Notice/Notice';
import { Tabs } from '../Tabs/Tabs';
import { usePipelineContext } from './usePipelineContext';

type NextStepsStepProps = Readonly<{
    stepNumber: string;
    title: ReactNode;
    description: ReactNode;
    children?: ReactNode;
}>;

function NextStepStep({ stepNumber, title, description, children }: NextStepsStepProps) {
    return (
        <QuiFlexBoxColumn gap="md">
            <QuiText size="text-md" weight="medium">
                {stepNumber}. {title}
            </QuiText>
            <div>
                <QuiBox text="text-sm" color="text-secondary">
                    {description}
                </QuiBox>
            </div>
            {children ? <QuiBox>{children}</QuiBox> : null}
        </QuiFlexBoxColumn>
    );
}

export function NextSteps() {
    const { parseJobConfigId } = usePipelineContext();
    return (
        <QuiFlexBoxColumn gap="lg">
            <Notice variant="purple">
                <Notice.Title variant="purple" icon={QuiIconEnum.FastForward}>
                    Use Python SDK after pipeline runs
                </Notice.Title>
                <Notice.Text>
                    After your pipeline finishes, follow the Python SDK instructions to create redacted and synthesized data, and prepare your
                    datasets for a RAG system or fine-tuning an LLM.
                </Notice.Text>

                <QuiLink external={true} variant="currentcolor" size="text-sm" to={SDK_DOCUMENTATION_URL} iconRight="external-link">
                    View Documentation
                </QuiLink>
            </Notice>
            <NextStepStep
                stepNumber="1"
                title="Create an API Key"
                description={
                    <QuiBox display="flex" alignItems="center">
                        With the Textual API, you can use a simple Python script to fetch your redacted data.
                        <CreateApiKeyButton />
                    </QuiBox>
                }
            />
            <NextStepStep
                stepNumber="2"
                title="Install the Python SDK"
                description={<CodeBox title="Python" code="pip install tonic-textual" language="python" />}
            />
            <NextStepStep
                stepNumber="3"
                title="Fetch the pipeline results"
                description={
                    <>
                        <QuiBox display="flex" alignItems="center">
                            <QuiText style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} size="text-xs">
                                Pipeline ID: {parseJobConfigId}
                            </QuiText>
                            <CopyToClipboardButton icon="copy" content={parseJobConfigId} size="sm" />
                        </QuiBox>

                        <Tabs.Container defaultTab="markdown">
                            <Tabs.TabTriggers>
                                <Tabs.TabTrigger data-test="pipeline-markdown-tab" id="markdown">
                                    Markdown
                                </Tabs.TabTrigger>
                                <Tabs.TabTrigger data-test="pipeline-json-tab" id="json">
                                    JSON
                                </Tabs.TabTrigger>
                                <Tabs.TabTrigger data-test="pipeline-chunks-tab" id="chunks">
                                    Chunks
                                </Tabs.TabTrigger>
                            </Tabs.TabTriggers>

                            <Tabs.TabContent id="markdown">
                                <CodeBox
                                    title="Python snippet"
                                    code={getPipelineCodeSnippet(parseJobConfigId, 'markdown')}
                                    language="python"
                                    expandable
                                    maxHeight={200}
                                />
                            </Tabs.TabContent>
                            <Tabs.TabContent id="json">
                                <CodeBox
                                    title="Python snippet"
                                    code={getPipelineCodeSnippet(parseJobConfigId, 'json')}
                                    language="python"
                                    expandable
                                    maxHeight={200}
                                />
                            </Tabs.TabContent>
                            <Tabs.TabContent id="chunks">
                                <CodeBox
                                    title="Python snippet"
                                    code={getPipelineCodeSnippet(parseJobConfigId, 'chunks')}
                                    language="python"
                                    expandable
                                    maxHeight={200}
                                />
                            </Tabs.TabContent>
                        </Tabs.Container>
                    </>
                }
            />
        </QuiFlexBoxColumn>
    );
}
