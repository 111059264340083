import { QuiSpinner } from '@tonicai/ui-quinine';
import { useEndpoint } from '../../hooks/useEndpoint';
import { Message } from '../Message/Message';
import { ReactNode, useMemo } from 'react';

export type EndpointGuardComponentProps<T> = Readonly<{
    data: T;
    fetchData: () => Promise<void>;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    [key: string]: any;
}>;

type EndpointGuardProps<T> = Readonly<
    | {
          Component: React.FC<EndpointGuardComponentProps<T>>;
          errorMessage: ReactNode;
          notFoundMessage: ReactNode;
          endpoint: string;
          /* eslint-disable @typescript-eslint/no-explicit-any */
          [key: string]: any;
      }
    | {
          render: (props: EndpointGuardComponentProps<T>) => ReactNode;
          errorMessage: ReactNode;
          notFoundMessage: ReactNode;
          endpoint: string;
          /* eslint-disable @typescript-eslint/no-explicit-any */
          [key: string]: any;
      }
>;

/**
 * A component that has an endpoint as a prop, fetches the data from the endpoint and passes
 * it to the Component prop. It handles loading & error UI states for you, so you can focus
 * on the happy path.
 *
 * Useful for things like forms, where you want to fetch the most up-to-date data before
 * rendering the form, but also don't want things like polling for global state to re-render
 * the form (and possibly lose user input when the form's state is reset on mount).
 *
 * Also useful for components that display data based on route parameters like:
 * `/some-thing/:some-id/sub-thing/:some-other-thing`
 * where the nested route params make it difficult to store the nested data in global state.
 */
export function EndpointGuard<T>({ endpoint, errorMessage, notFoundMessage, ...otherProps }: EndpointGuardProps<T>) {
    const { fetchStatus, data, fetchData } = useEndpoint<T>(endpoint);

    const result = useMemo(() => {
        if ((fetchStatus !== 'success' && fetchStatus !== 'refreshing') || data === null) return null;

        if ('Component' in otherProps) {
            const { Component, ...rest } = otherProps;
            return <Component data={data} fetchData={fetchData} {...rest} />;
        } else {
            const { render, ...rest } = otherProps;
            return render({ data, fetchData, ...rest });
        }
    }, [fetchStatus, data, otherProps, fetchData]);

    if (fetchStatus === 'loading' || fetchStatus === 'init') return <QuiSpinner />;

    if (fetchStatus === 'error' || data == null) return <Message variant="error">{errorMessage}</Message>;

    if (fetchStatus === 'not-found') return <Message variant="error">{notFoundMessage}</Message>;

    return result;
}
