import { atom } from 'jotai';
import { client } from '../services/HTTPClient';
import { CancelJobRequestModel, JobModel } from '../types';
import { globalStore } from './globalStore';
import { fetchDataset } from './datasets';

export const jobsAtom = atom<JobModel[]>([]);

export async function fetchJobs() {
    return await client.get<JobModel[]>('/api/DeidentifyFileJob').then(({ data }) => {
        globalStore.set(jobsAtom, data);
    });
}

export async function cancelJob(jobId: string) {
    const body: CancelJobRequestModel = { jobId };
    await client.post(`/api/DeidentifyFileJob/cancel`, body).finally(() => {
        fetchJobs();
    });
}

interface IRetryjobsRequest {
    assetId: string;
}

export async function retryJob(fileId: string, datasetId: string) {
    const body: IRetryjobsRequest = { assetId: fileId };
    await client.post(`/api/DeidentifyFileJob/start`, body).then(() => {
        fetchJobs();
        fetchDataset(datasetId);
    });
}
