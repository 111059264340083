import { PdfCoordinates, PdfRedactionArea, PdfRectangle, PdfDimensions, PdfWord, PiiTypeGeneratorState, Rectangle } from '../../types';

export const getAddedDrawnAreas = (outlineArea: Rectangle, words: PdfWord[]) => {
    const wordsInsideBox = words.filter((word) => {
        return isRectangleEncompassingOther(outlineArea, {
            x: word.x,
            y: word.y,
            width: word.width,
            height: word.height,
        });
    });

    return groupWordsIntoAreasByLine(wordsInsideBox);
};

export const getPiiTypeGeneratorStateOrDefault = (
    piiTypeLabel?: string,
    generatorSetup?: Record<string, PiiTypeGeneratorState>
): PiiTypeGeneratorState => generatorSetup?.[piiTypeLabel ?? ''] ?? 'Redaction';

export const isRectangleEncompassingOther = (rectangle: Rectangle, other: Rectangle): boolean =>
    rectangle &&
    other &&
    rectangle.x <= other.x &&
    rectangle.y <= other.y &&
    rectangle.x + rectangle.width >= other.x + other.width &&
    rectangle.y + rectangle.height >= other.y + other.height;

const groupWordsIntoAreasByLine = (containedWords: PdfWord[]): PdfRedactionArea[] => {
    const wordsByLine: PdfWord[][] = [];
    let currentList: PdfWord[] = [];
    let currentWordX = -1;
    let currentPage = -1;

    for (const word of [...containedWords].sort((a, b) => a.index - b.index)) {
        const containedWordX = word.x;
        const containedWordPage = word.pageNumber;

        if (containedWordX <= currentWordX || containedWordPage > currentPage) {
            wordsByLine.push(currentList);
            currentList = [];
        }

        currentList.push(word);
        currentWordX = containedWordX;
        currentPage = containedWordPage;
    }

    if (currentList.length > 0) {
        wordsByLine.push(currentList);
    }

    return wordsByLine
        .filter((l) => l.length > 0)
        .map((wordsOnLine) => {
            const lineRectangle = getBoundingRectangle(wordsOnLine);

            return {
                ...lineRectangle,
            };
        });
};

const getBoundingRectangle = (rectangles: PdfRectangle[]): PdfRectangle => {
    let minX = Infinity,
        maxX = -Infinity,
        minY = Infinity,
        maxY = -Infinity,
        pageNumber = Infinity;

    rectangles.forEach((rect) => {
        minX = Math.min(minX, rect.x);
        maxX = Math.max(maxX, rect.x + rect.width);
        minY = Math.min(minY, rect.y);
        maxY = Math.max(maxY, rect.y + rect.height);
        pageNumber = Math.min(pageNumber, rect.pageNumber);
    });

    return {
        pageNumber,
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
};

const MINIMUM_MOUSE_MOVEMENT_DISTANCE = 0.002;
export const hasMouseMoved = (startPosition: PdfCoordinates, endPosition: PdfCoordinates): boolean =>
    Math.abs(startPosition.x - endPosition.x) > MINIMUM_MOUSE_MOVEMENT_DISTANCE ||
    Math.abs(startPosition.y - endPosition.y) > MINIMUM_MOUSE_MOVEMENT_DISTANCE;

export const calculateMouseDragRectangle = (
    pageNumber: number,
    startPosition: PdfCoordinates,
    endPosition: PdfCoordinates,
    dimensions?: PdfDimensions
): PdfRectangle => {
    if (!dimensions) {
        return {
            pageNumber,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
    }

    const xStart = Math.min(startPosition.x, endPosition.x);
    const xEnd = Math.max(startPosition.x, endPosition.x);
    const yStart = Math.min(startPosition.y, endPosition.y);
    const yEnd = Math.max(startPosition.y, endPosition.y);

    return {
        pageNumber,
        x: xStart * dimensions.width,
        y: yStart * dimensions.height,
        width: (xEnd - xStart) * dimensions.width,
        height: (yEnd - yStart) * dimensions.height,
    };
};
