import { QuiBox, QuiCell, QuiFlexBoxColumn, QuiGridLayout } from '@tonicai/ui-quinine';
import { Editor } from './Editor';
import { PlaygroundProvider } from './PlaygroundProvider';
import { DeidResponsePreview } from './DeidResponsePreview';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { useHasActivePlan, useIsHostedProd } from '@/stores';

export function Playground() {
    const isHostedProd = useIsHostedProd();
    const hasActivePlan = useHasActivePlan();

    return (
        <PlaygroundProvider>
            <QuiBox display="flex" justifyContent="center">
                <QuiBox maxWidth="wide" flexGrow="1" padding="md" display="flex" flexDirection="column" gap="lg">
                    <QuiGridLayout gap="md">
                        <QuiCell width={3}>
                            <QuiBox display="flex" flexDirection="column" gap="lg" maxWidth="tablet">
                                <QuiFlexBoxColumn style={{ minHeight: 'calc(100vh - 44px - 2rem)' }} justifyContent="space-between" gap="lg">
                                    <PageTitle icon="target" title="Playground">
                                        Test-drive our AI-powered redaction capabilities by typing text into the box below. On the right, you'll see
                                        the results of what we detected.
                                    </PageTitle>

                                    {!hasActivePlan && isHostedProd ? (
                                        <QuiFlexBoxColumn gap="md">
                                            <HelpDocumentation />
                                        </QuiFlexBoxColumn>
                                    ) : null}
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </QuiCell>
                        <QuiCell width={9}>
                            <QuiFlexBoxColumn gap="lg">
                                <Editor />
                                <DeidResponsePreview />
                            </QuiFlexBoxColumn>
                        </QuiCell>
                    </QuiGridLayout>
                </QuiBox>
            </QuiBox>
        </PlaygroundProvider>
    );
}
