import { QuiFlexV, QuiEnterTransition, QuiCard } from '@tonicai/ui-quinine';

export type ChildrenProp = { children: React.ReactNode };

export function MainLayoutSlim({
    widthRem = 28,
    children,
}: {
    widthRem: number;
} & ChildrenProp) {
    return (
        <QuiEnterTransition duration={200}>
            <article>
                <QuiFlexV gap="xl" as="main" className="qui-padding-xl-lg qui-container" style={{ width: `${widthRem}rem` }}>
                    {children}
                </QuiFlexV>
            </article>
        </QuiEnterTransition>
    );
}

export function SlimLayoutCard({ children }: ChildrenProp) {
    return (
        <QuiCard padding="2lg" className="qui-border-white-400" style={{ boxShadow: '0px 0.5rem 1rem 0px rgba(0, 0, 0, 0.04)' }}>
            {children}
        </QuiCard>
    );
}
