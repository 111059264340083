import { useIsMounted } from '@/hooks/useIsMounted';
import { client } from '@/services/HTTPClient';
import { QuiButton } from '@tonicai/ui-quinine';
import { useState } from 'react';

type ManagePaymentButtonProps = Readonly<{
    onClick?: () => void;
}>;

export function ManagePaymentButton({ onClick }: ManagePaymentButtonProps) {
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <QuiButton
            spinner={isLoading}
            disabled={isLoading}
            onClick={() => {
                onClick?.();

                setIsLoading(true);
                client
                    .post<string>('/api/billing/portal-session')
                    .then((response) => {
                        window.location.href = response.data;
                    })

                    .catch(() => {
                        if (isMounted()) {
                            setIsLoading(false);
                        }
                    });
            }}
            iconRight="arrow-up-right"
        >
            Manage Payment
        </QuiButton>
    );
}
