import { QuiBox } from '@tonicai/ui-quinine';
import { PiiTextExample } from '../../types';

type IRedactionTypeExampleProps = {
    piiTextExample: PiiTextExample;
};

//Cannot exceed 50, the max length of the context text saved to the backend.
const MAX_CHARACTERS_CONTEXT = 25;

const grabWordsFromEndOfText = (text: string) => {
    const words = text.split(' ');
    let output = '';

    let hasFoundWord = false;

    for (let i = words.length - 1; i >= 0; i--) {
        if (output.length + words[i].length > MAX_CHARACTERS_CONTEXT) {
            if (!hasFoundWord) {
                output = '...' + words[i].slice(-MAX_CHARACTERS_CONTEXT) + output;
            } else {
                output = '...' + output;
            }
            break;
        }

        hasFoundWord = !!words[i].trim().length;

        output = words[i] + ' ' + output;
    }

    return output;
};

const grabWordsFromBeginningOfText = (text: string) => {
    const words = text.split(' ');
    let output = '';

    let hasFoundWord = false;

    for (let i = 0; i < words.length; i++) {
        if (output.length + words[i].length > MAX_CHARACTERS_CONTEXT) {
            if (!hasFoundWord) {
                output = output + words[i].slice(0, MAX_CHARACTERS_CONTEXT) + '...';
            } else {
                output += '...';
            }
            break;
        }

        hasFoundWord = !!words[i].trim().length;

        output += ' ' + words[i];
    }

    return output;
};

export function RedactionTypeExample({ piiTextExample }: IRedactionTypeExampleProps) {
    const preText = piiTextExample.text.substring(0, piiTextExample.startIndex);
    const exampleText = piiTextExample.text.substring(piiTextExample.startIndex, piiTextExample.endIndex);
    const postText = piiTextExample.text.substring(piiTextExample.endIndex, piiTextExample.text.length);

    if (!exampleText) return null;

    return (
        <QuiBox flexDirection={'row'} className={'fs-exclude'}>
            {preText && <span className={'qui-text-xs'}>{grabWordsFromEndOfText(preText)}</span>}
            <span className={'qui-text-xs qui-bold'}>{exampleText}</span>
            {postText && <span className={'qui-text-xs'}>{grabWordsFromBeginningOfText(postText)}</span>}
        </QuiBox>
    );
}
