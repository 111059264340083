import styles from './Or.module.scss';

export function Or() {
    return (
        <div className={styles.or}>
            <div className={styles.line} />
            <div className={styles.text}>or</div>
            <div className={styles.line} />
        </div>
    );
}
