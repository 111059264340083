import { fetchUsage } from '@/stores';
import axios from 'axios';
import equal from 'fast-deep-equal/es6';
import { atom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { instrumentation } from '../instrumentation/instrumentation';
import { client } from '../services/HTTPClient';
import {
    Dataset,
    DatasetFormState,
    FetchStatus,
    FileNeedingRescan,
    FileSource,
    LabelAllowList,
    LabelBlockList,
    PiiTypeEnum,
    UploadFileResponse,
} from '../types';
import { getLoadingFetchStatus } from '../utils';
import { createLoadedAtomFromFetchStatusAtom } from './atom-creators';
import { authResponseAtom } from './auth';
import { globalStore } from './globalStore';

// constants

export const DATASETS_ENDPOINT = '/api/dataset';

// atoms

export const piiTypeAtom = atom<PiiTypeEnum[]>([]);
export const piiFetchStatusAtom = atom<FetchStatus>('init');
export const datasetsAtom = atom<Dataset[]>([]);
export const datasetsFetchStatusAtom = atom<FetchStatus>('init');
export const filesNeedingRescanAtom = atom<FileNeedingRescan[]>([]);
export const filesNeedingRescanFetchStatusAtom = atom<FetchStatus>('init');
export const uploadingFilesAtom = atom<File[]>([]);
export const processingFilesAtom = atom<File[]>([]);
export const previouslyProcessedFilesAtom = atom<File[]>([]);

// computed atoms

export const datasetsLoadedAtom = createLoadedAtomFromFetchStatusAtom(datasetsFetchStatusAtom);

// actions

let datasetsGetAbortController = new AbortController();
export async function fetchDatasets() {
    globalStore.set(datasetsFetchStatusAtom, getLoadingFetchStatus(globalStore.get(datasetsFetchStatusAtom)));

    datasetsGetAbortController.abort();
    datasetsGetAbortController = new AbortController();

    await client
        .get<Dataset[]>(DATASETS_ENDPOINT, { signal: datasetsGetAbortController.signal })
        .then(({ data }) => {
            globalStore.set(datasetsAtom, data);
            globalStore.set(datasetsFetchStatusAtom, 'success');
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(datasetsFetchStatusAtom, 'error');
                throw e;
            }
        })
        .then(() => {
            fetchUsage();
        });
}

export async function fetchDataset(datasetId: string, abortSignal?: AbortSignal) {
    return await client.get<Dataset>(`${DATASETS_ENDPOINT}/${datasetId}`, { signal: abortSignal }).then(({ data }) => {
        const currentDatasets = globalStore.get(datasetsAtom);
        const newDatasets = [...currentDatasets.filter((d) => d.id !== datasetId), data];

        // Only update the datasets atom if the dataset has changed. We use polling to refetch datasets so this
        // is a way to ensure that we don't trigger a re-render when the dataset hasn't changed.
        if (!equal(currentDatasets, newDatasets)) {
            globalStore.set(datasetsAtom, newDatasets);
        }

        return data;
    });
}

export async function createDataset(name: string, fileSource: FileSource = 'Local') {
    return client
        .post<Dataset>(DATASETS_ENDPOINT, { name, fileSource })
        .then(async ({ data }) => {
            instrumentation.createDataset(data.id);
            await fetchDatasets();
            return data.id;
        })
        .then((id) => id);
}

export async function addSampleFile(datasetId: string) {
    return client
        .post<Dataset>(`${DATASETS_ENDPOINT}/add_sample`, { datasetId })
        .then(async ({ data }) => {
            instrumentation.addSampleFile(data.id);
            await fetchDatasets();
            return data.id;
        })
        .then((id) => id);
}

export async function createSampleDataset(name: string) {
    return createDataset(name).then(addSampleFile);
}

type LabelList = Record<string, LabelAllowList> | Record<string, LabelBlockList>;
function filterLabelLists(obj: LabelList) {
    const initialAcc: LabelList = {};
    return Object.keys(obj).reduce((acc, key) => {
        const regexes = obj[key].regexes?.filter(Boolean);
        const strings = obj[key].strings?.filter(Boolean);

        if (strings?.length && regexes?.length) {
            acc[key] = { strings, regexes };
        } else if (strings?.length) {
            acc[key] = { strings };
        } else if (regexes?.length) {
            acc[key] = { regexes };
        }
        return acc;
    }, initialAcc);
}

export async function updateDataset(dataset: DatasetFormState) {
    // We're not just passing dataset into the body of the request because we have some
    // form state from file uploads that we don't want to update.
    const formData = {
        name: dataset.name,
        id: dataset.id,
        generatorSetup: dataset.generatorSetup,
        labelBlockLists: filterLabelLists(dataset.labelBlockLists),
        labelAllowLists: filterLabelLists(dataset.labelAllowLists),
        enabledModels: dataset.enabledModels,
        datasetGeneratorMetadata: dataset.datasetGeneratorMetadata,
    };

    return client.put(`${DATASETS_ENDPOINT}${dataset.shouldRescan ? '?shouldRescan=true' : ''}`, formData).then(() => {
        instrumentation.updateDataset(dataset.id);
        return fetchDataset(dataset.id);
    });
}

export async function deleteDataset(id: string) {
    await client
        .delete(`${DATASETS_ENDPOINT}/?datasetId=${id}`)
        .then(fetchDatasets)
        .then(() => {
            instrumentation.deleteDataset(id);
        });
}

export async function uploadFiles(files: File[], datasetId: string, abortSignal?: AbortSignal) {
    return await Promise.all(
        files.map((file) => {
            const metadata = {
                fileName: file.name,
                csvConfig: {},
                datasetId,
            };

            const metadataBlob = new Blob([JSON.stringify(metadata)], {
                type: 'application/json',
            });

            // order matters here, file must be the last item
            const payload = new FormData();
            payload.append('document', metadataBlob);
            payload.append('file', file, file.name);

            return client
                .post<UploadFileResponse>(`${DATASETS_ENDPOINT}/${datasetId}/files/upload`, payload, { signal: abortSignal })
                .then(() => {
                    setUploadingFilesAtom((current) => {
                        return current.filter(({ name }) => file.name !== name);
                    });
                    fetchDataset(datasetId, abortSignal);
                })
                .catch((e) => {
                    setUploadingFilesAtom((current) => current.filter((f) => f.name !== file.name));
                    setProcessingFilesAtom((current) => current.filter((f) => f.name !== file.name));
                    if (!axios.isCancel(e)) {
                        throw e;
                    }
                });
        })
    );
}

export function getDownloadFileDataUrl(fileId: string, datasetId: string) {
    const authResponse = globalStore.get(authResponseAtom);

    let url = `${DATASETS_ENDPOINT}/${datasetId}/files/${fileId}/download`;
    if (authResponse) {
        url += `?access_token=${authResponse.jwt}`;
    }

    return url;
}

export function getDownloadAllDatasetFilesUrl(datasetId?: string) {
    const authResponse = globalStore.get(authResponseAtom);

    let url = `${DATASETS_ENDPOINT}/${datasetId}/files/download_all`;
    if (authResponse) {
        url += `?access_token=${authResponse.jwt}`;
    }
    return url;
}

export async function deleteDatasetFile(datasetId: string, fileId: string) {
    await client.delete(`${DATASETS_ENDPOINT}/${datasetId}/files/${fileId}`).then(() => {
        return fetchDatasets();
    });
}

// Hooks

export function useDatasets() {
    return useAtomValue(datasetsAtom);
}

export function useDataset(id: string | null | undefined) {
    const datasetAtom = useMemo(() => {
        return atom<Dataset | null>((get) => {
            return get(datasetsAtom).find((d) => d.id === id) ?? null;
        });
    }, [id]);

    return useAtomValue(datasetAtom);
}

export function usePIITypes() {
    const atomValue = useAtomValue(piiTypeAtom);
    const fetchAtomValue = useAtomValue(piiFetchStatusAtom);

    if (!['refreshing', 'loading'].includes(fetchAtomValue) && !atomValue.length) {
        globalStore.set(piiFetchStatusAtom, getLoadingFetchStatus(globalStore.get(piiFetchStatusAtom)));
        client
            .get('/api/redact/pii_types/')
            .then(({ data }) => {
                globalStore.set(piiTypeAtom, data);
                globalStore.set(piiFetchStatusAtom, 'success');
            })
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    globalStore.set(piiFetchStatusAtom, 'error');
                    throw e;
                }
            });
    }

    return atomValue;
}

export async function fetchFilesNeedingRescan(datasetId: string) {
    return client.get(`/api/dataset/${datasetId}/files/needs_rescan`).then(({ data }) => {
        globalStore.set(filesNeedingRescanAtom, data);
    });
}

export function useFilesNeedingRescan(datasetId: string) {
    const atomValue = useAtomValue(filesNeedingRescanAtom);
    const fetchAtomValue = useAtomValue(filesNeedingRescanFetchStatusAtom);
    if (!['refreshing', 'loading'].includes(fetchAtomValue) && fetchAtomValue == 'init') {
        fetchFilesNeedingRescan(datasetId)
            .then(() => globalStore.set(filesNeedingRescanFetchStatusAtom, 'success'))
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    globalStore.set(filesNeedingRescanFetchStatusAtom, 'error');
                    throw e;
                }
            });
    }
    return atomValue;
}

export function setUploadingFilesAtom(value: (currentValue: File[]) => File[]) {
    const currentAtom = globalStore.get(uploadingFilesAtom);
    globalStore.set(uploadingFilesAtom, value(currentAtom));
}

export function setProcessingFilesAtom(value: (currentValue: File[]) => File[]) {
    const currentAtom = globalStore.get(processingFilesAtom);
    globalStore.set(processingFilesAtom, value(currentAtom));
}

export function setPreviouslyProcessedFilesAtom(value: (currentValue: File[]) => File[]) {
    const currentAtom = globalStore.get(previouslyProcessedFilesAtom);
    globalStore.set(previouslyProcessedFilesAtom, value(currentAtom));
}
