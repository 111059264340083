import { useDataset } from '../stores/datasets';
import { fetchJobPdfRedactions } from '../stores/jobPdfRedaction';
import { fetchPdfFileWords } from '../stores/pdfWords';
import { fetchFilePdfRedactions } from '../stores/filePdfRedaction';
import { FetchStatus, PdfRedaction, PdfWord } from '../types';
import { useEffect, useMemo, useState } from 'react';
import { useDatasetFile } from '../stores/datasetFiles';

export type PdfOrImageFileData = {
    pdfWords: PdfWord[];
    pdfWordsFetchStatus: FetchStatus;
    fileSpecificRedactions: PdfRedaction[];
    fileTemplateId?: string;
    fileSpecificRedactionsFetchStatus: FetchStatus;
    automaticJobRedactions: PdfRedaction[];
    automaticJobRedactionsFetchStatus: FetchStatus;
    loadedSuccessfully: boolean;
};

export function usePdfOrImageFileData(datasetId: string, fileId?: string, pageNumber?: number): PdfOrImageFileData {
    const [fileSpecificRedactions, setFileSpecificRedactions] = useState<PdfRedaction[]>([]);
    const [fileTemplateId, setFileTemplateId] = useState<string>();
    const [fileSpecificRedactionsFetchStatus, setFileSpecificRedactionsFetchStatus] = useState<FetchStatus>('init');
    const [pdfWords, setPdfWords] = useState<PdfWord[]>([]);
    const [pdfWordsFetchStatus, setPdfWordsFetchStatus] = useState<FetchStatus>('init');
    const [automaticJobRedactions, setAutomaticJobRedactions] = useState<PdfRedaction[]>([]);
    const [automaticJobRedactionsFetchStatus, setAutomaticJobRedactionsFetchStatus] = useState<FetchStatus>('init');

    const dataset = useDataset(datasetId);
    const file = useDatasetFile(fileId);

    useEffect(() => {
        if (!file) return;
        setFileSpecificRedactionsFetchStatus('loading');
        fetchFilePdfRedactions(datasetId, file.fileId)
            .then((fileRedactions) => {
                setFileSpecificRedactions(fileRedactions.redactions);
                setFileTemplateId(fileRedactions.templateId);
                setFileSpecificRedactionsFetchStatus('success');
            })
            .catch(() => {
                setFileSpecificRedactionsFetchStatus('error');
            });
    }, [datasetId, file]);

    useEffect(() => {
        if (!file) return;
        setPdfWordsFetchStatus('loading');
        fetchPdfFileWords(datasetId, file.fileId)
            .then((words) => {
                setPdfWords(words);
                setPdfWordsFetchStatus('success');
            })
            .catch(() => {
                setPdfWordsFetchStatus('error');
            });
    }, [datasetId, file]);

    useEffect(() => {
        if (!file?.mostRecentCompletedJobId) return;
        setAutomaticJobRedactionsFetchStatus('loading');
        fetchJobPdfRedactions(datasetId, file.mostRecentCompletedJobId ?? '', pageNumber)
            .then((redactions) => {
                setAutomaticJobRedactions(redactions);
                setAutomaticJobRedactionsFetchStatus('success');
            })
            .catch(() => {
                setAutomaticJobRedactionsFetchStatus('error');
            });
    }, [datasetId, dataset?.generatorSetup, file?.mostRecentCompletedJobId, pageNumber]);

    const loadedSuccessfully = useMemo(
        () =>
            file !== null &&
            dataset !== null &&
            fileSpecificRedactionsFetchStatus === 'success' &&
            pdfWordsFetchStatus === 'success' &&
            (file?.mostRecentCompletedJobId ? automaticJobRedactionsFetchStatus === 'success' : true),
        [dataset, file, fileSpecificRedactionsFetchStatus, pdfWordsFetchStatus, automaticJobRedactionsFetchStatus]
    );

    return {
        fileSpecificRedactions: fileSpecificRedactions,
        fileTemplateId,
        fileSpecificRedactionsFetchStatus: fileSpecificRedactionsFetchStatus,
        automaticJobRedactions: automaticJobRedactions,
        automaticJobRedactionsFetchStatus: automaticJobRedactionsFetchStatus,
        pdfWords,
        pdfWordsFetchStatus,
        loadedSuccessfully,
    };
}
