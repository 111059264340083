import { useEffect } from 'react';

const GTAG_SCRIPT_ID = 'ga';
const GTAG_INIT_SCRIPT_ID = 'ga-init';
const GOOGLE_ANALYTICS_ID = `AW-772947744`;

export function useGoogleAnalytics() {
    useEffect(() => {
        const isHosted =
            window.location.hostname === 'solar.tonic.ai' ||
            window.location.hostname === 'textual.tonic.ai' ||
            !!sessionStorage.getItem('solar.hosted-tracking-test');

        if (!isHosted) return;

        if (document.getElementById(GTAG_SCRIPT_ID) === null) {
            const gtagScript = document.createElement('script');
            gtagScript.id = GTAG_SCRIPT_ID;
            gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;
            document.head.appendChild(gtagScript);
        }

        if (document.getElementById(GTAG_INIT_SCRIPT_ID) === null) {
            const gtagInitScript = document.createElement('script');
            gtagInitScript.id = GTAG_INIT_SCRIPT_ID;
            gtagInitScript.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ANALYTICS_ID}');`;

            document.head.append(gtagInitScript);
        }
    }, []);
}
