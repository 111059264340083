import { useEffect } from 'react';

export function usePolling(pollingCallback: () => void | Promise<void>, shouldPoll: boolean, intervalMs: number) {
    useEffect(() => {
        if (!shouldPoll) return;

        const id = setInterval(() => {
            pollingCallback();
        }, intervalMs);

        return () => {
            clearInterval(id);
        };
    }, [intervalMs, pollingCallback, shouldPoll]);
}
