import {
    fetchDataset,
    fetchUsage,
    setPreviouslyProcessedFilesAtom,
    setProcessingFilesAtom,
    setUploadingFilesAtom,
    uploadFiles,
    uploadingFilesAtom,
    useDataset,
} from '@/stores';
import { JobStatus } from '@/types';
import { QuiBox, QuiIconEnum, QuiLink, useQuiToasts } from '@tonicai/ui-quinine';
import { isAxiosError } from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { SDK_DOCUMENTATION_URL } from '../../constants';
import { useIsMounted } from '../../hooks/useIsMounted';
import { usePolling } from '../../hooks/usePolling';
import { instrumentation } from '../../instrumentation/instrumentation';
import { DownloadAllFilesButton } from '../../pages/Dataset/DownloadAllFilesButton';
import { UNSUPPORTED_FILE_EXTENSIONS } from '../../utils';
import { FileIcon } from '../FileIcon/FileIcon';
import Notice from '../Notice/Notice';
import { UploadFiles } from '../UploadFiles/UploadFiles';
import { UploadFilesButton } from '../UploadFilesButton/UploadFilesButton';
import styles from './FileAdder.module.scss';
import { FileAdderFileItem } from './FileAdderFileItem';
import { UploadingFile } from './UploadingFile';
import useFileProcessingCount from './useFileProcessingCount';

type FileAdderProps = Readonly<{
    name: string;
    mode: 'dropzone' | 'combobox' | 'button';
    datasetId: string;
}>;

export function FileAdder({ name, mode, datasetId }: FileAdderProps) {
    const dataset = useDataset(datasetId);
    const [uploading, setUploading] = useState(false);
    const form = useForm();
    const isMounted = useIsMounted();
    const addToast = useQuiToasts();
    const uploadAbortControllerRef = useRef<AbortController | null>(null);
    const uploadingFiles = useAtomValue(uploadingFilesAtom);
    const {
        isUploadingFiles,
        uploadingFileCount,
        uploadedFileCount,
        setUploadingFileCount,
        isProcessingFiles,
        processedFileCount,
        processingFileCount,
        getProcessingFiles,
        getProcessedFiles,
        setUploadHasError,
    } = useFileProcessingCount();

    useEffect(() => {
        return () => {
            if (uploadAbortControllerRef.current) {
                uploadAbortControllerRef.current.abort();
            }
        };
    }, []);

    const refetchDataset = useCallback(() => {
        fetchDataset(datasetId);
        fetchUsage();
    }, [datasetId]);

    const {
        input: { value },
    } = useField<string[]>(name, {
        format: (value: string[]) => {
            if (!Array.isArray(value)) {
                return [];
            }

            return Array.from(new Set([...value]));
        },
        parse: (value: unknown) => {
            if (!Array.isArray(value)) {
                return [];
            }
            return Array.from(new Set([...(value as string[])]));
        },
    });

    const hasAtLeastOneFileRequiringPolling = useMemo(() => {
        if (!dataset) {
            return false;
        }

        const assignedDatasetFiles = dataset.files.filter((d) => {
            return Array.isArray(value) && value.includes(d.fileId);
        });
        return assignedDatasetFiles.find((f) => f.processingStatus === 'Queued' || f.processingStatus === 'Running') !== undefined;
    }, [value, dataset]);

    usePolling(refetchDataset, hasAtLeastOneFileRequiringPolling, 5000);

    const removeNonTextFiles = useCallback(
        (files: File[]) => {
            const { textFiles, nonTextFiles } = files.reduce(
                (acc, file) => {
                    const fileExtension = file.name.split('.').pop()?.toLowerCase();

                    if (UNSUPPORTED_FILE_EXTENSIONS.has(fileExtension ?? '')) {
                        acc.nonTextFiles.push(file);
                    } else {
                        acc.textFiles.push(file);
                    }
                    return acc;
                },
                { textFiles: [] as File[], nonTextFiles: [] as File[] }
            );

            if (nonTextFiles.length > 0) {
                instrumentation.uploadNonTextFiles(nonTextFiles.map((f) => f.name));

                const toastTitleMessage =
                    nonTextFiles.length === 1
                        ? `"${nonTextFiles[0].name}" is not a text file`
                        : 'The following files are not text files: ' +
                          nonTextFiles
                              .map(function (f) {
                                  return `"${f.name}"`;
                              })
                              .join(', ');

                addToast({
                    title: `${toastTitleMessage}. Tonic Textual only supports text files, text delimited files, PDF files, MS Word documents (docx), Excel files (xlsx), and common image formats (jpg, png, tif)`,
                    variant: 'destructive',
                    timeout: 10000,
                });
            }

            return textFiles;
        },
        [addToast]
    );

    const onFilesSelected = useCallback(
        async (files: File[]) => {
            setUploading(true);

            const textFiles = removeNonTextFiles(files);
            const datasetToAddFilesTo = datasetId;

            uploadAbortControllerRef.current?.abort();
            uploadAbortControllerRef.current = new AbortController();

            setUploadingFileCount(textFiles.length);
            setUploadingFilesAtom((current) => [...current, ...textFiles]);
            setProcessingFilesAtom(() => [...(getProcessingFiles() ?? []).map((f) => new File([''], f.fileName)), ...textFiles]);
            setPreviouslyProcessedFilesAtom(() => [...(getProcessedFiles() ?? []).map((f) => new File([''], f.fileName))]);
            await uploadFiles(textFiles, datasetId ?? datasetToAddFilesTo, uploadAbortControllerRef.current.signal)
                .catch((error: unknown) => {
                    setUploadHasError(true);
                    if (isAxiosError(error)) {
                        if (error.response?.status === 409) {
                            const errorDetails = error.response.data;
                            addToast({
                                title: errorDetails,
                                variant: 'destructive',
                                timeout: 10000,
                            });
                        }
                        if (error.response?.status === 400) {
                            addToast({
                                variant: 'destructive',
                                title: error.response.data,
                                timeout: 10000,
                            });
                        }
                    }
                    setUploading(false);
                    return [];
                })
                .then(() => {
                    form.submit();
                    if (isMounted()) {
                        setUploading(false);
                    }
                });
        },
        [getProcessedFiles, getProcessingFiles, setUploadingFileCount, isMounted, datasetId, form, addToast, removeNonTextFiles, setUploadHasError]
    );

    if (!dataset) return null;

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox padding="none md none md" display={'flex'} gap={'sm'}>
                {(mode === 'button' || mode === 'combobox') && (
                    <UploadFilesButton
                        variant="brand-purple"
                        className={styles.uploadFilesButton}
                        iconLeft="upload"
                        disabled={uploading}
                        onFilesSelected={onFilesSelected}
                        datasetId={datasetId}
                        fileSource={dataset.fileSource}
                        data-test={'fileadder-upload-files-button'}
                        style={{ flexGrow: 1, justifyContent: 'center' }}
                    >
                        Upload Files
                    </UploadFilesButton>
                )}
                {mode === 'combobox' && <DownloadAllFilesButton datasetId={dataset.id} fileSource={dataset.fileSource} />}
            </QuiBox>
            <QuiBox padding="none md none md">
                {dataset.files.length === 0 && (
                    <Notice variant="purple">
                        <Notice.Title icon={QuiIconEnum.File}>No files found...</Notice.Title>
                        <Notice.Text>
                            To get started, upload files to redact. Unstructured free-text files, such as patient notes or customer call logs, work
                            best.
                        </Notice.Text>

                        <QuiLink external={true} variant="currentcolor" size="text-sm" to={SDK_DOCUMENTATION_URL} iconRight="external-link">
                            View Documentation
                        </QuiLink>
                    </Notice>
                )}
            </QuiBox>

            {mode === 'dropzone' && <UploadFiles onFilesSelected={onFilesSelected} />}

            {mode === 'combobox' && isUploadingFiles && (
                <QuiBox
                    justifyContent="space-between"
                    display="flex"
                    margin="none md none md"
                    text="text-sm"
                    padding="md"
                    style={{ backgroundColor: '#f6f6f6' }}
                >
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <FileIcon jobStatus={JobStatus.UPLOADING} />
                        <QuiBox>
                            <div>Uploading files</div>
                            <QuiBox text="text-xs">If you leave the page, the upload will stop.</QuiBox>
                        </QuiBox>
                    </QuiBox>
                    {uploadedFileCount} / {uploadingFileCount}
                </QuiBox>
            )}

            {isProcessingFiles && (
                <QuiBox
                    justifyContent="space-between"
                    display="flex"
                    margin="none md none md"
                    text="text-sm"
                    padding="md"
                    style={{ backgroundColor: '#f6f6f6' }}
                >
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <FileIcon jobStatus={JobStatus.RUNNING} />
                        Processing files...
                    </QuiBox>
                    {processedFileCount} / {processingFileCount}
                </QuiBox>
            )}
            <QuiBox display="flex" flexDirection="column" style={{ maxHeight: '600px', overflow: 'auto' }}>
                {value.map((datasetFileId) => {
                    const file = dataset.files.find((f) => f.fileId === datasetFileId);

                    if (!file || !dataset) return null;

                    return <FileAdderFileItem key={datasetFileId} fileId={file.fileId} datasetId={dataset.id} />;
                })}

                {mode === 'combobox' && (
                    <>
                        {uploadingFiles.map((file) => {
                            return <UploadingFile fileName={file.name} key={file.name} />;
                        })}
                    </>
                )}
            </QuiBox>
        </QuiBox>
    );
}
