import { QuiButton, useQuiModal, useQuiToasts } from '@tonicai/ui-quinine';
import { DeleteDialog } from '../../components/DeleteDialog/DeleteDialog';
import { deleteParseJob } from '../../stores/parse-jobs';
import { useNavigate } from 'react-router-dom';

type DeleteParseButtonProps = Readonly<{
    name: string;
    parseJobConfigId: string;
}>;

export function DeleteParseButton({ name, parseJobConfigId }: DeleteParseButtonProps) {
    const dialog = useQuiModal();
    const addToast = useQuiToasts();
    const navigate = useNavigate();
    return (
        <>
            <QuiButton type="button" variant="outline-red" onClick={dialog.open}>
                Delete Pipeline
            </QuiButton>
            <DeleteDialog
                title="Are you sure you want to delete this Parse?"
                isOpen={dialog.isOpen}
                closeDialog={dialog.close}
                itemType="Parse"
                itemName={name}
                deleteMethod={async () => {
                    try {
                        await deleteParseJob(parseJobConfigId);
                        addToast({
                            title: 'Deleted Pipeline Successfully',
                            variant: 'positive',
                        });
                        dialog.close();
                        navigate('/pipelines');
                    } catch (e) {
                        addToast({
                            title: 'Error deleting Pipeline',
                            variant: 'destructive',
                        });
                    }
                }}
            />
        </>
    );
}
