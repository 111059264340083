import { IQuiButtonProps, QuiButton, QuiTooltip } from '@tonicai/ui-quinine';
import { copyToClipboard } from '../../utils';
import { ReactNode, useEffect, useState } from 'react';

export type CopyToClipboardButtonProps = Readonly<{
    content: string;
    size?: IQuiButtonProps['size'];
    variant?: IQuiButtonProps['variant'];
    icon?: IQuiButtonProps['iconLeft'];
    children?: ReactNode;
    includeIcon?: boolean;
}>;

export function CopyToClipboardButton({ size, content, variant, icon, includeIcon = true, children }: CopyToClipboardButtonProps) {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (!isCopied) return;

        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied]);

    return (
        <QuiTooltip placement="top" disabled={!isCopied} content="Copied">
            <QuiButton
                variant={variant}
                {...(includeIcon && { iconLeft: icon ?? 'clipboard' })}
                size={size}
                onClick={() => {
                    copyToClipboard(content);
                    setIsCopied(true);
                }}
            >
                {children}
            </QuiButton>
        </QuiTooltip>
    );
}
