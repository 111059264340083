import { useCallback, useMemo, useState } from 'react';

const MIN = 0.25;
const MAX = 4;
const STEP = 0.25;
const DEFAULT = 1;

export function useScale() {
    const [scale, setScale] = useState<number>(DEFAULT);

    const zoomOut = useCallback(() => {
        setScale((cv) => Math.max(cv - STEP, MIN));
    }, []);

    const zoomIn = useCallback(() => {
        setScale((cv) => Math.min(cv + STEP, MAX));
    }, []);

    const reset = useCallback(() => {
        setScale(DEFAULT);
    }, []);

    const canZoomOut = useMemo(() => scale - STEP >= MIN, [scale]);
    const canZoomIn = useMemo(() => scale + STEP <= MAX, [scale]);

    const stringScale = useMemo(() => `${Math.round(scale * 100)}%`, [scale]);

    return {
        scale,
        zoomOut,
        zoomIn,
        reset,
        canZoomIn,
        canZoomOut,
        stringScale,
    };
}
