import { QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { Tabs } from '../../components/Tabs/Tabs';
import { FileParseResultApiModel, BaseDocument } from '../../types';
import { EndpointGuardComponentProps } from '../../components/EndpointGuard/EndpointGuard';
import { Original } from './Original';
import { Redacted } from './Redacted';
import { JsonTab } from './JsonTab';
import { TablesTab } from './TablesTab';
import { KeyValuePairsTab } from './KeyValuePairsTab';

type FileTabsProps = Readonly<
    EndpointGuardComponentProps<BaseDocument> & {
        fileParseResult: FileParseResultApiModel;
    }
>;

export function FileTabs({ data: deidentifyResponse, fileParseResult }: FileTabsProps) {
    const isPdfOrImage =
        fileParseResult.file.fileType === 'Pdf' ||
        fileParseResult.file.fileType === 'Jpg' ||
        fileParseResult.file.fileType === 'Png' ||
        fileParseResult.file.fileType === 'Tif';
    const hasTables = (deidentifyResponse?.tables ?? [])?.length > 0;
    const hasKeyValues = (deidentifyResponse?.keyValuePairs ?? [])?.length > 0;
    return (
        <QuiFlexBoxColumn gap="md">
            <Tabs.Container defaultTab="original">
                <Tabs.TabTriggers>
                    <Tabs.TabTrigger icon="inbox" id="original">
                        Original
                    </Tabs.TabTrigger>
                    <Tabs.TabTrigger icon="briefcase" id="entities">
                        Entities
                    </Tabs.TabTrigger>
                    <Tabs.TabTrigger icon="code" id="json">
                        JSON
                    </Tabs.TabTrigger>
                    {isPdfOrImage && hasTables ? (
                        <Tabs.TabTrigger icon="briefcase" id="columns">
                            Tables
                        </Tabs.TabTrigger>
                    ) : null}
                    {isPdfOrImage && hasKeyValues ? (
                        <Tabs.TabTrigger icon="list" id="key-value-pairs">
                            Key-Value Pairs
                        </Tabs.TabTrigger>
                    ) : null}
                </Tabs.TabTriggers>
                <Tabs.TabContent id="original">
                    <QuiFlexBoxColumn gap="md">
                        <Original fileParseResult={fileParseResult} text={deidentifyResponse.content.text ?? ''} />
                    </QuiFlexBoxColumn>
                </Tabs.TabContent>
                <Tabs.TabContent id="entities">
                    <Redacted deidentifyResponse={deidentifyResponse} />
                </Tabs.TabContent>
                <Tabs.TabContent id="json">
                    <JsonTab deidentifyResponse={deidentifyResponse} />
                </Tabs.TabContent>
                {isPdfOrImage && hasTables ? (
                    <Tabs.TabContent id="columns">
                        <TablesTab deidentifyResponse={deidentifyResponse} />
                    </Tabs.TabContent>
                ) : null}
                {isPdfOrImage && hasKeyValues ? (
                    <Tabs.TabContent id="key-value-pairs">
                        <KeyValuePairsTab deidentifyResponse={deidentifyResponse} />
                    </Tabs.TabContent>
                ) : null}
            </Tabs.Container>
        </QuiFlexBoxColumn>
    );
}
