import { ReactNode } from 'react';
import styles from './ScrollView.module.scss';

type ScrollViewProps = Readonly<{
    children: ReactNode;
}>;

export function ScrollView({ children }: ScrollViewProps) {
    return <div className={styles.scrollView}>{children}</div>;
}
