import { PiiTypeInfo } from '@/types';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiText } from '@tonicai/ui-quinine';
import { useRef, useState } from 'react';
import UndetectedEntity from './UndetectedEntity';
import { useDatasetContext } from '@/contexts/DatasetContext';

type UndetectedEntityTypesProps = Readonly<{
    undetectedEntities: PiiTypeInfo[];
}>;
export default function UndetectedEntityTypes({ undetectedEntities }: UndetectedEntityTypesProps) {
    const [isExpanded, setIsExpanded] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { dataset } = useDatasetContext();
    const hasFiles = dataset.files?.length > 0;
    if (!hasFiles)
        return (
            <div>
                <QuiText weight="medium" size="text-sm" style={{ marginBottom: '1em' }}>
                    Entity types
                </QuiText>
                <QuiBox display="flex" flexDirection="column" gap="xs" border="white-600" borderRadius="md">
                    {undetectedEntities.map((entity) => {
                        return (
                            <QuiBox key={entity.label} style={{ backgroundColor: 'white' }}>
                                <UndetectedEntity entity={entity} />
                            </QuiBox>
                        );
                    })}
                </QuiBox>
            </div>
        );

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs" border="white-600" borderRadius="md">
            <QuiBox padding="md">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <QuiIcon icon={QuiIconEnum.Minus} />
                        <div ref={scrollRef}>
                            <div className={'pii-type-label'}>Entity types not found</div>
                            <div className={'pii-type-description'}>Textual did not find these entity types in the files</div>
                        </div>
                    </QuiBox>
                    <QuiButton
                        iconRight={isExpanded ? QuiIconEnum.ChevronUp : QuiIconEnum.ChevronDown}
                        size="sm"
                        variant="outline-default"
                        onClick={() => {
                            setIsExpanded((current) => !current);
                            scrollRef?.current?.scrollIntoView({
                                block: 'center',
                                inline: 'center',
                            });
                        }}
                    />
                </QuiBox>
            </QuiBox>
            {isExpanded &&
                undetectedEntities.map((entity) => {
                    return <UndetectedEntity key={entity.label} entity={entity} />;
                })}
        </QuiBox>
    );
}
