import { OnPremCallout } from '../../components/GoOnPrem/OnPremCallout';
import { useIsHostedProd } from '../../stores/settings';
import { QuiBox, QuiButtonExternalLink, QuiIconEnum } from '@tonicai/ui-quinine';
import { SDK_DOCUMENTATION_URL } from '../../constants';
import { CodeBox } from '../../components/CodeBlock/CodeBox';
import './GeneratorSetup.scss';
import { ApiKeys } from './ApiKeys';
import { ApiKeyModal } from '@/components/ApiKeyModal/ApiKeyModal';
import { useState } from 'react';

const getCode = (name: string) => `from tonic_textual.redact_api import TonicTextual

textual = TonicTextual("${window.location.protocol}//${window.location.host}")
ds = textual.get_dataset("${name}")
df = ds.fetch_all_df()`;

type DatasetMetaProps = Readonly<{
    datasetName: string;
}>;

export function DatasetMeta({ datasetName }: DatasetMetaProps) {
    const isHostedProd = useIsHostedProd();
    const code = getCode(datasetName);
    const [activeModal, setActiveModal] = useState<'apikey'>();

    return (
        <>
            <QuiBox padding="none md md md" display="flex" flexDirection="column" gap="md">
                <ApiKeys
                    openApiKeyModal={() => {
                        setActiveModal('apikey');
                    }}
                />
                <QuiBox display={'flex'} flexDirection="column" gap={'2lg'}>
                    <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                        <QuiBox text="text-sm" weight="medium">
                            Install the tonic-textual package
                        </QuiBox>
                        <QuiBox text="text-xs" color="text-secondary">
                            Before you can use our Python SDK, you must install it.
                        </QuiBox>
                        <CodeBox title="Pip Install" code={`pip install tonic-textual`} language="python" />
                    </QuiBox>
                    <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                        <QuiBox text="text-sm" weight="medium">
                            Python Snippet
                        </QuiBox>
                        <QuiBox text="text-xs" color="text-secondary">
                            Python snippet to grab data. Fetch your redacted data.
                        </QuiBox>
                        <CodeBox title="Python" code={code} language="python" />
                    </QuiBox>
                </QuiBox>
                <QuiButtonExternalLink
                    variant={'minimal'}
                    href={SDK_DOCUMENTATION_URL}
                    openInNewTab={true}
                    iconRight={QuiIconEnum.ExternalLink}
                    style={{ width: 'fit-content', padding: 0 }}
                >
                    View SDK Documentation
                </QuiButtonExternalLink>
                {isHostedProd && <OnPremCallout />}
            </QuiBox>
            <ApiKeyModal isOpen={activeModal === 'apikey'} onClose={() => setActiveModal(undefined)} />
        </>
    );
}
