import { ReactNode } from 'react';
import { QuiBox, QuiSpinner, TonicTextualHorizontal } from '@tonicai/ui-quinine';

type FullScreenLoadingProps = {
    children?: ReactNode;
};

export function FullScreenLoading({ children }: FullScreenLoadingProps) {
    return (
        <QuiBox minHeight="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <QuiBox display="flex" flexDirection="column" gap="md">
                <QuiBox display="flex" gap="sm" alignItems="center" justifyContent="center">
                    <TonicTextualHorizontal height="1rem" />
                </QuiBox>
                <QuiBox display="flex" justifyContent="center">
                    <QuiSpinner size="lg" />
                </QuiBox>
            </QuiBox>
            {children ? <QuiBox>{children}</QuiBox> : null}
        </QuiBox>
    );
}
