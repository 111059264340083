import { QuiBox, QuiButton, QuiConditionalField, QuiFlexBoxColumn, QuiPasswordField, QuiTextField } from '@tonicai/ui-quinine';
import styles from '@/components/CreatePipelineDialog/CreatePipelineDialog.module.scss';
import { SourceType } from '@/components/CreatePipelineDialog/SourceType';
import { Message } from '@/components/Message/Message';
import { requiredString } from '@/validation';

interface AzureCredentialsProps {
    testStatus: 'untested' | 'testing' | 'success' | 'error';
    testConnectionEnabled: boolean | undefined;
    submitting: boolean;
    onClick: () => void;
    errorToShow: 'form' | 'test' | 'none';
    testErrorMessage?: string | null;
}

export function AzureCredentials({ testStatus, testConnectionEnabled, submitting, onClick, errorToShow, testErrorMessage }: AzureCredentialsProps) {
    return (
        <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Azure}>
            <QuiFlexBoxColumn className={styles.azureCredentials} gap="md">
                <QuiBox weight="medium" text="text-sm">
                    Azure Credentials
                </QuiBox>

                <QuiTextField
                    size="sm"
                    validate={requiredString}
                    placeholder=""
                    label="Account Name"
                    name="azureStorageAccountName"
                    id="azure-account-name"
                />

                <QuiPasswordField size="sm" validate={requiredString} label="Account Key" name="azureStorageAccountKey" id="azure-account-key" />

                <QuiButton
                    spinner={testStatus === 'testing'}
                    iconRight="globe"
                    type="button"
                    size="sm"
                    disabled={!testConnectionEnabled || testStatus === 'testing' || submitting}
                    onClick={onClick}
                >
                    Test Azure Connection
                </QuiButton>

                {/* Success Message */}
                {errorToShow === 'test' && testStatus === 'success' && <Message variant="success">Successfully connected to Azure</Message>}

                {/* Error Message */}
                {errorToShow === 'test' && testStatus === 'error' && (
                    <Message variant="error">{testErrorMessage ?? 'Could not connect to Azure'}</Message>
                )}
            </QuiFlexBoxColumn>
        </QuiConditionalField>
    );
}
