import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiIcon, QuiSegmentedControlButton, QuiSegmentedControlField, useQuiModal } from '@tonicai/ui-quinine';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { getPiiTypeInfo } from '@/pages/Dataset/utils';
import { piiTypeMetadataComponents } from '@/pages/Dataset/RedactTypeOptions';
import { useField } from 'react-final-form';

type GeneratorConfigProps = Readonly<{
    metadataName: string;
    piiType: PiiTypeEnum;
    generatorStatusName: string;
    disabled?: boolean;
}>;

export function GeneratorConfig({ metadataName, piiType, generatorStatusName, disabled }: GeneratorConfigProps) {
    disabled = disabled ?? false;
    const {
        input: { value },
    } = useField<PiiTypeGeneratorState>(generatorStatusName);
    const entityOptions = useQuiModal();

    const MetadataComponent = piiTypeMetadataComponents?.[piiType] ?? null;

    return (
        <QuiBox>
            <QuiBox padding="xs sm" display="flex" justifyContent="space-between" alignItems="center">
                <QuiBox alignItems="center" gap="md" display="inline-flex">
                    <QuiIcon icon={getPiiTypeInfo(piiType)?.icon ?? 'help-circle'} />

                    <QuiFlexBoxColumn>
                        <QuiBox text="text-sm" weight="medium">
                            {getPiiTypeInfo(piiType)?.label}
                        </QuiBox>
                        <QuiBox text="text-xs" color="text-secondary">
                            {getPiiTypeInfo(piiType)?.description}
                        </QuiBox>
                    </QuiFlexBoxColumn>
                </QuiBox>

                <QuiBox display="flex" gap="md" alignItems="center">
                    {MetadataComponent && value === 'Synthesis' && (
                        <QuiButton
                            onClick={entityOptions.toggle}
                            variant={entityOptions.isOpen ? 'primary' : 'outline-default'}
                            size={'sm'}
                            type={'button'}
                            iconRight={entityOptions.isOpen ? 'chevron-up' : 'chevron-down'}
                            disabled={disabled}
                        >
                            Options
                        </QuiButton>
                    )}

                    <QuiSegmentedControlField size="sm" name={generatorStatusName}>
                        <QuiSegmentedControlButton disabled={disabled} value="Synthesis">
                            Synthesize
                        </QuiSegmentedControlButton>
                        <QuiSegmentedControlButton disabled={disabled} value="Redaction">
                            Redact
                        </QuiSegmentedControlButton>
                        <QuiSegmentedControlButton disabled={disabled} value="Off">
                            Off
                        </QuiSegmentedControlButton>
                    </QuiSegmentedControlField>
                </QuiBox>
            </QuiBox>
            {entityOptions.isOpen && value == 'Synthesis' && MetadataComponent ? (
                <QuiBox padding="md">
                    <MetadataComponent name={metadataName} piiType={piiType} automaticallySubmit={false} />
                </QuiBox>
            ) : null}
        </QuiBox>
    );
}
