import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { Message } from '@/components/Message/Message';
import { isCancel } from 'axios';
import isEqual from 'fast-deep-equal';
import { FieldSubscription } from 'final-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import { client } from '../../services/HTTPClient';
import { AwsCredential, AzureCredential, DatabricksCredential, FileSourceEnum } from '../../types';
import { FormError } from '../FormError/FormError';

const fieldSubscription: FieldSubscription = {
    value: true,
    valid: true,
    dirtySinceLastSubmit: true,
    touched: true,
    active: true,
    pristine: true,
    modifiedSinceLastSubmit: true,
    modified: true,
};

const CLOUD_CONNECTION_ENDPOINT = '/api/cloudfile/test-cloud-connection';
const ERROR_CANNOT_CONNECT = 'Could not connect to';

export interface ITestResponse {
    success: boolean;
    errorMessage: string | undefined;
}

type AWSCredentialsProps = {
    fileSystemType: 'aws';
    awsKeyFieldName: string;
    awsAccessSecretFieldName: string;
    awsRegionFieldName: string;
    awsSessionTokenFieldName: string;
};

type DatabricksCredentialsProps = {
    fileSystemType: 'databricks';
    databricksUrlFieldName: string;
    databricksAccessTokenFieldName: string;
};

type AzureCredentialsProps = {
    fileSystemType: 'azure';
    accountNameFieldName: string;
    accountKeyFieldName: string;
};

type CommonProps = {
    onClick?: () => void;
    showFormError?: boolean;
};

type TestCredentialsButtonProps = CommonProps & (AWSCredentialsProps | DatabricksCredentialsProps | AzureCredentialsProps);

export function TestCredentialsButton(props: TestCredentialsButtonProps) {
    const { onClick, showFormError, fileSystemType } = props;

    const fileSourceMap: Record<string, FileSourceEnum> = {
        aws: FileSourceEnum.Aws,
        azure: FileSourceEnum.Azure,
        databricks: FileSourceEnum.Databricks,
    };

    // @ts-expect-error: Suppressing TypeScript error
    const fileSourceTitleMap: Record<FileSourceEnum, string> = {
        [FileSourceEnum.Aws]: 'AWS',
        [FileSourceEnum.Azure]: 'Azure',
        [FileSourceEnum.Databricks]: 'Databricks',
    };

    const fileSourceEnum = fileSourceMap[fileSystemType];
    const fileSourceText = fileSourceTitleMap[fileSourceEnum];

    const {
        awsKeyFieldName = '',
        awsAccessSecretFieldName = '',
        awsRegionFieldName = '',
        awsSessionTokenFieldName = '',
    } = fileSystemType === 'aws' ? props : {};

    const { databricksUrlFieldName = '', databricksAccessTokenFieldName = '' } = fileSystemType === 'databricks' ? props : {};

    const { accountNameFieldName = '', accountKeyFieldName = '' } = fileSystemType === 'azure' ? props : {};

    const awsKeyField = useField(fileSystemType === 'aws' ? awsKeyFieldName : '', { subscription: fieldSubscription });
    const awsAccessSecretField = useField(fileSystemType === 'aws' ? awsAccessSecretFieldName : '', { subscription: fieldSubscription });
    const awsRegionField = useField(fileSystemType === 'aws' ? awsRegionFieldName : '', { subscription: fieldSubscription });
    const awsSessionToken = useField(fileSystemType === 'aws' ? awsSessionTokenFieldName : '', { subscription: fieldSubscription });

    const databricksUrlField = useField(fileSystemType === 'databricks' ? databricksUrlFieldName : '', { subscription: fieldSubscription });
    const databricksAccessTokenField = useField(fileSystemType === 'databricks' ? databricksAccessTokenFieldName : '', {
        subscription: fieldSubscription,
    });

    // Azure fields
    const azureAccountNameField = useField(fileSystemType === 'azure' ? accountNameFieldName : '', { subscription: fieldSubscription });
    const azureAccountKeyField = useField(fileSystemType === 'azure' ? accountKeyFieldName : '', { subscription: fieldSubscription });

    const lastCheckedValues = useRef<AwsCredential | DatabricksCredential | AzureCredential | null>(null);

    const abortControllerRef = useRef<AbortController | null>(null);

    const [testStatus, setTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const testConnectionEnabled = useMemo(() => {
        switch (fileSourceEnum) {
            case FileSourceEnum.Aws:
                return awsKeyField.meta.valid && awsAccessSecretField.meta.valid && awsRegionField.meta.valid;
            case FileSourceEnum.Databricks:
                return databricksUrlField.meta.valid && databricksAccessTokenField.meta.valid;
            case FileSourceEnum.Azure:
                return azureAccountNameField.meta.valid && azureAccountKeyField.meta.valid;
            default:
                return false;
        }
    }, [
        fileSourceEnum,
        awsKeyField.meta.valid,
        awsAccessSecretField.meta.valid,
        awsRegionField.meta.valid,
        databricksUrlField.meta.valid,
        databricksAccessTokenField.meta.valid,
        azureAccountNameField.meta.valid,
        azureAccountKeyField.meta.valid,
    ]);

    const form = useFormState({
        subscription: {
            submitting: true,
            submitError: true,
            submitFailed: true,
        },
    });

    useEffect(() => {
        if (form.submitting) {
            setTestStatus('untested');
            setErrorMessage(null);
        }
    }, [form.submitting]);

    useEffect(() => {
        setTestStatus('untested');
        setErrorMessage(null);
    }, [
        fileSystemType,
        awsKeyField.input.value,
        awsAccessSecretField.input.value,
        awsRegionField.input.value,
        awsSessionToken.input.value,
        databricksUrlField.input.value,
        databricksAccessTokenField.input.value,
        azureAccountNameField.input.value,
        azureAccountKeyField.input.value,
    ]);

    const testConnection = useCallback(
        (automatic?: boolean) => {
            let data: AwsCredential | DatabricksCredential | AzureCredential;

            switch (fileSourceEnum) {
                case FileSourceEnum.Aws:
                    data = {
                        accessKey: awsKeyField.input.value,
                        secretKey: awsAccessSecretField.input.value,
                        region: awsRegionField.input.value,
                        sessionToken: awsSessionToken.input.value,
                    } as AwsCredential;
                    break;
                case FileSourceEnum.Databricks:
                    data = {
                        url: databricksUrlField.input.value,
                        accessToken: databricksAccessTokenField.input.value,
                    } as DatabricksCredential;
                    break;
                case FileSourceEnum.Azure:
                    data = {
                        accountName: azureAccountNameField.input.value,
                        accountKey: azureAccountKeyField.input.value,
                    } as AzureCredential;
                    break;
                default:
                    throw new Error('Unsupported ObjectStorageType.');
            }

            if (automatic && lastCheckedValues.current !== null && isEqual(data, lastCheckedValues.current)) {
                return;
            }

            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            lastCheckedValues.current = data;

            setTestStatus('testing');
            setErrorMessage(null);
            client
                .post<ITestResponse>(
                    CLOUD_CONNECTION_ENDPOINT,
                    {
                        fileSource: fileSourceEnum,
                        credential: data,
                    },
                    { signal: abortControllerRef.current.signal }
                )
                .then((response) => {
                    if (response.data.success) {
                        setTestStatus('success');
                        setErrorMessage(null);
                    } else {
                        setTestStatus('error');
                        setErrorMessage(response.data.errorMessage ?? 'Error');
                    }
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        console.error(error);
                        setTestStatus('error');
                        setErrorMessage(`${ERROR_CANNOT_CONNECT} ${fileSourceText}`);
                    }
                });
        },
        [
            fileSourceEnum,
            fileSourceText,
            awsKeyField.input.value,
            awsAccessSecretField.input.value,
            awsRegionField.input.value,
            awsSessionToken.input.value,
            databricksUrlField.input.value,
            databricksAccessTokenField.input.value,
            azureAccountNameField.input.value,
            azureAccountKeyField.input.value,
        ]
    );

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <>
            <QuiButton
                spinner={testStatus === 'testing'}
                iconRight="globe"
                type="button"
                disabled={!testConnectionEnabled || testStatus === 'testing'}
                onClick={() => {
                    testConnection(false);
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                Test {fileSourceText} Connection
            </QuiButton>

            {/* Success Message */}
            {testStatus === 'success' ? <Message variant="success">Successfully connected to {fileSourceText}</Message> : null}

            {/* Error Message */}
            {testStatus === 'error' && !showFormError ? (
                <QuiBox>
                    <Message variant="error">{errorMessage}</Message>
                </QuiBox>
            ) : null}

            {showFormError ? <FormError /> : null}
        </>
    );
}
