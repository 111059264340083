import { MarkSpec, Schema } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { keymap } from 'prosemirror-keymap';
import { deleteSelection, joinBackward, splitBlock } from 'prosemirror-commands';

const redactionMark: MarkSpec = {
    group: 'inline',
    inclusive: false,
    attrs: {
        'data-type': {
            default: 'redaction',
        },
        class: {
            default: 'redaction',
        },
    },
    parseDOM: [
        {
            tag: 'span',
            priority: 100,
            getAttrs(node) {
                if (typeof node === 'string') {
                    return false;
                }

                return {
                    'data-type': 'redaction',
                    class: 'redaction',
                };
            },
        },
    ],
    toDOM() {
        return [
            'span',
            {
                'data-type': 'redaction',
                class: 'redaction',
            },
            0,
        ];
    },
};

// Schema

export const schema = new Schema({
    nodes: {
        doc: {
            content: 'block+',
        },
        paragraph: {
            group: 'block',
            content: 'text*',
            toDOM() {
                return ['p', 0];
            },
            parseDOM: [{ tag: 'p' }],
        },
        text: {},
    },
    marks: {
        redaction: redactionMark,
    },
});

export function createProsemirrorEditor() {
    return EditorState.create({
        schema,
        plugins: [
            keymap({
                Enter: splitBlock,
                'Shift-Enter': splitBlock,
                Backspace: joinBackward,
            }),
            keymap({
                Backspace: deleteSelection,
            }),
        ],
    });
}
