import { useNavigate } from 'react-router-dom';
import { QuiBox, QuiButton, QuiSubmitButton } from '@tonicai/ui-quinine';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

export function CancelSubmitButtons() {
    const {
        parseJobConfig: { id },
    } = usePipelineContext();
    const navigate = useNavigate();
    return (
        <QuiBox display="flex" alignItems="center" gap="md">
            <QuiButton
                data-test="cancel-parse-settings-button"
                type="button"
                onClick={() => {
                    navigate(`/pipelines/${id}`);
                }}
            >
                Cancel
            </QuiButton>
            <QuiSubmitButton data-test="save-parse-settings-button" variant="brand-purple">
                Save
            </QuiSubmitButton>
        </QuiBox>
    );
}
