import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchParseJob } from '../../stores/parse-jobs';
import { QuiBox, QuiFlexH, QuiSpinner } from '@tonicai/ui-quinine';
import { Message } from '../Message/Message';
import { ParseJobConfigResponse } from '../../types';
import { PipelineLayout } from '../PipelineLayout/PipelineLayout';

export function ParseJobConfigGuard() {
    const params = useParams<{ parseJobConfigId: string }>();
    const parseJobConfigId = params.parseJobConfigId!;

    const [fetchedParseJob, setFetchedParseJob] = useState(false);
    const [parseJobConfig, setParseJobConfig] = useState<ParseJobConfigResponse | null>(null);

    const refetchParseJobConfig = useCallback(() => {
        return fetchParseJob(parseJobConfigId)
            .then((response) => {
                setParseJobConfig(response);
            })
            .finally(() => {
                setFetchedParseJob(true);
            });
    }, [parseJobConfigId]);

    useEffect(() => {
        setFetchedParseJob(false);

        fetchParseJob(parseJobConfigId)
            .then((response) => {
                setParseJobConfig(response);
            })
            .finally(() => {
                setFetchedParseJob(true);
            });
    }, [parseJobConfigId]);

    if (!fetchedParseJob) {
        return (
            <QuiFlexH justify="center" align="center" gap="md" fullWidth style={{ height: '50vh' }}>
                <QuiSpinner />
            </QuiFlexH>
        );
    }

    if (!parseJobConfig) {
        return (
            <QuiBox padding="md">
                <Message variant="error">Pipeline not found</Message>
            </QuiBox>
        );
    }

    return <PipelineLayout parseJobConfigId={parseJobConfigId} refetchParseJobConfig={refetchParseJobConfig} parseJobConfig={parseJobConfig} />;
}
