import { QuiBox } from '@tonicai/ui-quinine';
import { FileIcon } from '../FileIcon/FileIcon';
import { JobStatus } from '@/types';
import { FileDetails } from './FileDetails';

type Props = {
    fileName: string;
};
export function UploadingFile({ fileName }: Props) {
    return (
        <QuiBox
            bg="white"
            borderTop="white-600"
            padding="sm sm sm sm"
            display={'flex'}
            flexDirection={'row'}
            gap={'xs'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <QuiBox display="flex" flexGrow="1" alignItems="center" gap="sm" overflow="hidden">
                <QuiBox display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
                    <QuiBox text="text-xs" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        {fileName}
                    </QuiBox>
                    <QuiBox
                        color="black-600"
                        text="text-xs"
                        display="flex"
                        gap="xs"
                        alignItems="center"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                    >
                        <FileIcon jobStatus={JobStatus.UPLOADING} />

                        <FileDetails jobStatus={JobStatus.UPLOADING} processingError={undefined} numRows={undefined} wordCount={undefined} />
                    </QuiBox>
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
