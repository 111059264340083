import { composeValidators, matchesOtherField, matchesRegex, minLength, required } from '@tonicai/ui-quinine';

export function isUndefinedNotNull(v: unknown) {
    return v !== null && v === undefined;
}

export function hasStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'string';
}

export function hasOptionalStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'string' || isUndefinedNotNull(r[key]) : true;
}

export function hasNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'number';
}

export function hasOptionalNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'number' || isUndefinedNotNull(r[key]) : true;
}

export function hasBooleanValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'boolean';
}

export function hasOptionalBooleanValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'boolean' || isUndefinedNotNull(r[key]) : true;
}

export const passwordValidator = composeValidators(
    required,
    matchesRegex(/[A-Z]/, 'Must have at least one uppercase letter.'),
    matchesRegex(/[a-z]/, 'Must have at least one lowercase letter.'),
    matchesRegex(/[0-9]/, 'Must have at least one number.'),
    matchesRegex(/[^a-zA-Z0-9:]/, 'Must have at least one non-alphanumeric character.'),
    minLength(12, 'Must be at least 12 characters in length.')
);

export const confirmPasswordValidator = (passwordFieldName: string) =>
    composeValidators(required, matchesOtherField(passwordFieldName, 'Passwords must match.'));
