import { atom, useAtomValue } from 'jotai';
import { createGetEndpoint } from './atom-creators';
import { client } from '../services/HTTPClient';
import { CloudSubscriptionStatus, FREE_TRIAL_WORD_COUNT, StripeAccountMetadata, StripeInvoice, TotalUsageHistoryResponseModel } from '../types';
import { environmentVariablesStatusAtom, isHostedProdAtom } from './settings';
import { globalStore } from './globalStore';

/**
 * The billing plan dialog can be opened across various parts of the app. This atom
 * is used to track whether or not the dialog is open. This makes it kind of like a
 * "globally accessible" dialog, which is different than most other dialogs in the app.
 */
const planDialogOpenAtom = atom(false);

export function usePlanDialogOpen() {
    return useAtomValue(planDialogOpenAtom);
}

export function openPlanDialog() {
    globalStore.set(planDialogOpenAtom, true);
}

export function closePlanDialog() {
    globalStore.set(planDialogOpenAtom, false);
}

/**
 * Word count usage
 *
 * How many words have been used by the user in total
 */
export const {
    dataAtom: usageAtom,
    statusAtom: usageStatusAtom,
    fetchData: fetchUsage,
    useDataValue: useUsage,
} = createGetEndpoint<TotalUsageHistoryResponseModel>('/api/users/usage');

// Billing status: 'None', 'Expired', or 'Active'
export const {
    dataAtom: billingStatusAtom,
    statusAtom: billingStatusStatusAtom,
    dataLoadedAtom: billingStatusLoadedAtom,
    fetchData: fetchBillingStatus,
    useDataValue: useBillingStatus,
} = createGetEndpoint<CloudSubscriptionStatus>('/api/billing/status');

export const { dataAtom: checkoutSessionAtom, fetchData: fetchCheckoutSession } = createGetEndpoint<string>('/api/billing/checkout-session');

export const { dataAtom: portalSessionAtom, fetchData: fetchPortalSession } = createGetEndpoint<string>('/api/billing/portal-session');

// Whether or not the user has an active subscription (ignores free trial status)
const hasActivePlanAtom = atom((get) => {
    const status = get(billingStatusAtom);
    const billingStatusLoaded = get(billingStatusLoadedAtom);
    return billingStatusLoaded && status === CloudSubscriptionStatus.Active;
});

export function useHasActivePlan() {
    return useAtomValue(hasActivePlanAtom);
}

export const freeTrialActiveAtom = atom((get) => {
    const billingStatus = get(billingStatusAtom);
    const lifetimeUsage = get(lifetimeUsageAtom);

    return billingStatus === CloudSubscriptionStatus.None && lifetimeUsage < FREE_TRIAL_WORD_COUNT;
});

export function useFreeTrialActive() {
    return useAtomValue(freeTrialActiveAtom);
}

export const wordsRemainingAtom = atom((get) => {
    const usage = get(usageAtom)?.wordCount;
    return typeof usage === 'number' ? Math.max(0, FREE_TRIAL_WORD_COUNT - usage) : 0;
});

export const freeTrialExpiredAtom = atom<boolean>((get) => {
    const isHostedProd = get(isHostedProdAtom);
    const usage = get(usageAtom);
    const licenseDetailsStatus = get(licenseDetailsStatusAtom);
    const licenseDetails = get(licenseDetailsAtom);
    const maximumNumberOfWords = get(maximumNumberOfWordsAtom);

    if (licenseDetails?.category === 'Custom') return false;

    if (!isHostedProd) {
        return false;
    }

    const billingStatusStatus = get(billingStatusStatusAtom);
    const billingStatus = get(billingStatusAtom);

    if (billingStatusStatus !== 'success' || licenseDetailsStatus !== 'success') {
        return false;
    }

    if (billingStatus === CloudSubscriptionStatus.Active) {
        return false;
    }

    return typeof usage === 'number' && usage >= maximumNumberOfWords;
});

export function useFreeTrialExpired() {
    return useAtomValue(freeTrialExpiredAtom);
}

type DayUsageHistory = {
    date: string;
    words: {
        DeidentifyFile: number;
        ModelTemplateGeneration: number;
        ModelTraining: number;
        DeidentifyUnattachedFile: number;
        ParseFiles: number;
        PipelineDeidentifyFile: number;
    };
};

type UsageHistoryResponseModel = {
    from: string;
    total: number;
    days: DayUsageHistory[];
};

export const {
    statusAtom: usageHistoryStatusAtom,
    dataAtom: usageHistoryAtom,
    fetchData: fetchUsageHistory,
} = createGetEndpoint<UsageHistoryResponseModel>('/api/users/usage-history');

export const overFreeTrialLimit = atom<boolean>((get) => {
    const usage = get(usageAtom)?.wordCount;
    const usageStatus = get(usageStatusAtom);
    const billingStatus = get(billingStatusStatusAtom);
    const plan = get(billingStatusAtom);

    if (usageStatus !== 'success' || billingStatus !== 'success') {
        return false;
    }

    return typeof usage === 'number' ? usage < FREE_TRIAL_WORD_COUNT && plan !== CloudSubscriptionStatus.Active : false;
});

export type BarChartData = {
    date: Date;
    total: number;
};

export const barChartDataAtom = atom<BarChartData[]>((get) => {
    const history = get(usageHistoryAtom);
    if (!history) {
        const emptyData: BarChartData[] = [];

        for (let i = 0; i < 30; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            emptyData.push({ date, total: 0 });
        }

        return emptyData;
    }

    const data = history.days.map((day) => ({
        date: new Date(day.date),
        total: Object.values(day.words).reduce((acc, word) => acc + word, 0),
    }));

    if (data.length < 30) {
        const firstDate = data.length > 0 ? data[0].date : new Date();
        for (let i = 0; i < 30; i++) {
            const date = new Date(firstDate);
            date.setDate(date.getDate() - i);
            if (!data.find((d) => d.date.valueOf() === date.valueOf())) {
                data.unshift({ date, total: 0 });
            }
        }
    }

    return data;
});

export function createChekcoutSession() {
    return client.post<string>('/api/billing/checkout-session').then(({ data }) => data);
}

export const billingInfoAtom = atom((get) => {
    const status = get(billingStatusAtom);
    const checkoutSession = get(checkoutSessionAtom);
    const portalSession = get(portalSessionAtom);

    return {
        status,
        checkoutSession,
        portalSession,
    };
});

/**
 * Stripe metadata
 */
export const {
    dataAtom: stripeMetadataAtom,
    fetchData: fetchStripeMetadata,
    useDataValue: useStripeMetadata,
    statusAtom: stripeMetadataStatusAtom,
} = createGetEndpoint<StripeAccountMetadata | null>('/api/billing/stripe-metadata');

/**
 * Stripe invoices
 */
export const {
    dataAtom: stripeInvoiceAtom,
    fetchData: fetchStripeInvoices,
    useDataValue: useStripeInvoices,
    statusAtom: stripeInvoiceStatusAtom,
} = createGetEndpoint<StripeInvoice[]>('/api/billing/stripe-invoices');

/**
 * License Details
 */
type LicenseCategory = 'FreeTrial' | 'CloudSubscription' | 'Custom';
type LicenseDetails = {
    category: LicenseCategory;
    wordCount: number;
};

export const {
    fetchData: fetchLicenseDetails,
    dataAtom: licenseDetailsAtom,
    useDataValue: useLicenseDetails,
    statusAtom: licenseDetailsStatusAtom,
} = createGetEndpoint<LicenseDetails>('/api/users/license-details');

/**
 * Helper utility to fetch all billing-related data in a single function call
 */
export function fetchBillingInfo() {
    return Promise.all([
        fetchBillingStatus(),
        fetchStripeMetadata(),
        fetchUsageHistory(),
        fetchUsage(),
        fetchLicenseDetails(),
        fetchStripeInvoices(),
    ]);
}

// Billing data logic is dependent on a number of endpoints. This atom will be true only
// when all of the necessary data has been _successfully_ fetched. We want to avoid
// showing users invalid or incomplete billing data so they don't freak out and see a
// UI that tells them they've gone over limits, etc.

const fetchedBillingDataAtom = atom((get) => {
    const environmentVariablesStatus = get(environmentVariablesStatusAtom);
    const usageStatus = get(usageStatusAtom);
    const billingStatusStatus = get(billingStatusStatusAtom);
    const licenseDetailsStatus = get(licenseDetailsStatusAtom);
    const stripeMetadataStatus = get(stripeMetadataStatusAtom);
    const usageHistoryStatus = get(usageHistoryStatusAtom);
    const stripeInvoiceStatus = get(stripeInvoiceStatusAtom);
    return (
        (environmentVariablesStatus === 'success' || environmentVariablesStatus === 'refreshing') &&
        (usageStatus === 'success' || usageStatus === 'refreshing') &&
        (billingStatusStatus === 'success' || billingStatusStatus === 'refreshing') &&
        (licenseDetailsStatus === 'success' || licenseDetailsStatus === 'refreshing') &&
        (stripeMetadataStatus === 'success' || stripeMetadataStatus === 'refreshing') &&
        (usageHistoryStatus === 'success' || usageHistoryStatus === 'refreshing') &&
        (stripeInvoiceStatus === 'success' || stripeInvoiceStatus === 'refreshing')
    );
});

export function useFetchedBillingData() {
    return useAtomValue(fetchedBillingDataAtom);
}

/**
 * Lifetime Usage
 */
export const lifetimeUsageAtom = atom((get) => {
    const usage = get(usageAtom);
    return typeof usage?.wordCount === 'number' ? usage.wordCount : 0;
});

export function useLifetimeUsage() {
    return useAtomValue(lifetimeUsageAtom);
}

/**
 * Last 30 days usage
 */
export const last30DaysUsageAtom = atom((get) => {
    const usageHistory = get(usageHistoryAtom);
    return typeof usageHistory?.total === 'number' ? usageHistory.total : 0;
});

export function useLast30DaysUsage() {
    return useAtomValue(last30DaysUsageAtom);
}

/**
 * Current Billing Cycle Usage
 */
export const currentBillingCycleUsageAtom = atom((get) => {
    const billingStatus = get(billingStatusAtom);

    if (billingStatus === CloudSubscriptionStatus.Active) {
        const wordsUsed = get(stripeMetadataAtom)?.wordsUsed;
        return typeof wordsUsed === 'number' ? wordsUsed : 0;
    }

    return get(lifetimeUsageAtom);
});

export function useCurrentBillingCycleUsage() {
    return useAtomValue(currentBillingCycleUsageAtom);
}

/**
 * Max Number of Words
 */
const maximumNumberOfWordsAtom = atom((get) => {
    const licenseDetails = get(licenseDetailsAtom);
    const billingStatus = get(billingStatusAtom);

    if (licenseDetails?.category === 'Custom' && typeof licenseDetails?.wordCount === 'number') {
        return licenseDetails.wordCount;
    }

    if (billingStatus === CloudSubscriptionStatus.Active) {
        return 0;
    }

    if (get(freeTrialActiveAtom)) {
        return FREE_TRIAL_WORD_COUNT;
    }

    return 0;
});

export function useMaximumNumberOfWords() {
    return useAtomValue(maximumNumberOfWordsAtom);
}
