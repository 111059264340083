import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiLink } from '@tonicai/ui-quinine';

type CreateFirstParseJobProps = Readonly<{
    openDialog: () => void;
}>;

export function CreateFirstParseJob({ openDialog }: CreateFirstParseJobProps) {
    return (
        <QuiFlexBoxColumn borderRadius="lg" gap="lg" padding="lg" bg="background-tertiary">
            <QuiBox text="text-md" weight="medium">
                No pipelines found...
            </QuiBox>
            <QuiBox color="text-secondary" text="text-sm">
                {`To get started, create a pipeline to process files from Amazon S3 or files that you upload. Unstructured free-text files, such as patient notes or customer call logs, work best. `}
                <QuiLink variant="currentcolor" external to="https://docs.tonic.ai/textual">
                    View documentation
                </QuiLink>
            </QuiBox>
            <QuiBox>
                <QuiButton iconLeft="plus" onClick={openDialog} type="button" variant="brand-purple">
                    Create a Pipeline
                </QuiButton>
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
