import { atom, useAtomValue } from 'jotai';
import { DatasetFile } from '../types';
import { datasetsAtom } from './datasets';
import { useMemo } from 'react';

export const datasetFilesAtom = atom<Record<string, DatasetFile>>((get) => {
    const datasetFiles: Record<string, DatasetFile> = {};

    const datasets = get(datasetsAtom);

    datasets.forEach((dataset) => {
        dataset.files.forEach((file) => {
            datasetFiles[file.fileId] = file;
        });
    });

    return datasetFiles;
});

export function useDatasetFile(fileId: string | undefined) {
    const fileAtom = useMemo(
        () =>
            atom<DatasetFile | null>((get) => {
                if (!fileId) return null;
                return get(datasetFilesAtom)?.[fileId] ?? null;
            }),
        [fileId]
    );
    return useAtomValue(fileAtom);
}
